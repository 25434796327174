import DwhExportLib, { DwhExportMeta } from '@wg/dwh-export-lib'
import { getAnalyticsData, getFrontendSessionId } from '~/utils/analytics'

type Meta = {
    [key: string]: string | number | boolean | null | undefined
}

class DwhExport {
    static readonly PROJECT: string = 'dockyard'

    static readonly INTERVAL: number = 3

    static readonly MAX_EVENT_BATCH_SIZE: number = 5

    private readonly url: string = null

    private readonly accountId: number = null

    private readonly DwhExportLib: DwhExportLib

    constructor() {
        this.url = window.dockyard.urls.dwhExport + '/v1/user_events/'
        this.accountId = window.dockyard.state.account?.spaId as number
        this.DwhExportLib = new DwhExportLib({
            url: this.url,
            exportInterval: DwhExport.INTERVAL,
            maxEventsBatchSize: DwhExport.MAX_EVENT_BATCH_SIZE,
            project: DwhExport.PROJECT,
        })
        if (window.dockyard.urls.dwhExport) this.DwhExportLib.start()
    }

    async send(event: string, meta?: Meta, force = false, withoutAccount = false) {
        if (!this.url || !window.dockyard.urls.dwhExport) {
            return
        }

        if (!this.accountId && !withoutAccount) {
            return
        }

        meta = meta || {}
        if (meta) {
            meta = this._cleanMeta(this._addAnalyticsData(meta))
        }

        try {
            this.DwhExportLib.push(event, <DwhExportMeta>meta)

            if (force) {
                await this.DwhExportLib.send()
            }
        } catch (e) {
            console.log('Stats export failed', e)
        }
    }

    _cleanMeta = (meta: Meta) => {
        meta = { ...meta }

        Object.keys(meta).forEach((k) => {
            const val = meta[k]

            if (val === undefined || val === null) {
                delete meta[k]
            } else if (typeof val !== 'number' && typeof val?.toString === 'function') {
                meta[k] = val.toString()
            }
        })

        return meta
    }

    _addAnalyticsData = (meta: Meta) => {
        const data = getAnalyticsData()

        return { sid: getFrontendSessionId(), src: data?.source, pl: data.platform, spaId: this.accountId, ...meta }
    }
}

export default new DwhExport()
