import i18n, * as i18next from 'i18next'
import each from 'lodash/each'
import get from 'lodash/get'
import { initReactI18next } from 'react-i18next'
import preloaded from '~/preloaded'
import { searchToObject } from '~/utils'

export interface i18nTparams {}

export interface i18n_Props {
    i18n: i18next.i18n
    tReady: boolean
    t: (key: string, params?: i18nTparams) => string
}

const language = preloaded.settings.languageCode
const catalog: I18nCatalog = get(window, 'i18n.catalog', {})
const queryParams = searchToObject()
const i18nDebug = queryParams.i18nDebug === true
const i18nextTranslations: I18nextTranslations = {}

each(catalog, (translations: Array<string | string>, key: string) => {
    const pluralizable = Array.isArray(translations)

    if (pluralizable) {
        each(translations, (translation: string, index: number) => {
            i18nextTranslations[`${key}_${index}`] = translation
        })
        if (translations[0] && translations[1]) {
            i18nextTranslations[`${key}`] = translations[0]
            i18nextTranslations[`${key}_plural`] = translations[1]
        }
    } else {
        i18nextTranslations[key] = translations
    }
})

export const translations = { messages: i18nextTranslations }

i18n.use(initReactI18next).init({
    nsSeparator: false,
    keySeparator: false,
    fallbackLng: false,
    lowerCaseLng: true,
    lng: language,
    resources: {
        [language]: translations,
    },
    ns: ['messages'],
    defaultNS: 'messages',
    debug: i18nDebug,
    interpolation: {
        escapeValue: false,
    },
    react: {
        wait: true,
    },
})

export default i18n
