import { BlueprintsBackground } from '~/types'
import useMedia from '~/utils/useMedia'

const getBlueprintBackgroundByType = (blueprintsBackgrounds: BlueprintsBackground[], type: string): BlueprintsBackground => {
    return blueprintsBackgrounds.filter((background) => background.standard === type)[0]
}

const useResizableBlueprints = (blueprintsBackgrounds: BlueprintsBackground[]): string => {
    const ULTRAWIDE_32_9 = useMedia('(min-width: 3800px) and (max-height: 1100px)')
    const ULTRAWIDE_21_9 = useMedia('(min-width: 2999px) and (max-height: 1270px)')
    const STANDARD_1280 = useMedia('(max-width: 1280px)')
    const WXGA = useMedia('(max-width: 1400px)')
    const FULL_HD = useMedia('(max-width: 1920px)')
    const WQXGA = useMedia('(max-width: 2560px)')

    const getType = (type: string) => getBlueprintBackgroundByType(blueprintsBackgrounds, type)

    const getBlueprintType = (blueprintsBackgrounds: BlueprintsBackground[]): BlueprintsBackground | null => {
        if (!blueprintsBackgrounds) return null
        if (ULTRAWIDE_32_9) return getType('ULTRAWIDE_32_9')
        else if (ULTRAWIDE_21_9) return getType('ULTRAWIDE_21_9')
        else if (STANDARD_1280) return getType('STANDARD_1280')
        else if (WXGA) return getType('WXGA')
        else if (FULL_HD) return getType('FULL_HD')
        else if (WQXGA) return getType('WQXGA')
        else return getType('WQXGA')
    }

    return getBlueprintType(blueprintsBackgrounds)?.image || ''
}

export default useResizableBlueprints
