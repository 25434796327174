import React, { Component } from 'react'
import { Scrollbars, ScrollbarProps } from 'react-custom-scrollbars'
import { SpringSystem, Spring } from 'rebound'

type SpringScrollbarsProps = ScrollbarProps

export default class SpringScrollbars extends Component<SpringScrollbarsProps> {
    springSystem: SpringSystem
    spring: Spring
    scrollbars: Scrollbars

    componentDidMount() {
        this.springSystem = new SpringSystem()
        this.spring = this.springSystem.createSpring(100, 15)
        this.spring.addListener({ onSpringUpdate: this.handleSpringUpdate })
    }

    componentWillUnmount() {
        this.springSystem.deregisterSpring(this.spring)
        this.springSystem.removeAllListeners()
        this.springSystem = undefined
        this.spring.destroy()
        this.spring = undefined
    }

    getScrollTop() {
        return this.scrollbars.getScrollTop()
    }

    getScrollHeight() {
        return this.scrollbars.getScrollHeight()
    }

    getHeight() {
        return this.scrollbars.getHeight()
    }

    scrollTop(top: number) {
        const scrollTop = this.scrollbars.getScrollTop()
        this.spring.setCurrentValue(scrollTop).setAtRest()
        this.spring.setEndValue(top)
    }

    handleSpringUpdate = (spring: Spring) => {
        const val = spring.getCurrentValue()
        this.scrollbars.scrollTop(val)
    }

    render() {
        return (
            <Scrollbars
                {...this.props}
                ref={(r: Scrollbars) => {
                    this.scrollbars = r
                }}
            />
        )
    }
}
