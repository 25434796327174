import { IAccount } from '~/Reducers/ReducerApp'

export const defaultAccount: IAccount = {
    // boughtBoosters: [],
    completedActions: [],
    issuedRewards: [],
    boughtBoostersIds: [],
    issuedRewardsIds: [],
    completedActionsIds: [],
    pendingTransactions: false,
    progress: 0,
    shipLevelId: '',
    spaId: undefined,
    // speed: 0,
    wallet: {
        eventum: 0,
        gold: 0,
    },
}
