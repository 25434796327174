import { Interpolate, Divider, DivTooltip, Message, TooltipFooter } from '@wg/wows-react-uikit'
import { playShipYardSoftOver, playButtonClickSound } from '@wg/web2clientapi/sound'
import classnames from 'classnames'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './ReplayStep.scss'

export interface ReplayStep_Props {
    index: number
    count: number
    animationShipLevel: number
    descr: string
    isPlaying: boolean
    id: string
    isDisabled: boolean
    isCurrent: boolean
    isAnimationInProgress: boolean

    onClick: () => void
}

export interface ReplayStep_State {}

const ReplayStep = (props: ReplayStep_Props) => {
    const { index, descr, isPlaying, id, isDisabled, isCurrent, isAnimationInProgress, onClick, animationShipLevel } = props

    const { t } = useTranslation()

    const renderTitle = () => {
        if (index === 0) {
            return t('Подготовительный этап')
        }

        return <Interpolate str={t('Этап %(level)s')} level={index} />
    }

    const renderTooltip = () => {
        return (
            <div className={styles.tooltip}>
                <div className={styles.tooltipHeader}>{renderTitle()}</div>
                <Divider type={'major'} />
                <div className={styles.row}>
                    <Message style={'inline'} type={'done'}>
                        {t('Завершенный этап')}
                    </Message>
                </div>
                {!isAnimationInProgress && <TooltipFooter mouseIcon={'left'}>{t('Посмотреть этап строительства')}</TooltipFooter>}
            </div>
        )
    }

    const onMouseEnter = () => {
        playShipYardSoftOver()
    }

    const onClickHandler = () => {
        if (!isDisabled) {
            playButtonClickSound()
            onClick()
        }
    }

    const wrapper = classnames(styles.wrapper, {
        [styles.disabled]: isDisabled,
        [styles.playing]: isPlaying || isCurrent,
    })

    return (
        <DivTooltip tooltipBody={isDisabled ? undefined : renderTooltip()} onClick={onClickHandler}>
            <div className={wrapper} id={id} onMouseEnter={onMouseEnter}>
                <div className={styles.header}>
                    {isPlaying && <div className={styles.ripple} />}
                    {!isAnimationInProgress && index === animationShipLevel && <div className={styles.pointer} />}
                    {renderTitle()}
                </div>
                <div className={`${isPlaying ? styles.bodyActive : styles.body}`}>{descr}</div>
            </div>
        </DivTooltip>
    )
}

export default memo(ReplayStep, (prev: ReplayStep_Props, next: ReplayStep_Props) => {
    return (
        prev.isCurrent === next.isCurrent &&
        prev.isDisabled === next.isDisabled &&
        prev.isPlaying === next.isPlaying &&
        prev.isAnimationInProgress === next.isAnimationInProgress &&
        prev.animationShipLevel === next.animationShipLevel
    )
})
