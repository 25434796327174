import { ACTION_IDS, DWH_EXPORT_EVENTS } from '~/constants'
import { AnyAction } from 'redux'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Joyride, { CallBackProps, STATUS, Step, StoreHelpers, EVENTS } from 'react-joyride'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { playButtonClickSound } from '@wg/web2clientapi/sound'
import { switchSync, completeAction, turnGuideOff } from '~/Actions/ActionApp'
import { closeWelcomeDialog, openWelcomeDialog } from '~/Actions/ActionDialogs'
import { i18n_Props } from '~/i18n'
import { State } from '~/Reducers'
import './Guide.scss'
import styles from '~/Components/Guide/GuideTooltip.scss'
import getSteps from './guideSteps'
import GuideTooltip from './GuideTooltip'
import { IReward } from '~/Reducers/ReducerApp'
import Api from '~/types'
import dwhExport from '~/api/dwhExport'

type OwnProps = i18n_Props
const KEYCODE_ESC = 27
const KEYCODE_F5 = 116

interface DispatchProps {
    turnGuideOff: () => void
    openWelcomeDialog: () => void
    closeWelcomeDialog: () => void
    completeAction: (actionId: string) => void
    switchSync: (enabled: boolean) => void
}

interface StateProps {
    progressFinishTime: number
    guideIsRunning: boolean
    appIsReady: boolean
    isWelcomeDialogOpen: boolean
    isInGame: boolean
    rewards: Array<IReward>
    educationBackground: string
    educationSteps: Array<Api.EducationStep>
    shipId: string
    isVideoPlayerVisible: boolean
}

export type Props = StateProps & OwnProps & DispatchProps

interface IState {
    progressIsFinished: boolean
}

class Guide extends Component<Props, IState> {
    constructor(props: Props) {
        super(props)
        this.escPres = this.escPres.bind(this)
        this.state = { progressIsFinished: this.isProgressFinished() }
    }

    private prevIndex?: number

    private helpers?: StoreHelpers

    private welcomeDialogOpenWasOpen = false

    private getProgressFinishTimeout() {
        return this.props.progressFinishTime - Date.now()
    }

    private isProgressFinished() {
        return this.getProgressFinishTimeout() <= 0
    }

    private progressFinishTimer?: ReturnType<typeof setTimeout> = null

    private setProgressTimer() {
        this.clearProgressTimer()
        if (!this.isProgressFinished()) {
            this.progressFinishTimer = setTimeout(() => {
                this.setState({ progressIsFinished: this.isProgressFinished() })
            }, this.getProgressFinishTimeout() + 100)
        }
    }

    private clearProgressTimer() {
        if (this.progressFinishTimer !== null) {
            clearTimeout(this.progressFinishTimer)
        }
    }

    public componentDidMount() {
        if (this.props.isWelcomeDialogOpen && this.props.guideIsRunning) {
            this.welcomeDialogOpenWasOpen = true
            this.props.closeWelcomeDialog()
        }
        document.addEventListener('keydown', this.escPres, false)
        this.setProgressTimer()
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escPres, false)
        this.clearProgressTimer()
    }

    componentDidUpdate(prevProps: StateProps) {
        if (this.props.progressFinishTime !== prevProps.progressFinishTime) {
            this.setState({ progressIsFinished: this.isProgressFinished() })
            this.setProgressTimer()
        }
    }

    escPres = (event: any) => {
        if ([KEYCODE_ESC, KEYCODE_F5].includes(event.keyCode) && this.props.guideIsRunning && !this.props.isVideoPlayerVisible) {
            if (event.keyCode === KEYCODE_ESC) {
                this.props.turnGuideOff()
            }
            this.props.completeAction(ACTION_IDS.EDUCATION)
        }
    }

    escClick = () => {
        playButtonClickSound()
        this.props.turnGuideOff()
    }

    public render() {
        if (!this.props.appIsReady) {
            return null
        }

        return this.props.guideIsRunning && !this.props.isVideoPlayerVisible ? (
            <div>
                {this.props.guideIsRunning && <button onClick={this.escClick.bind(this)} className={styles.esc} />}
                <Joyride
                    callback={this.handleJoyrideCallback}
                    continuous={true}
                    getHelpers={this.getHelpers}
                    run={this.props.guideIsRunning}
                    scrollToFirstStep={false}
                    showProgress={true}
                    showSkipButton={false}
                    steps={getSteps({
                        t: this.props.t,
                        shipId: this.props.shipId,
                        educationSteps: this.props.educationSteps,
                    })}
                    tooltipComponent={GuideTooltip}
                    disableOverlayClose={false}
                    disableCloseOnEsc={true}
                    styles={{
                        options: {
                            zIndex: 2,
                            arrowColor: '#ffcc33',
                        },
                    }}
                />
            </div>
        ) : null
    }

    private getHelpers = (helpers: StoreHelpers) => {
        this.helpers = helpers
    }

    private handleJoyrideCallback = (data: CallBackProps) => {
        const { status, type, index } = data
        if (index === 0) {
            const element = document.getElementById('react-joyride-step-0')
            if (element) element.style.backgroundImage = `url(${this.props.educationBackground})`
        }
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]
        if (finishedStatuses.includes(status) && type === EVENTS.TOUR_END) {
            this.props.switchSync(true)
            this.props.turnGuideOff()
            this.props.completeAction(ACTION_IDS.EDUCATION)
        }
        if (this.prevIndex == undefined || index > this.prevIndex) {
            dwhExport.send(DWH_EXPORT_EVENTS.PASS_GUIDE_STEP, {
                step: index,
            })
        }
        this.prevIndex = index
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    guideIsRunning: state.ReducerApp.guideIsRunning,
    appIsReady: state.ReducerApp.appIsReady,
    progressFinishTime: state.ReducerApp.progressFinishTime,
    isWelcomeDialogOpen: state.ReducerDialogs.isWelcomeDialogOpen,
    isInGame: state.ReducerApp.isInGame,
    rewards: state.ReducerApp.rewards,
    educationBackground: state.ReducerApp.educationBackground,
    educationSteps: state.ReducerApp.educationSteps,
    shipId: state.ReducerApp.mainRewardShipId,
    isVideoPlayerVisible: state.ReducerDialogs.isVideoPlayerVisible,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<State, unknown, AnyAction>, ownProps: OwnProps): DispatchProps => {
    return {
        turnGuideOff: () => {
            dispatch(turnGuideOff())
        },
        openWelcomeDialog: () => {
            dispatch(openWelcomeDialog())
        },
        closeWelcomeDialog: () => {
            dispatch(closeWelcomeDialog())
        },
        completeAction: (actionId: string) => {
            dispatch(completeAction(actionId))
        },
        switchSync: (enabled: boolean) => {
            dispatch(switchSync(enabled))
        },
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Guide))
