import { Dialog } from '@wg/wows-react-uikit'
import React, { PureComponent } from 'react'
import MultipleBuyDialog from '~/Components/Dialogs/MultipleBuyDialog/MultipleBuyDialog'
import ErrorDialog from '~/Components/Dialogs/ErrorDialog/ErrorDialog'
import FinalDialog from '~/Components/Dialogs/FinalDialog/FinalDialog'
import RulesDialog from '~/Components/Dialogs/RulesDialog/RulesDialog'
import WelcomeDialog from '~/Components/Dialogs/WelcomeDialog/WelcomeDialog'
import BuyGoldDialog from '~/Components/Dialogs/BuyGoldDialog/BuyGoldDialog'
import Overlay from '~/Components/Overlay/Overlay'
import { Props } from '~/Containers/ContainerDialogs'
import styles from './ViewDialogs.scss'
import classnames from 'classnames'
import { State } from '~/Reducers'
import { connect } from 'react-redux'
import PurchaseConfirmation from '~/Components/Dialogs/PurchaseConfirmation/PurchaseConfirmation'

interface StateProps {
    finalBg: string
    buyBg: string
    isBuyGoldDialogVisible: boolean
    isRestRewardsDialogVisible: boolean
}

type ViewDialogsProps = StateProps & Props

class ViewDialogs extends PureComponent<ViewDialogsProps> {
    closeMultipleBuyDialogBounded = this.closeMultipleBuyDialog.bind(this)

    closeMultipleBuyDialog() {
        if (!this.props.isPurchaseConfirmationVisible && !this.props.isBuyGoldDialogVisible && !this.props.isRestRewardsDialogVisible)
            this.props.closeMultipleBuyDialog()
    }

    render() {
        const finalBg = this.props.finalBg ? `url("${this.props.finalBg}")` : ''
        const buyBg = this.props.buyBg ? `url("${this.props.buyBg}")` : ''
        return (
            <div className={styles.wrapper}>
                <Dialog
                    className={classnames({
                        [styles.multipleBuyDialog]: true,
                        [styles.noAnimation]: this.props.noAnimation,
                    })}
                    content={<RulesDialog key={'RulesDialog'} />}
                    size={'worship'}
                    isOverlay={false}
                    isVisible={this.props.isRulesDialogOpen}
                    hideDialog={this.props.closeRulesDialog}
                    overlayBackground={buyBg}
                />
                <Dialog
                    className={classnames(styles.multipleBuyDialog, {
                        [styles.multipleBuyDialog]: true,
                        [styles.noAnimation]: this.props.noAnimation,
                    })}
                    content={<MultipleBuyDialog key={'MultipleBuyDialog'} />}
                    size={'worship'}
                    isOverlay={false}
                    isVisible={this.props.isMultipleBuyDialogVisible}
                    hideDialog={this.closeMultipleBuyDialogBounded}
                    overlayBackground={buyBg}
                />
                <Dialog
                    className={classnames({
                        [styles.noAnimation]: this.props.noAnimation,
                    })}
                    content={<ErrorDialog key={'ErrorDialog'} />}
                    size={'worship'}
                    isOverlay={false}
                    isVisible={this.props.isErrorDialogOpen}
                    hideDialog={this.props.closeErrorDialog}
                />
                <Dialog
                    className={classnames(styles.finalDialog, {
                        [styles.noAnimation]: this.props.noAnimation,
                    })}
                    content={<FinalDialog key={'FinalDialog'} />}
                    size={'introduction'}
                    isOverlay={false}
                    isVisible={this.props.isFinalDialogOpen}
                    hideDialog={this.props.closeFinalDialog}
                    overlayBackground={finalBg}
                />
                <Overlay isVisible={this.props.isOverlayVisible} hide={this.props.hideOverlay} />
                <WelcomeDialog isVisible={this.props.isWelcomeDialogOpen} hide={this.props.closeWelcomeDialog} />
                <PurchaseConfirmation isVisible={this.props.isPurchaseConfirmationVisible} hide={this.props.closePurchaseConfirmation} />
                <BuyGoldDialog />
            </div>
        )
    }
}

const mapStateToProps = (state: State): StateProps => ({
    finalBg: state.ReducerApp.congratulationBackground || '',
    buyBg: state.ReducerApp.plugOnWeb || '',
    isBuyGoldDialogVisible: state.ReducerDialogs.isBuyGoldDialogVisible,
    isRestRewardsDialogVisible: state.ReducerDialogs.isRestRewardsDialogVisible,
})

export default connect(mapStateToProps)(ViewDialogs)
