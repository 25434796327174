export const updateProgressSpeed = (level: number, timings: Array<number>): void => {
    const index = level - 1
    const t = timings[index] || 1000

    const bar = document.getElementById('progressbar-bar')
    const barBlueprint = document.getElementById('progressbar-bar-blueprint')

    if (barBlueprint) {
        barBlueprint.style.transition = `transform ${t}ms linear`
    }
    if (bar) {
        bar.style.transition = `transform ${t}ms linear`
    }
}

export const dropProgressSpeed = (force = false): void => {
    const bar = document.getElementById('progressbar-bar')
    const barBlueprint = document.getElementById('progressbar-bar-blueprint')
    if (barBlueprint) {
        if (force) {
            barBlueprint.style.transition = ``
        } else {
            barBlueprint.style.transition = `transform 0.1s linear`
        }
    }
    if (bar) {
        if (force) {
            bar.style.transition = ``
        } else {
            bar.style.transition = `transform 0.1s linear`
        }
    }
}

class Timing {
    private levelTimingMap: LevelTimingMap = {}

    setStartTime = (start: number, level: number) => {
        this.levelTimingMap[level] = {
            start: start,
        }
    }

    setEndTime = (end: number, level: number) => {
        if (this.levelTimingMap[level]) {
            this.levelTimingMap[level].end = end
        }
    }

    showTimings = () => {
        const timings = []
        for (const key in this.levelTimingMap) {
            const delay = this.levelTimingMap[key].end - this.levelTimingMap[key].start
            console.info(`${key} - ${delay}`)
            timings.push(`${key} - ${delay}`)
        }
        return timings
    }
}

export const timing = new Timing()

export default updateProgressSpeed
