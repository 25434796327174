import get from 'lodash/get'
import log from 'loglevel'
import moment from 'moment'
import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import convert from 'xml-js'
import { buyBoosters } from '~/Actions/ActionBuy'
import { armoryOnAppInit } from '~/armory'
import ClientApi from '~/clientApi'
import { BlueprintViewerRef } from '~/Components/BlueprintViewer/BlueprintViewer'
import blueprintsState from '~/utils/blueprintsState'
import { getLength as getSvgPathLength } from '~/Components/BlueprintViewer/utils'
import { ACTION_IDS, BLUEPRINT_DURATION_MODIFIER, COLOR_ORDER, DWH_EXPORT_EVENTS, GOLD, GRADES } from '~/constants'
import api from '~/api'
import { mapBoosters, mapRewards } from '~/mapping'
import preloaded from '~/preloaded'
import { State } from '~/Reducers'
import { IAccount, IBooster, IReward } from '~/Reducers/ReducerApp'
import RefManager, { RefManagerKeys } from '~/refManager'
import * as Api from '~/types'
import { isOdd } from '~/utils'
import { getCurrentShipLevel, isGuidePassed } from '~/utils/account'
import { getBlueprintsCount, getLaunchingLevel, getVideoAction, isVideoWatched, mapActions } from '~/utils/actions'
import animationIterator from '~/utils/animationIterator'
import { preload } from '~/utils/preload'
import Sync from '~/utils/sync'
import { dropProgressSpeed } from '~/utils/updateProgressSpeed'
import {
    ClearBoosterId,
    HideRepeatSidebar,
    SetAnimationState,
    SetCompleteActionPendingState,
    SetGetDataPendingState,
    SetIssueRewardsPendingState,
    SetNextStep,
    SetPauseAnimation,
    SetPredictProgress,
    SetPreviousStep,
    SetProgressShipLevel,
    SetRepeatingState,
    SetSelectedBooster,
    SetShopLoadingState,
    SetTargetLevel,
    ShowRepeatSidebar,
    UpdateBackButtonVisibility,
    DisableBlur,
    EnableBlur,
    SetTimings,
    SetUsedStarters,
} from './ActionAppType'
import {
    setClientStateListener,
    setShipLevelLocal,
    setWorldRendererState,
    showErrorMessage,
    updateAnimationSubtitle,
    updateClientState,
} from './ActionClient'
import { addMockJsHost } from './ActionDev'
import {
    closeWelcomeDialog,
    hideLoadError,
    openFinalDialog,
    openWelcomeDialog,
    showFinalVideo,
    showIntroVideo,
    showLastChanceScreen,
    showLoadError,
    hideBlueprintsViewer,
    showBlueprintsViewer,
    showDownloadGame,
    hideDownloadGame,
} from './ActionDialogs'
import { getStartBoosterProgressRestriction, getStartBoostersIds } from '~/utils/boosters'
import mapExtraLevels from '~/mapping/levels'
import { loadRewards } from '~/utils/loadRewads'
import dwhExport from '~/api/dwhExport'
import { startTime } from '~/app'

const SYNC_INTERVAL = 1000 * 10
const SYNC_INTERVAL_PENDING = 1000

export const HIDE_DIALOG_DELAY = 500
export const EDUCATION_ACTION_ID = 'action_education'

export const AppInit = (initFromArmory = false): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch(addMessageListeners())
    dispatch(addMockJsHost())
    dispatch(getData(preloaded.state.account, preloaded.state.config, initFromArmory))

    console.log('App init with account state:', preloaded.state.account)

    dispatch(setClientStateListener())
    const state = getState()
    if (state.ReducerApp.isInIframe) {
        armoryOnAppInit()
    }
}

export const MAKE_COMPENSATION = 'MAKE_COMPENSATION'
export const makeCompensation = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const state = getState()
    const eventum = get(state.ReducerApp, 'account.wallet.eventum', 0) as number

    if (eventum > 0) {
        dispatch(switchSync(false))
        api.post('/accounts/change-extra-dockyardum/', {
            count: eventum,
        })
            .then((resp) => {
                dispatch(updateAccount(resp))
            })
            .catch((error: Error) => {
                dispatch(showErrorMessage(error))
                log.error(error)
            })
            .finally(() => {
                dispatch(switchSync(true))
            })
    }
}

export const ISSUUE_REWARDS = 'ISSUUE_REWARDS'
export const issueRewards = (rewardIds?: Array<string>, fromDialog?: boolean): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch(setIssueRewardsPendingState(true))
    const state = getState()

    if (state.ReducerDialogs.isWelcomeDialogOpen) {
        dispatch(closeWelcomeDialog())
    }
    dispatch(switchSync(false))
    api.post('/accounts/issue-rewards/', {
        rewardIds: rewardIds ? rewardIds : state.ReducerApp.availableRewards,
    })
        .then((resp) => {
            if (state.ReducerDialogs.isWelcomeDialogOpen && !rewardIds) {
                dispatch(closeWelcomeDialog())
            }
            if (state.ReducerApp.isEndReached && fromDialog) {
                dispatch(makeCompensation())
            }
            dispatch(updateAccount(resp))
        })
        .catch((error: Error) => {
            dispatch(showErrorMessage(error))
            log.error(error)
        })
        .finally(() => {
            dispatch(switchSync(true))
            dispatch(setIssueRewardsPendingState(false))
        })
}

export const SET_ISSUE_REWARDS_PENDING_STATE = 'SET_ISSUE_REWARDS_PENDING_STATE'
export const setIssueRewardsPendingState = (isPending: boolean): SetIssueRewardsPendingState => {
    return {
        type: SET_ISSUE_REWARDS_PENDING_STATE,
        payload: {
            isPending,
        },
    }
}

export const UPDATE_AVAILABLE_REWARDS = 'UPDATE_AVAILABLE_REWARDS'
export const updateAvailableRewards = (collectShips = false): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const availableRewardsIds: Array<string> = []
    const autocollectRewardsIds: Array<string> = []
    const state = getState()
    const progress = state.ReducerApp.account.progress
    const issuedRewardsIds = state.ReducerApp.account.issuedRewardsIds

    state.ReducerApp.rewards.forEach((reward: IReward) => {
        reward.gameRewards.forEach((gameReward) => {
            if (gameReward.type === 'ship' && reward.value <= progress && !issuedRewardsIds.includes(reward.id)) {
                autocollectRewardsIds.push(reward.id)
            }
        })

        if (reward.value <= progress && !issuedRewardsIds.includes(reward.id)) {
            availableRewardsIds.push(reward.id)
        }
    })

    if (autocollectRewardsIds.length > 0 && collectShips) {
        dispatch(issueRewards(autocollectRewardsIds))
    }

    dispatch({
        type: UPDATE_AVAILABLE_REWARDS,
        payload: {
            availableRewardsIds,
        },
    })
}

export const SET_SELECTED_BOOSTER = 'SET_SELECTED_BOOSTER'
export const setSelectedBooster = (booster: IBooster | null): SetSelectedBooster => {
    return {
        type: SET_SELECTED_BOOSTER,
        payload: {
            booster,
            boosterId: booster ? booster.id : '',
        },
    }
}

export const CLEAR_BOOSTER_ID = 'CLEAR_BOOSTER_ID'
export const clearBoosterId = (): ClearBoosterId => {
    return {
        type: CLEAR_BOOSTER_ID,
    }
}

export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const updateAccount = (account: Api.Account, isInstant = false): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    if (account) {
        dropProgressSpeed()
        const state = getState()
        const currentShipLevel = getCurrentShipLevel(account, state.ReducerApp.shipLevels)
        const lastLevel: number = state.ReducerApp.shipLevels.length - 1
        const isEndReached = lastLevel === currentShipLevel
        const isEventFinished = moment.utc().unix() * 1000 >= state.ReducerApp.progressFinishTime

        const stateAccount: IAccount = {
            ...account,
            issuedRewards: account.issuedRewardsIds.map((id) => state.ReducerApp.rewards.find((reward) => reward.id === id)),
            completedActions: account.completedActionsIds.map((id) => state.ReducerApp.actions.find((action) => action.name === id)),
            wallet: {
                eventum: account.balance.dockyardum || 0,
                gold: account.balance.gold || 0,
            },
        }

        const startBoosterIds = getStartBoostersIds(state.ReducerApp.boosters)
        const isStartBoostersBought =
            state.ReducerApp?.account?.boughtBoostersIds && startBoosterIds.every((id) => state.ReducerApp.account.boughtBoostersIds.includes(id))

        dispatch({
            type: UPDATE_ACCOUNT,
            payload: {
                account: stateAccount,
                isEndReached,
                isEventFinished,
                lastLevel,
                isStartBoostersBought,
            },
        })
        if (!isEndReached) {
            dispatch(buyBoosters(false))
        }
        setTimeout(() => dispatch(updateAvailableRewards()), 1000)
        dispatch(showLastChanceScreen())
        if (account.progress !== state.ReducerApp.progressShipLevel) {
            dispatch({
                type: SET_TARGET_LEVEL,
                payload: {
                    level: account.progress,
                },
            })
            dispatch({
                type: SET_PROGRESS_SHIP_LEVEL,
                payload: {
                    level: account.progress,
                },
            })
        }
        dispatch(updateClientState(account, isInstant))
    } else {
        log.error('Account:', account)
    }
}

export const CHECK_AVAILABLE_REWARDS = 'CHECK_AVAILABLE_REWARDS'
export const checkAvailableRewards = (initFromArmory = false): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const state = getState()
    const rewards = state.ReducerApp.rewards.filter((reward) => {
        return state.ReducerApp.availableRewards.includes(reward.id) && reward.gameRewards && reward.gameRewards.length > 0
    })
    if (rewards.length > 0 && isGuidePassed(state.ReducerApp.account.completedActionsIds)) {
        if (!initFromArmory) {
            dispatch(openWelcomeDialog())
        }
    }
}

export const APP_IS_READY = 'APP_IS_READY'
export const appIsReady = (initFromArmory = false): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
    dispatch({
        type: APP_IS_READY,
    })
    ClientApi.hideScreenshot()
    dispatch(checkAvailableRewards(initFromArmory))
}

export const CHECK_GUIDE_IS_FINISHED = 'CHECK_GUIDE_IS_FINISHED'
export const checkGuideIsFinished = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const state = getState()
    const isEducationPassed = isGuidePassed(state.ReducerApp.account.completedActionsIds)

    if (!isEducationPassed && !preloaded.settings.skipTutorial) {
        dispatch(turnGuideOn())
    } else {
        dispatch(checkAvailableRewards())
    }
}

export const SET_GET_DATA_PENDING_STATE = 'SET_GET_DATA_PENDING_STATE'
export const setGetDataPendingState = (isPending: boolean): SetGetDataPendingState => {
    return {
        type: SET_GET_DATA_PENDING_STATE,
        payload: {
            isPending,
        },
    }
}

export const GET_DATA = 'GET_DATA'
export const getData = (
    preloadedAccount?: Api.Account,
    preloadedConfig?: Api.Config,
    initFromArmory = false,
): ThunkAction<void, unknown, unknown, AnyAction> => (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: () => State) => {
    dispatch(hideLoadError())

    const setData = async (data: Api.ApiQuery) => {
        const animationShipLevel = data.account ? getCurrentShipLevel(data.account, data.config.shipLevels) : 0
        const actionLevels = mapActions(data.config.actions)
        const spaId = data.account.spaId as number
        const rewards = await loadRewards(mapRewards(data.config.rewards, data.config.shipLevels))
        const lastLevel = data.config.shipLevels?.length ? data.config.shipLevels?.length - 1 : 0
        let freeLevels = lastLevel - data.config.events.levelsCompletedBySpendingDoubloons
        if (!freeLevels || freeLevels < 1) freeLevels = 0
        const boosters = mapBoosters(data.config.boosters)
        dispatch({
            type: GET_DATA,
            payload: {
                showThirdCard: isOdd(spaId),
                mainRewardShipId: data.config.mainRewardShipId,
                mainRewardShipName: data.config.mainRewardName,
                secondaryShipLevels: mapExtraLevels(data.config.stuffLevels, false),
                tertiaryShipLevels: mapExtraLevels(data.config.furtherLevels, true),
                boosters: boosters,
                rewards: rewards,
                shipLevels: data.config.shipLevels,
                launchingLevelId: getLaunchingLevel(data),
                actions: data.config.actions,
                actionLevels: actionLevels,
                isIntroWatched: data.account ? isVideoWatched(ACTION_IDS.INTRO_VIDEO, data.account.completedActionsIds) : false,
                progressFinishTime: Date.parse(data.config.events.eventScheduleSettings.progressFinishDatetime),
                progressStartTime: Date.parse(data.config.events.eventScheduleSettings.openDatetime),
                progressFinishTimeDefault: Date.parse(data.config.events.eventScheduleSettings.progressFinishDatetime),
                progressStartTimeDefault: Date.parse(data.config.events.eventScheduleSettings.openDatetime),
                startersTimeLimit: Date.parse(data.config.events.eventScheduleSettings.startersTimeLimit),
                currency: data.config.events.currency,
                animationShipLevel: animationShipLevel,
                closeTime: Date.parse(data.config.events.eventScheduleSettings.closeDatetime),
                blueprintsCount: getBlueprintsCount(data.config.shipLevels),
                congratulationBackground: data.config.events.eventContent.arts.congratulationBackground,
                educationBackground: data.config.events.eventContent.arts.educationBackground,
                blueprintsBackgrounds: data.config.events.eventContent.arts.blueprintsBackgrounds,
                blueprintArts: [],
                plugOnWeb: data.config.events.eventContent.arts.plugOnWeb,
                readMoreLink: data.config.events.eventContent.readMoreLink,
                rules: data.config.events.eventContent.rules,
                sseSectionName: data.config.events.eventContent.sseSectionName,
                compensations: data.config.events.eventContent.rewardForExchange.items,
                specialRewards: rewards.filter((reward) => reward.grade !== GRADES.NORMAL),
                educationSteps: data.config.events.eventContent.education,
                levelsCompletedBySpendingDoubloons: data.config.events.levelsCompletedBySpendingDoubloons,
                freeLevels: freeLevels,
                lastLevel: lastLevel,
                starterPacksMaxLevel: getStartBoosterProgressRestriction(boosters),
                timings: data.config.events.eventContent.progressbarDurations,
                missionsFinishVersion: data.config.events.finishSseVersion?.[0],
                eventFinishVersion: data.config.events.finishSseVersion?.[1],
                showDevPanel: data.config.events.eventMode.showDevPanel,
                showResetButton: data.config.events.eventMode.showResetButton,
            },
        })

        dispatch(updateAccount(data.account, true))
        dispatch(updateAnimationSubtitle(animationShipLevel, true))
        const state = getState()

        // preload(state)

        if (state.ReducerApp.isIntroWatched || initFromArmory) {
            dispatch(setGetDataPendingState(false))
            if (
                state.ReducerApp.isIntroWatched &&
                state.ReducerApp.account.progress > 0 &&
                !isGuidePassed(state.ReducerApp.account.completedActionsIds)
            ) {
                dispatch(completeAction(ACTION_IDS.EDUCATION))
            }
        } else {
            dispatch(showIntroVideo())
            dispatch(turnGuideOn())
        }

        dispatch(appIsReady(initFromArmory))

        dwhExport.send(DWH_EXPORT_EVENTS.APP_LOADED)

        window.addEventListener('beforeunload', async function (e) {
            const endTime = Date.now()
            const duration = Math.floor((endTime - startTime) / 1000)
            const progress = getState().ReducerApp.account.progress
            await dwhExport.send(DWH_EXPORT_EVENTS.USER_ACTIVITY_TIME, { duration, progress }, true)
        })

        if (data.account) {
            const regularSync = new Sync(SYNC_INTERVAL, false, dispatch, getState)
            const pendingSync = new Sync(SYNC_INTERVAL_PENDING, true, dispatch, getState)
        }
    }

    if (preloadedAccount && preloadedConfig && Object.keys(preloadedAccount).length > 0 && Object.keys(preloadedConfig).length > 0) {
        if (getState().ReducerApp.isInGame) {
            dispatch(setGetDataPendingState(true))
        }
        const account: Api.Account = preloadedAccount as Api.Account
        const config: Api.Config = preloadedConfig as Api.Config
        const data: Api.ApiQuery = {
            account: account,
            config: config,
        }
        setData(data).then(() => {
            if (!data.account.isActivated && !ClientApi.isInGame()) {
                dispatch(showDownloadGame())
            } else {
                dispatch(hideDownloadGame())
            }
        })
        if (!data.account.isActivated && !ClientApi.isInGame()) {
            dispatch(showDownloadGame())
        } else {
            dispatch(hideDownloadGame())
        }
    } else {
        dispatch(setGetDataPendingState(true))
        const getDataQueryArr = [api.get('/config/current/'), api.get('/accounts/account-info/')]

        Promise.all(getDataQueryArr)
            .then(
                (responses): Api.ApiQuery => {
                    return {
                        config: responses[0],
                        account: responses[1],
                    }
                },
            )
            .then(setData)
            .then(() => {
                dispatch(setGetDataPendingState(false))
            })
            .catch((errors: Array<Error>) => {
                dispatch(setGetDataPendingState(false))
                if (Array.isArray(errors)) {
                    errors.forEach((error: Error) => {
                        dispatch(showErrorMessage(error))
                        log.error(error)
                    })
                } else {
                    dispatch(showErrorMessage(errors))
                    log.error(errors)
                }
                dispatch(showLoadError())
            })
    }
}

export const TURN_GUIDE_ON = 'TURN_GUIDE_ON'
export const turnGuideOn = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: TURN_GUIDE_ON,
    })
}

export const TURN_GUIDE_OFF = 'TURN_GUIDE_OFF'
export const turnGuideOff = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: TURN_GUIDE_OFF,
    })
    dispatch(checkAvailableRewards())
}

export const ADD_EVENTIN = 'ADD_EVENTIN'
export const addEventin = (value: number): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const currency = getState().ReducerApp.currency
    api.post('/accounts/replenish-wallet/', {
        price: {
            currency: currency,
            amount: value,
        },
    })
        .then((resp) => {
            dispatch(updateAccount(resp))
        })
        .catch((error: Error) => {
            dispatch(showErrorMessage(error))
            log.error(error)
        })
}

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const deleteAccount = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const state = getState()
    const spaId: number = Number(state.ReducerApp.account.spaId) || 0

    api.delete(`/accounts/${spaId}/`)
        .then((resp) => {
            log.log('Account deleted')
            dispatch(updateAnimationSubtitle(0, true)) //
            dispatch(sync())
        })
        .catch((error: Error) => {
            dispatch(showErrorMessage(error))
            log.error(error)
        })
}

export const SWITCH_SYNC = 'SWITCH_SYNC'
export const switchSync = (value?: boolean): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const state = getState()
    const isSyncEnabled = state.ReducerApp.account.pendingTransactions || value || !state.ReducerApp.isSyncEnabled

    dispatch({
        type: SWITCH_SYNC,
        payload: {
            isSyncEnabled: isSyncEnabled,
        },
    })
}

export const STOP_REPEAT_BUILDING_PROCESS = 'STOP_REPEAT_BUILDING_PROCESS'
export const stopRepeatBuildingProcess = (clb?: () => void, stop?: number): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    ClientApi.clearWaitingList()

    const blueprintsViewer = RefManager.getRef(RefManagerKeys.BlueprintViewer) as BlueprintViewerRef
    if (blueprintsViewer) {
        blueprintsViewer.setIsInstant(true)
    }
    const { account, animationShipLevel, lastLevel, shipLevels, nextStep, previousStep, blueprintsCount } = getState().ReducerApp
    const currentShipLevel = stop ? stop : animationShipLevel

    animationIterator.forceStop()

    if (currentShipLevel > blueprintsCount) {
        dispatch(hideBlueprintsViewer())
    }

    dispatch(setAnimationState(false))
    dispatch(setRepeatingState(false))
    dispatch(switchSync(true))

    const afterSetShipLevelLocal = (level: number) => {
        dispatch(
            updateAnimationSubtitle(level, true, () => {
                if (blueprintsViewer) {
                    blueprintsViewer.setIsInstant(false)
                }
                if (clb) {
                    clb()
                }
            }),
        )
    }

    const setLevel = (level: number) => {
        dispatch(
            setShipLevelLocal(level, true, () => {
                dropProgressSpeed(true)
                afterSetShipLevelLocal(level)
            }),
        )
    }
    if (nextStep) {
        dispatch(setNextStep(false))
        dispatch(
            setShipLevelLocal(currentShipLevel + 1, true, () => {
                setLevel(currentShipLevel)
            }),
        )
    } else if (previousStep) {
        dispatch(setPreviousStep(false))
        const level = currentShipLevel === 0 ? currentShipLevel : currentShipLevel - 1
        setLevel(level)
    } else {
        setLevel(currentShipLevel)
        dispatch({
            type: SET_TARGET_LEVEL,
            payload: {
                level: currentShipLevel,
            },
        })
    }
}

export const pauseRepeatBuildingProcess = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    ClientApi.clearWaitingList()
    const state = getState()

    animationIterator.forceStop()

    dispatch({
        type: SET_TARGET_LEVEL,
        payload: {
            level: state.ReducerApp.animationShipLevel,
        },
    })

    dispatch(setAnimationState(false))
    dispatch(setRepeatingState(false))
    dispatch(switchSync(true))
}

export const REPEAT_BUILDING_PROCESS = 'REPEAT_BUILDING_PROCESS'
export const repeatBuildingProcess = (fromLevel = 0): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    ClientApi.clearWaitingList()
    blueprintsState.clear()
    const blueprintsViewer = RefManager.getRef(RefManagerKeys.BlueprintViewer) as BlueprintViewerRef
    const { account, isRepeating, blueprintsCount } = getState().ReducerApp

    const repeat = () => {
        const currentShipLevel = account.progress
        const level = fromLevel === 0 ? fromLevel : fromLevel - 1

        if (blueprintsViewer) {
            blueprintsViewer.setIsInstant(true)
        }

        dispatch(setRepeatingState(true))
        dispatch(switchSync(false))
        dispatch(
            setShipLevelLocal(level, true, () => {
                dispatch(
                    updateAnimationSubtitle(level, true, () => {
                        if (level < blueprintsCount) {
                            dispatch(showBlueprintsViewer())
                        }
                        setTimeout(() => {
                            if (blueprintsViewer) {
                                blueprintsViewer.setIsInstant(false)
                            }
                            dispatch(setShipLevelLocal(currentShipLevel, false))
                        }, 100)
                    }),
                )
            }),
        )
    }

    if (isRepeating) {
        dispatch(stopRepeatBuildingProcess(repeat))
    } else {
        repeat()
    }
}

export const SET_PAUSE_ANIMATION = 'SET_PAUSE_ANIMATION'
export const setPauseAnimation = (isPauseAnimation: boolean): SetPauseAnimation => {
    return {
        type: SET_PAUSE_ANIMATION,
        payload: {
            isPauseAnimation,
        },
    }
}

export const SET_REPEATING_STATE = 'SET_REPEATING_STATE'
export const setRepeatingState = (isRepeating: boolean): SetRepeatingState => {
    return {
        type: SET_REPEATING_STATE,
        payload: {
            isRepeating,
        },
    }
}

export const AFTER_SKIP_VIDEO = 'AFTER_SKIP_VIDEO'
export const afterSkipVideo = (videoType: VideoType): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const state = getState()
    const videoAction = getVideoAction(state.ReducerApp.actions, videoType)
    const completedActionsIds = state.ReducerApp.account.completedActionsIds
    const isFinalPopupSeen = completedActionsIds.includes(ACTION_IDS.GOLD_REWARD_POPUP)

    dispatch(completeAction(videoAction.name))
    if (videoType === 'intro') {
        dispatch(checkGuideIsFinished())
        dispatch(appIsReady())
    }

    if (videoType === 'launch') {
        animationIterator.continueAction()
    }

    if (videoType === 'final') {
        if (isFinalPopupSeen) {
            animationIterator.actionFinished()
        } else {
            dispatch(openFinalDialog(state.ReducerApp.mainRewardShipName))
        }
    }

    if (videoType === 'completeBlueprints') {
        animationIterator.continueAction()
    }
}

export const SET_COMPLETE_ACTION_PENDING_STATE = 'SET_COMPLETE_ACTION_PENDING_STATE'
export const setCompleteActionPendingState = (isPending: boolean): SetCompleteActionPendingState => {
    return {
        type: SET_COMPLETE_ACTION_PENDING_STATE,
        payload: {
            isPending,
        },
    }
}

export const COMPLETE_ACTION = 'COMPLETE_ACTION'
export const completeAction = (actionId: string): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch(setCompleteActionPendingState(true))
    dispatch(switchSync(false))
    api.post('/accounts/complete-action/', {
        actionId: actionId,
    })
        .then((resp) => {
            dispatch(updateAccount(resp))
        })
        .catch((error: Error) => {
            dispatch(showErrorMessage(error))
            log.error(error)
        })
        .finally(() => {
            dispatch(switchSync(true))
            dispatch(setCompleteActionPendingState(false))
        })
}

export const SET_TARGET_LEVEL = 'SET_TARGET_LEVEL'
export const setTargetLevel = (level: number | null): SetTargetLevel => {
    return {
        type: SET_TARGET_LEVEL,
        payload: {
            level,
        },
    }
}
export const SET_PROGRESS_SHIP_LEVEL = 'SET_PROGRESS_SHIP_LEVEL'
export const setProgressShipLevel = (level: number): SetProgressShipLevel => {
    return {
        type: SET_PROGRESS_SHIP_LEVEL,
        payload: {
            level,
        },
    }
}
export const SET_NEXT_STEP = 'SET_NEXT_STEP'
export const setNextStep = (nextStep: boolean): SetNextStep => {
    return {
        type: SET_NEXT_STEP,
        payload: {
            nextStep,
        },
    }
}
export const SET_PREVIOUS_STEP = 'SET_PREVIOUS_STEP'
export const setPreviousStep = (previousStep: boolean): SetPreviousStep => {
    return {
        type: SET_PREVIOUS_STEP,
        payload: {
            previousStep,
        },
    }
}

export const SHOW_REPEAT_SIDEBAR = 'SHOW_REPEAT_SIDEBAR'
export const showRepeatSidebar = (): ShowRepeatSidebar => {
    return {
        type: SHOW_REPEAT_SIDEBAR,
    }
}

export const HIDE_REPEAT_SIDEBAR = 'HIDE_REPEAT_SIDEBAR'
export const hideRepeatSidebar = (): HideRepeatSidebar => {
    return {
        type: HIDE_REPEAT_SIDEBAR,
    }
}

export const SET_ANIMATION_STATE = 'SET_ANIMATION_STATE'
export const setAnimationState = (isAnimationInProgress: boolean): SetAnimationState => {
    return {
        type: SET_ANIMATION_STATE,
        payload: {
            isAnimationInProgress,
        },
    }
}

export const SYNC = 'SYNC'
export const sync = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    api.get('/accounts/account-info/')
        .then((resp) => {
            dispatch(updateAccount(resp))
        })
        .catch((error: Error) => {
            dispatch(showErrorMessage(error))
        })
}

export const SET_PREDICT_PROGRESS = 'SET_PREDICT_PROGRESS'
export const setPredictProgress = (predictProgress: number): SetPredictProgress => {
    return {
        type: SET_PREDICT_PROGRESS,
        payload: {
            predictProgress,
        },
    }
}

export const SET_USED_STARTERS = 'SET_USED_STARTERS'
export const setUsedStarters = (starters: Array<string>): SetUsedStarters => {
    return {
        type: SET_USED_STARTERS,
        payload: {
            usedStarters: starters,
        },
    }
}

export const SET_SHOP_LOADING_STATE = 'SET_SHOP_LOADING_STATE'
export const setShopLoadingState = (isLoading: boolean): SetShopLoadingState => {
    return {
        type: SET_SHOP_LOADING_STATE,
        payload: {
            isLoading: isLoading,
        },
    }
}

export const addMessageListeners = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const onMessage = (e: MessageEvent) => {
        const msg: ShopMessage = e.data as ShopMessage
        switch (msg.action) {
            case 'ON_ROUTE_CHANGE': {
                dispatch(updateBackButtonVisibility(msg.payload.path))
                break
            }
            case 'LOADER_STATE': {
                dispatch(setShopLoadingState(msg.payload.visibility))
                break
            }
            case 'WINSTORE_INIT': {
                dispatch(setWinStoreCallbacks(msg.payload.callbacks))
                break
            }
            default: {
                break
            }
        }
    }

    window.addEventListener('message', onMessage)
}

export const setWinStoreCallbacks = (methods: Array<string>): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const send = (method: string, args: any[]) => {
        const frame = RefManager.getRef(RefManagerKeys.SteambotFrame) as HTMLIFrameElement
        if (frame) {
            const msg = {
                action: 'ON_WINSTORE_CALLBACK',
                payload: {
                    name: method,
                    args: args,
                },
            }
            frame.contentWindow.postMessage(msg, '*')
        }
    }

    ;(<any>window).winStore = {}

    methods.forEach((method) => {
        ;(<any>window).winStore[method] = (...rest: any[]) => {
            send(method, rest)
        }
    })
}

export const UPDATE_BACK_BUTTON_VISIBILITY = 'UPDATE_BACK_BUTTON_VISIBILITY'
export const updateBackButtonVisibility = (path: string): UpdateBackButtonVisibility => {
    return {
        type: UPDATE_BACK_BUTTON_VISIBILITY,
        payload: {
            isVisible: path !== '/',
        },
    }
}

export const GET_BLUEPRINTS_SVG_DATA = 'GET_BLUEPRINTS_SVG_DATA'
export const getBlueprintsSvgData = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const getPrevColor = (color: LINE_COLORS): LINE_COLORS | null => {
        const curentColorIndex = COLOR_ORDER[color]
        if (curentColorIndex === 0) {
            return null
        } else {
            let c: LINE_COLORS | null = null
            const prevColorIndex = curentColorIndex - 1
            for (const key in COLOR_ORDER) {
                const selector = key as LINE_COLORS
                if (COLOR_ORDER[selector] === prevColorIndex) {
                    c = selector
                }
            }
            return c
        }
    }

    const svgData: BlueprintsSvgData = {}
    const state = getState()
    const durations: Array<number> = []

    const loadLevelSvg = (level: number, maxLevel: number) => {
        const shipLevel = state.ReducerApp.shipLevels[level]
        const url = shipLevel.blueprintsSvg

        fetch(url)
            .then((response) => response.text())
            .then((svg) => {
                const svgJs = convert.xml2js(svg)
                const rootElement = get(svgJs, 'elements[0]', undefined)
                if (rootElement && rootElement.elements) {
                    const levelDurations: Array<number> = []
                    const groupDurations: GroupDurations = {}

                    const sortedElements = rootElement.elements.sort((a: SvgElement, b: SvgElement) => {
                        const aColor: LINE_COLORS = a.attributes.stroke || a.attributes.fill
                        const bColor: LINE_COLORS = b.attributes.stroke || b.attributes.fill
                        return COLOR_ORDER[aColor] - COLOR_ORDER[bColor]
                    })

                    const pathDataArray = sortedElements.map((element: SvgElement) => {
                        const color = element.attributes.stroke || element.attributes.fill
                        if (!groupDurations[color]) {
                            groupDurations[color] = []
                        }
                        const path = element.attributes.d
                        const length = getSvgPathLength(path)
                        let duration = length / BLUEPRINT_DURATION_MODIFIER
                        if (duration > 5) {
                            duration = 5
                        }
                        if (duration < 1) {
                            duration = 1
                        }

                        groupDurations[color].push(duration)

                        const getDelay = (color: LINE_COLORS) => {
                            const currentIndex = COLOR_ORDER[color]
                            const prevColor = getPrevColor(color)
                            if (prevColor) {
                                let delay = 0
                                for (const key in COLOR_ORDER) {
                                    const selector = key as LINE_COLORS
                                    const i = COLOR_ORDER[selector]
                                    if (i < currentIndex) {
                                        const colorTimings = groupDurations[selector]
                                        delay += Math.max(...colorTimings)
                                    }
                                }
                                return delay
                            } else {
                                return 0
                            }
                        }

                        const delay = getDelay(color)
                        levelDurations.push(duration + delay)

                        return {
                            path: path,
                            delay: delay,
                            duration: duration,
                        }
                    })

                    const longestLevelDuration = Math.max(...levelDurations)
                    durations.push(Math.round(longestLevelDuration))

                    svgData[level] = pathDataArray

                    if (level < maxLevel) {
                        loadLevelSvg(level + 1, maxLevel)
                    }
                    if (level === maxLevel) {
                        const newTimings = [...state.ReducerApp.timings]
                        durations.forEach((duration, index) => {
                            newTimings[index] = duration
                        })
                        dispatch(setTimings(newTimings))

                        dispatch({
                            type: GET_BLUEPRINTS_SVG_DATA,
                            payload: {
                                data: svgData,
                                durations: durations,
                            },
                        })
                    }
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    loadLevelSvg(0, state.ReducerApp.blueprintsCount)
}

export const SET_TIMINGS = 'SET_TIMINGS'
export const setTimings = (timings: Array<number>): SetTimings => {
    return {
        type: SET_TIMINGS,
        payload: {
            timings: timings,
        },
    }
}

export const ENABLE_BLUR = 'ENABLE_BLUR'
export const enableBlur = (): EnableBlur => {
    return {
        type: ENABLE_BLUR,
    }
}

export const DISABLE_BLUR = 'DISABLE_BLUR'
export const disableBlur = (): DisableBlur => {
    return {
        type: DISABLE_BLUR,
    }
}
