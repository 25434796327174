import '@wg/wows-css-uikit/index.css'
import { applyMiddleware, createStore } from 'redux'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import dialogListenerMiddleware from '~/middleware/dialogListenerMiddleware'
import { Actions } from './Actions'
import reducer, { State } from './Reducers'

const middleware = [thunk as ThunkMiddleware<State, Actions>]

middleware.push(dialogListenerMiddleware)

const store = createStore(reducer, applyMiddleware(...middleware))

window.store = store as GlobalStore

export default store
