import * as Sentry from '@sentry/browser'
import get from 'lodash/get'
import log from 'loglevel'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { searchToObject } from '~/utils'
import App from './app'

const queryParams = searchToObject()
const isDevMode = queryParams.devMode === true

const timer = (callback: FrameRequestCallback): number => {
    setTimeout(callback, 1000 / 60)
    return 0
}

window.requestAnimFrame = (() => {
    return (
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        timer
    )
})()

export const isProd = IS_PROD_BUILD ? true : false

log.setDefaultLevel(isProd ? log.levels.ERROR : log.levels.DEBUG)

const dsn: string = get(window, 'dockyard.settings.sentry.dsn', '')
const isSentryEnabled: string = get(window, 'dockyard.settings.sentry.isEnabled', false)

if (isSentryEnabled) {
    if (dsn == '') {
        log.error('Sentry DSN is empty!')
    } else {
        Sentry.init(get(window, 'dockyard.settings.sentry'))
    }
}

ReactDOM.render(<App />, document.getElementById('app'))
