import React, { useRef, useState } from 'react'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import { useMount } from '~/hooks'
import styles from './Slider.scss'
import classnames from 'classnames'

export interface SliderLabel {
    index: number
    content: React.ReactNode
}

export type SliderLabels = Array<SliderLabel>

export interface Slider_Props {
    min: number
    max: number
    minValue: number
    progress: number
    labels: SliderLabels
    after?: React.ReactNode
    value?: number
    blueprintsCount: number
    onChange?: (delta: number) => void
    onDrag?: (delta: number) => void
}

const Slider = (props: Slider_Props) => {
    const { min, max, progress, labels, minValue, after, value, blueprintsCount, onChange, onDrag } = props

    const markerWidth = 12
    const [fullWidth, setWidth] = useState<number | undefined>()
    const [hoveredIndex, setHoveredIndex] = useState<number | undefined>()
    let wrapperRef = useRef<HTMLDivElement | undefined>().current

    const count = max - min
    const width = fullWidth ? fullWidth : 0
    const step = width ? width / count : 10
    const progressWidth = Math.min(progress * step, width)
    const minMarkerPosition = (progress + minValue) * step - markerWidth / 2
    const maxMarkerPosition = width - markerWidth / 2
    const predictSize = Math.min((progress + value) * step, width)
    const predictSizeWidth = Math.max(predictSize - progressWidth, minValue * step)
    const isPredictVisible = predictSizeWidth > 0
    const predictLabel = Math.max(progress + value, progress + minValue)
    const positionX = (value + progress) * step - markerWidth / 2

    const onResize = () => {
        setWrapperWidth()
    }

    useMount(() => {
        window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize', onResize)
        }
    })

    const setWrapperRef = (ref?: HTMLDivElement) => {
        if (ref) {
            wrapperRef = ref
            setWrapperWidth()
        }
    }

    const setWrapperWidth = () => {
        const width = wrapperRef.clientWidth
        setWidth(width)
    }

    const onDragHandler = (e: DraggableEvent, data: DraggableData) => {
        const val = Math.round(data.x / step) - progress

        if (onDrag) {
            onDrag(val)
        }
    }

    const onStop = (e: DraggableEvent, data: DraggableData) => {
        const val = Math.round(data.x / step) - progress
        if (onChange) {
            onChange(val)
        }
    }

    const onClickablePointClick = (index: number) => () => {
        const val = index + 1
        if (val >= minValue) {
            if (onDrag) {
                onDrag(val)
            }
        }
    }

    const onClickablePointMouseEnter = (index: number) => () => {
        setHoveredIndex(index)
    }

    const onClickablePointMouseLeave = () => () => {
        setHoveredIndex(undefined)
    }

    const renderClickablePoints = () => {
        const items = new Array(max - progress).fill('')
        return (
            <div
                style={{
                    width: width - progressWidth,
                    left: progressWidth,
                }}
                className={styles.clickablePointsHolder}
            >
                <div className={styles.clickablePointsHolderInner}>
                    {items.map((_: string, index: number) => {
                        const clickablePoint = classnames(styles.clickablePoint, {
                            [styles.clickablePointActive]: index <= hoveredIndex,
                        })
                        return (
                            <div
                                key={`clickablePoint-${index}`}
                                className={clickablePoint}
                                onClick={onClickablePointClick(index)}
                                onMouseEnter={onClickablePointMouseEnter(index)}
                                onMouseLeave={onClickablePointMouseLeave()}
                            />
                        )
                    })}
                </div>
            </div>
        )
    }

    const notInLabels = !labels.map((l) => l.index).includes(predictLabel)
    const gradient = `linear-gradient(to right, #2C9AFF 0%, #2C9AFF ${step * blueprintsCount - 10}px, #ffcc66 ${step * blueprintsCount}px, #ffcc66 ${
        width - (step * count) / 5
    }px, #e74b12 ${width}px)`

    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapperInner} ref={setWrapperRef}>
                {width ? (
                    <>
                        <div
                            className={styles.predict}
                            style={{
                                width: predictSizeWidth,
                                left: progressWidth,
                                opacity: isPredictVisible ? 1 : 0,
                            }}
                        ></div>
                        <div
                            className={styles.progress}
                            style={{
                                width: progressWidth,
                                background: gradient,
                            }}
                        />
                        {labels.map((label, index) => {
                            return (
                                <div
                                    key={`SliderLabel-${index}`}
                                    className={styles.sliderLabel}
                                    style={{
                                        left: label.index * step,
                                    }}
                                >
                                    {label.content}
                                </div>
                            )
                        })}

                        {renderClickablePoints()}

                        <Draggable
                            key={`Draggable-${width}=${min}-${max}-${progress}`}
                            handle={`.${styles.dragMarker}`}
                            position={{
                                x: positionX,
                                y: 0,
                            }}
                            grid={[step, 0]}
                            scale={1}
                            bounds={{
                                left: minMarkerPosition,
                                right: maxMarkerPosition,
                            }}
                            onDrag={onDragHandler}
                            onStop={onStop}
                        >
                            <div
                                className={styles.dragMarker}
                                style={{
                                    width: markerWidth,
                                }}
                            >
                                {isPredictVisible && notInLabels ? <div className={styles.label}>{predictLabel}</div> : null}
                            </div>
                        </Draggable>
                    </>
                ) : null}
            </div>
            {after ? <div className={styles.after}>{after}</div> : null}
        </div>
    )
}

export default Slider
