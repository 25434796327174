import { connect, useSelector } from 'react-redux'
import { State } from '~/Reducers'
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as React from 'react'
import {PresentationStyles} from "@wg/wows-entities/const";

interface CompensationProps extends WithTranslation {
    multiplier?: number
}
interface StateProps {
    compensations: Array<CompensationItem>
}

type Props = CompensationProps & StateProps

function CompensationEntity(props: Props) {
    const compensations = useSelector<State, Array<CompensationItem>>((state) => state.ReducerApp.compensations)
    return (
        <div>
            {compensations.map((compensationItem, index) => {
                return (
                    <WoWSEntity
                        key={`compensation_${index}`}
                        className={'we-compensation'}
                        type={compensationItem.type}
                        amount={compensationItem.amount * (props.multiplier || 1)}
                        presentation={{
                            withTooltip: true,
                            style: PresentationStyles.TTC,
                        }}
                    />
                )
            })}
        </div>
    )
}
const mapStateToProps = (state: State): StateProps => ({
    compensations: state.ReducerApp.compensations,
})

export default withTranslation()(connect(mapStateToProps)(CompensationEntity))
