import { playShipYardSoftOver } from '@wg/web2clientapi/sound'
import { Interpolate } from '@wg/wows-react-uikit'
import * as React from 'react'
import { withTranslation } from 'react-i18next'
import { i18n_Props } from '~/i18n'
import styles from './Stage.scss'

interface StageProps extends i18n_Props {
    isVisible: boolean
    shipLevel: number
    tooltipText: string
}

class Stage extends React.Component<StageProps> {
    shouldComponentUpdate(nextProps: StageProps) {
        return (
            this.props.isVisible !== nextProps.isVisible ||
            this.props.shipLevel !== nextProps.shipLevel ||
            this.props.tooltipText !== nextProps.tooltipText
        )
    }

    onMouseEnter = () => {
        playShipYardSoftOver()
    }

    render() {
        const { t } = this.props

        const isPreparation = this.props.shipLevel === 0
        return (
            <div className={styles.wrapper} onMouseEnter={this.onMouseEnter}>
                {isPreparation ? (
                    <span className={styles.title}>{t('Подготовка')}</span>
                ) : (
                    <div className={styles.title}>
                        <Interpolate str={t('Этап %(level)s')} level={this.props.shipLevel} />
                    </div>
                )}
            </div>
        )
    }
}

export default withTranslation()(Stage)
