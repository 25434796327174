import styles from '~/Components/ReplaySidebar/PlayerControl.scss'
import { Interpolate, DivTooltip, localization } from '@wg/wows-react-uikit'
import React from 'react'
import {
    repeatBuildingProcess,
    SET_TARGET_LEVEL,
    setNextStep,
    setPauseAnimation,
    setPreviousStep,
    stopRepeatBuildingProcess,
} from '~/Actions/ActionApp'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'

interface PlayerControl_Props {
    isAnimationInProgress: boolean
    isRepeating: boolean
    animationShipLevel: number
    progress: number
}

const { t } = localization

const PlayerControl = (props: PlayerControl_Props) => {
    const { isAnimationInProgress, isRepeating, animationShipLevel, progress } = props
    const dispatch = useDispatch()

    const mainController = (level: number) => {
        dispatch({
            type: SET_TARGET_LEVEL,
            payload: {
                level: progress,
            },
        })
        dispatch(repeatBuildingProcess(level))
    }

    const onBack = (): void => {
        dispatch(setPauseAnimation(false))
        const shipLevel = animationShipLevel - 1 >= 0 ? animationShipLevel - 1 : 0
        dispatch(setPreviousStep(true))
        mainController(shipLevel)
    }
    const onPlay = (): void => {
        dispatch(setPauseAnimation(false))
        if (!isAnimationInProgress || isRepeating) {
            mainController(animationShipLevel)
        }
    }
    const onNext = (): void => {
        if (animationShipLevel < progress) {
            dispatch(setPauseAnimation(false))
            dispatch(setNextStep(true))
            mainController(animationShipLevel + 1)
        }
    }
    const onPause = (): void => {
        dispatch(setPauseAnimation(true))
        const shipLevel = animationShipLevel - 1 >= 0 ? animationShipLevel - 1 : 0
        dispatch(stopRepeatBuildingProcess(undefined, shipLevel))
    }

    const backTooltip = (
        <div className={styles.tooltip}>
            <Interpolate str={t('Посмотреть предыдущий этап строительства')} />
        </div>
    )

    const nextTooltip = (
        <div className={styles.tooltip}>
            <Interpolate str={t('Посмотреть следующий этап строительства')} />
        </div>
    )

    const playTooltip = (
        <div className={styles.tooltip}>
            <Interpolate str={t('Посмотреть этап строительства')} />
        </div>
    )

    const pauseTooltip = (
        <div className={styles.tooltip}>
            <Interpolate str={t('Приостановить просмотр строительства')} />
        </div>
    )
    return (
        <div className={styles.control}>
            <DivTooltip tooltipBody={backTooltip}>
                <button
                    className={classnames(styles.back, {
                        [styles.disabled]: animationShipLevel === 0,
                    })}
                    onClick={onBack}
                />
            </DivTooltip>

            {isAnimationInProgress ? (
                <DivTooltip tooltipBody={pauseTooltip}>
                    <button className={styles.stop} onClick={onPause} />
                </DivTooltip>
            ) : (
                <DivTooltip tooltipBody={playTooltip}>
                    <button className={styles.play} onClick={onPlay} />
                </DivTooltip>
            )}
            <DivTooltip tooltipBody={nextTooltip}>
                <button
                    className={classnames(styles.next, {
                        [styles.disabled]: animationShipLevel === progress,
                    })}
                    onClick={onNext}
                />
            </DivTooltip>
            {animationShipLevel !== 0 && (
                <div className={styles.level}>
                    <Interpolate str={t('Этап %(level)s')} level={animationShipLevel} />
                </div>
            )}
        </div>
    )
}

export default PlayerControl
