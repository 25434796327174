import { BUY_BOOSTER_SOURCE } from '~/constants'
import { armoryShowSpinner, armoryHideSpinner } from '~/armory'
import { playBoosterBuyRegular, playBoosterBuyPremium } from '@wg/web2clientapi/sound'
import get from 'lodash/get'
import log from 'loglevel'
import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { showErrorMessage } from '~/Actions/ActionClient'
import api from '~/api'
import { State } from '~/Reducers'
import * as Api from '~/types'
import { setUsedStarters, switchSync, updateAccount } from './ActionApp'
import { SetBuyBoosterPendingState } from './ActionBuyType'
import { closeMultipleBuyDialog, closeStartBuyDialog } from './ActionDialogs'

export const SET_BUY_BOOSTER_PENDING_STATE = 'SET_BUY_BOOSTER_PENDING_STATE'
export const setBuyBoosterPendingState = (isPending: boolean): SetBuyBoosterPendingState => {
    return {
        type: SET_BUY_BOOSTER_PENDING_STATE,
        payload: {
            isPending,
        },
    }
}

export const waitForTransactionsComplete = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const interval = setInterval(() => {
        const state = getState()
        if (!state.ReducerApp.account.pendingTransactions) {
            armoryHideSpinner()
            clearInterval(interval)
        }
    }, 1000)
}

export const BUY_BOOSTER = 'BUY_BOOSTER'
export const buyBooster = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch(setBuyBoosterPendingState(true))
    const state = getState()
    const buyingBooster = state.ReducerApp.selectedBooster
    let source = BUY_BOOSTER_SOURCE.DOCKYARD
    if (state.ReducerApp.isInIframe) {
        armoryShowSpinner()
        source = BUY_BOOSTER_SOURCE.ARMORY
    }
    dispatch(switchSync(false))
    api.post('/accounts/buy-booster/', {
        boosterId: buyingBooster.id,
        count: 1,
        source: source,
    })
        .then((resp) => {
            dispatch(closeStartBuyDialog())
            if (resp) {
                dispatch(updateAccount(resp))

                if (buyingBooster.isPremium) {
                    playBoosterBuyPremium()
                } else {
                    playBoosterBuyRegular()
                }
                if (get(resp, 'pendingTransactions', false)) {
                    dispatch(waitForTransactionsComplete())
                }
            } else {
                armoryHideSpinner()
            }
        })
        .catch((error: Error) => {
            log.error(error)
            dispatch(showErrorMessage(error))
        })
        .finally(() => {
            dispatch(switchSync(true))
            dispatch(setBuyBoosterPendingState(false))
        })
    dispatch({
        type: BUY_BOOSTER,
    })
}

export const BUY_BOOSTERS = 'BUY_BOOSTERS'
export const buyBoosters = (isPremium: boolean): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const state = getState()
    const premiumBooster = state.ReducerApp.boosters.filter((booster) => booster.isPremium && !booster.unique)[0]
    const regularBooster = state.ReducerApp.boosters.filter((booster) => !booster.isPremium && !booster.unique)[0]
    const booster = isPremium ? premiumBooster : regularBooster
    const eventum = get(state.ReducerApp, 'account.wallet.eventum', 0) as number
    const count = isPremium ? state.ReducerApp.predictProgress : eventum
    const starters = isPremium ? state.ReducerApp.usedStarters : undefined
    const pendingTransactions: boolean = get(state.ReducerApp, 'account.pendingTransactions', false)
    const data = count ? [{ boosterId: booster.id, count: count }] : []
    if (starters?.length) {
        const startersData = state.ReducerApp.boosters.filter((booster) => booster.isPremium && booster.unique)
        let startersProgress = 0
        startersData.map((starter) => {
            if (starters.includes(starter.id)) {
                data.push({ boosterId: starter.id, count: 1 })
                startersProgress += starter.bonus.shipLevel
            }
        })
        data[0].count -= startersProgress
    }

    const filteredData = data.filter((item) => item.count)
    const url = isPremium ? '/accounts/buy-boosters/' : '/accounts/buy-booster/'

    if (count > 0 && !pendingTransactions) {
        if (isPremium) {
            dispatch(setBuyBoosterPendingState(true))
        }
        dispatch(switchSync(false))
        api.post(url, isPremium ? filteredData : filteredData[0])
            .then((resp) => {
                dispatch(updateAccount(resp))
                if (isPremium) {
                    playBoosterBuyPremium()
                } else {
                    playBoosterBuyRegular()
                }
                const usedStarters = starters?.filter((starter) => !state.ReducerApp.account.boughtBoostersIds.includes(starter))
                if (starters?.length !== usedStarters?.length) {
                    dispatch(setUsedStarters(usedStarters))
                }
            })
            .catch((error: Error) => {
                log.error(error)
                dispatch(showErrorMessage(error))
            })
            .finally(() => {
                dispatch(switchSync(true))
                dispatch(closeMultipleBuyDialog())
                dispatch(setBuyBoosterPendingState(false))
            })
        dispatch({
            type: BUY_BOOSTER,
        })
    }
}
