import { combineReducers } from 'redux'
import { ReducerApp, State as AppState } from './ReducerApp'
import { ReducerDialogs, State as DialogsState } from './ReducerDialogs'
import { ReducerShop, State as ShopState } from './ReducerShop'

const reducers = {
    ReducerApp,
    ReducerDialogs,
    ReducerShop,
}

export interface State {
    ReducerApp: AppState
    ReducerDialogs: DialogsState
    ReducerShop: ShopState
}

export default combineReducers(reducers)
