import styles from '~/Components/VideoPlayer/VideoPlayer.scss'
import React from 'react'
import { SkipMessage } from '~/Components/Messages/Messages'

interface SkipDocket_Props {
    hidePlayer: () => void
}

const SkipDocket = (props: SkipDocket_Props) => {
    const [isVisible, setVisible] = React.useState<boolean>(false)
    const visibleRef = React.useRef<boolean>(isVisible)
    const delay = 3000
    let lastTimeClick: number

    React.useEffect(() => {
        const ondown = () => {
            if (!lastTimeClick) {
                lastTimeClick = Date.now()
            }
            if (visibleRef.current && Date.now() - lastTimeClick < delay) {
                props.hidePlayer?.()
            }
            if (!visibleRef.current) {
                setVisible(true)
                visibleRef.current = true
            }
        }

        document.addEventListener('keydown', ondown)
        document.addEventListener('mousedown', ondown)
        if (visibleRef.current) {
            setTimeout(() => {
                setVisible(false)
                visibleRef.current = false
                lastTimeClick = null
            }, delay)
        }

        return () => {
            document.removeEventListener('keydown', ondown)
            document.removeEventListener('mousedown', ondown)
        }
    }, [isVisible])

    if (!isVisible) {
        return null
    }

    return (
        <div className={styles.skipContent}>
            <SkipMessage />
        </div>
    )
}

export default SkipDocket
