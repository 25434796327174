import * as React from 'react'
import styles from './LoadError.scss'
import { useTranslation } from 'react-i18next'
import { Button } from '@wg/wows-react-uikit'

export interface LoadErrorProps {
    getData: () => void
}

const LoadError = (props: LoadErrorProps) => {
    const { getData } = props
    const { t } = useTranslation()
    return (
        <div className={styles.wrapper}>
            <div className={styles.message}>{t('Произошла ошибка. Повторите попытку позже.')}</div>
            <div className={styles.repeatButton}>
                <Button isFlat={true} onClick={getData}>
                    {t('Обновить')}
                </Button>
            </div>
        </div>
    )
}

export default LoadError
