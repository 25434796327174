export const armoryOnAppInit = () => {
    window.parent.postMessage(
        {
            action: 'ON_APPLICATION_INIT',
        },
        '*',
    )
}

const armoryHideEsc = () => {
    window.parent.postMessage(
        {
            action: 'JS_HOST_QUERY',
            payload: {
                command: 'action',
                params: {
                    actionId: 'updateBrowserControlState',
                    browserHasControl: false,
                },
            },
        },
        '*',
    )
}

const armoryShowEsc = () => {
    window.parent.postMessage(
        {
            action: 'JS_HOST_QUERY',
            payload: {
                command: 'action',
                params: {
                    actionId: 'updateBrowserControlState',
                    browserHasControl: true,
                },
            },
        },
        '*',
    )
}

export const armoryShowBlur = () => {
    const bundlePurchaseWrapper = document.getElementById('bundle-purchase-wrapper')
    if (bundlePurchaseWrapper) {
        bundlePurchaseWrapper.classList.add('armory-blur')
    }
    window.parent.postMessage(
        {
            action: 'BLUR_STATE',
            payload: {
                blur: true,
            },
        },
        '*',
    )
    armoryShowEsc()
}

export const armoryHideBlur = () => {
    const bundlePurchaseWrapper = document.getElementById('bundle-purchase-wrapper')
    if (bundlePurchaseWrapper) {
        bundlePurchaseWrapper.classList.remove('armory-blur')
    }
    window.parent.postMessage(
        {
            action: 'BLUR_STATE',
            payload: {
                blur: false,
            },
        },
        '*',
    )
    armoryHideEsc()
}

export const armoryShowSpinner = () => {
    window.parent.postMessage(
        {
            action: 'LOADER_STATE',
            payload: {
                visibility: true,
            },
        },
        '*',
    )
}

export const armoryHideSpinner = () => {
    window.parent.postMessage(
        {
            action: 'LOADER_STATE',
            payload: {
                visibility: false,
            },
        },
        '*',
    )
}
