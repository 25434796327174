import { AnyAction } from 'redux'
import { DialogBody, DialogContainer, DialogHeader } from '@wg/wows-react-uikit'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { AppInit } from '~/Actions/ActionApp'
import { closeErrorDialog } from '~/Actions/ActionDialogs'
import { State } from '~/Reducers'

const STAGE = 'PT2'

interface OwnProps {}

interface DispatchProps {
    closeErrorDialog: () => void
    AppInit: () => void
}

interface StateProps {
    error: Error | null
}

export type Props = StateProps & OwnProps & DispatchProps

class ErrorDialog extends PureComponent<Props> {
    onReloadClick = () => {
        this.props.closeErrorDialog()
        this.props.AppInit()
    }

    render() {
        if (!this.props.error) {
            return null
        }
        return (
            <DialogContainer>
                <DialogHeader>{`${STAGE} ${this.props.error.name} : ${this.props.error.message}`}</DialogHeader>
                <DialogBody>{`Stack: ${this.props.error.stack}`}</DialogBody>
            </DialogContainer>
        )
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    error: state.ReducerDialogs.error,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<State, unknown, AnyAction>, ownProps: OwnProps): DispatchProps => {
    return {
        closeErrorDialog: () => {
            dispatch(closeErrorDialog())
        },
        AppInit: () => {
            dispatch(AppInit())
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialog)
