import { DWH_EXPORT_EVENTS, HEIGHT_PLAYER, isShowPlayer } from '~/constants'
import { SET_TARGET_LEVEL, setPauseAnimation } from '~/Actions/ActionApp'
import classnames from 'classnames'
import equal from 'fast-deep-equal/react'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { repeatBuildingProcess } from '~/Actions/ActionApp'
import ClientApi from '~/clientApi'
import SpringScrollbars from '~/Components/SpringScrollbars/SpringScrollbars'
import { State } from '~/Reducers'
import Api from '~/types'
import timer from '~/utils/timer'
import ReplayStep from '../ReplayStep/ReplayStep'
import styles from './ReplaySidebar.scss'
import PlayerControl from '~/Components/ReplaySidebar/PlayerControl'
import dwhExport from '~/api/dwhExport'

const SCROLL_ID = 'replay-sidebar-scroll'
const TIMER_ID = 'replay-sidebar-timer-id'

const updateScrollDelay = 500

export interface ReplaySidebarState {
    shipLevels: Array<Api.ShipLevel>
    animationShipLevel: number
    isRepeating: boolean
    shipLevel: number
    progress: number
    isRepeatSidebarVisible: boolean
    animationShipLevelIsInstant: boolean
    isBlueprintsViewerVisible: boolean
    isAnimationInProgress: boolean
    bgImage: string
}

interface OnUpdateData {
    top: number
}

const stateSelector = (state: State): ReplaySidebarState => {
    return {
        shipLevels: state.ReducerApp.shipLevels,
        animationShipLevel: state.ReducerApp.animationShipLevel,
        isRepeating: state.ReducerApp.isRepeating,
        shipLevel: state.ReducerApp.shipLevel,
        progress: state.ReducerApp.account.progress,
        isRepeatSidebarVisible: state.ReducerApp.isRepeatSidebarVisible,
        animationShipLevelIsInstant: state.ReducerApp.animationShipLevelIsInstant,
        isBlueprintsViewerVisible: state.ReducerDialogs.isBlueprintsViewerVisible,
        isAnimationInProgress: state.ReducerApp.isAnimationInProgress,
        bgImage: state.ReducerApp.plugOnWeb,
    }
}

const ReplaySidebar = () => {
    const state = useSelector<State, ReplaySidebarState>(stateSelector, equal)
    const {
        shipLevels,
        animationShipLevel,
        isRepeating,
        shipLevel,
        isRepeatSidebarVisible,
        animationShipLevelIsInstant,
        isBlueprintsViewerVisible,
        isAnimationInProgress,
        progress,
        bgImage,
    } = state
    const [localShipLevel, setLocalShipLevel] = useState<number>(0)
    const isMouseInside = useRef<boolean>(false)
    const tempShipLevel = useRef<number>(0)
    const scrollbar = useRef<SpringScrollbars>()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const backgroundStyle = {
        '--bg-image': `url(${bgImage})`,
        maxHeight: `100%`,
    }

    useEffect(() => {
        updateScrollPosition()
    }, [isRepeatSidebarVisible, animationShipLevel])

    setTimeout(() => {
        if (tempShipLevel.current === shipLevel && localShipLevel !== shipLevel) {
            setLocalShipLevel(shipLevel)
        }
    }, 300)
    tempShipLevel.current = shipLevel

    const updateScrollPosition = () => {
        if (scrollbar.current && !isMouseInside.current && !timer.isRunning(TIMER_ID)) {
            timer.start(TIMER_ID, 500, () => {
                window.requestAnimFrame(() => {
                    if (isRepeatSidebarVisible) {
                        const scrollTop = getScrollTop()
                        if (scrollbar.current) {
                            scrollbar.current.scrollTop(scrollTop)
                        }
                    }
                })
            })
        }
    }

    const getScrollTop = (): number => {
        let level = localShipLevel

        if (isRepeating || isAnimationInProgress) {
            level = animationShipLevel
        }

        if (shipLevels.length > 0 && shipLevels[level - 1]) {
            const id = shipLevels[level - 1].name
            const step = document.getElementById(id)
            const offsetTop = step ? step.offsetTop : 0

            return offsetTop
        } else {
            return 0
        }
    }

    const updateOverlayMask = (opacity?: number) => {
        const min = 0.8
        const max = 1
        const alpha = opacity === undefined ? 0 : opacity
        const relativeAlpha = alpha > min ? (opacity - min) / (max - min) : 0
        const scrollDiv = document.getElementById(SCROLL_ID)
        if (scrollDiv) {
            scrollDiv.style.webkitMaskImage = `-webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0,0,0,1)), color-stop(80%, rgba(0,0,0,1)),  color-stop(100%, rgba(0, 0, 0, ${relativeAlpha})))`
            scrollDiv.style.height = `calc(100% - 40px - ${isShowPlayer ? HEIGHT_PLAYER : 0}px)`
        }
    }

    const onUpdate = (data: OnUpdateData) => {
        updateOverlayMask(data.top)
    }

    const onStepClick = (shipLevel: Api.ShipLevel) => () => {
        if (shipLevel.level === animationShipLevel || shipLevel.level === animationShipLevel + 1) return
        if (isAnimationInProgress && !isRepeating) return
        dispatch(setPauseAnimation(false))
        dispatch({
            type: SET_TARGET_LEVEL,
            payload: {
                level: progress,
            },
        })
        dispatch(repeatBuildingProcess(shipLevel.level))
        dwhExport.send(DWH_EXPORT_EVENTS.REPEAT_ANIMATION_FROM_LEVEL, {
            level: shipLevel.level,
        })
    }

    const renderSteps = () => {
        return shipLevels.map((ship, index) => {
            const isPlaying: boolean = ship.level === animationShipLevel && (isRepeating || isAnimationInProgress) && !animationShipLevelIsInstant
            const isCurrent: boolean = localShipLevel === ship.level && !isRepeating
            const isDisabled = ship.level > localShipLevel || localShipLevel === 0
            return (
                <ReplayStep
                    id={ship.name}
                    key={`ReplayStep${index}`}
                    index={ship.level}
                    count={shipLevels.length - 1}
                    descr={ship.description}
                    isPlaying={isPlaying}
                    onClick={onStepClick(ship)}
                    isDisabled={isDisabled}
                    isCurrent={isCurrent}
                    isAnimationInProgress={isAnimationInProgress}
                    animationShipLevel={animationShipLevel}
                />
            )
        })
    }

    const onMouseEnter = () => {
        timer.clear(TIMER_ID)
        isMouseInside.current = true
        ClientApi.setScrollover(true)
    }

    const onMouseLeave = () => {
        isMouseInside.current = false
        if (!isBlueprintsViewerVisible) {
            ClientApi.setScrollover(false)
        }
        setTimeout(() => {
            updateScrollPosition()
        }, updateScrollDelay)
    }

    const wrapper = classnames(styles.wrapper, {
        [styles.visible]: isRepeatSidebarVisible,
    })

    return (
        <div className={wrapper} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={backgroundStyle}>
            <div>
                <div className={styles.header}>{t('Все этапы строительства корабля')}</div>
                {isShowPlayer && (
                    <PlayerControl
                        animationShipLevel={animationShipLevel}
                        progress={progress}
                        isAnimationInProgress={isAnimationInProgress}
                        isRepeating={isRepeating}
                    />
                )}
                <SpringScrollbars
                    className={styles.customScroll}
                    id={SCROLL_ID}
                    autoHide
                    onUpdate={onUpdate}
                    ref={(r: SpringScrollbars) => {
                        scrollbar.current = r
                        updateOverlayMask()
                    }}
                >
                    {renderSteps()}
                </SpringScrollbars>
            </div>
        </div>
    )
}

export default ReplaySidebar
