import React, { memo } from 'react'
import * as Api from '~/types'
import { IReward } from '~/Reducers/ReducerApp'
import { DivTooltip } from '@wg/wows-react-uikit'
import { UnitedRewardTooltip } from '~/Components/Tooltips/UnitedRewardTooltip/UnitedRewardTooltip'
import TooltipGameReward from '~/Components/TooltipGameReward/TooltipGameReward'
import { GameItemReward } from '~/types'
import cloneDeep from 'lodash/cloneDeep'
import { settings } from '~/Components/DownloadGamePopup/settings'

interface UnitedGameReward_Props extends Api.GameItemReward {
    hideText?: boolean
    rewards: Array<IReward>
}

export const UnitedReward = (props: UnitedGameReward_Props) => {
    const { type, amount, id, hideText, rewards } = props

    const filterGameReward = (gameReward: GameItemReward) => {
        return type == gameReward.type && id == gameReward.id
    }

    const cleanGameReward = (reward: IReward) => {
        const cleanedGameRewards: Array<GameItemReward> = []
        reward.gameRewards.forEach((gameReward: GameItemReward) => {
            if (filterGameReward(gameReward)) cleanedGameRewards.push(gameReward)
        })
        return cleanedGameRewards
    }
    const filterRewards = () => {
        const filteredRewards: Array<IReward> = []
        rewards.forEach((reward: IReward) => {
            reward.gameRewards.forEach((gameReward: GameItemReward) => {
                if (filterGameReward(gameReward)) {
                    const cleanedReward = cloneDeep(reward)
                    if (reward.gameRewards.length > 1) {
                        cleanedReward.gameRewards = cleanGameReward(reward)
                    }
                    filteredRewards.push(cleanedReward)
                }
            })
        })
        return filteredRewards
    }

    const getImage = () => {
        let imgSrc: string
        rewards.forEach((reward: IReward) => {
            reward.gameRewards.forEach((gameReward: GameItemReward) => {
                if (filterGameReward(gameReward)) {
                    imgSrc = reward.isMainReward && settings.isPublicTestMode ? reward.extraImage : reward.image
                }
            })
        })
        return imgSrc
    }

    const getTooltip = <UnitedRewardTooltip rewards={filterRewards()} />

    return (
        <DivTooltip tooltipBody={getTooltip}>
            {<TooltipGameReward hideTooltip={true} hideText={hideText} id={id} type={type} imgSrc={getImage()} amount={amount} withAmount />}
        </DivTooltip>
    )
}

export default memo(UnitedReward, () => true)
