import equal from 'fast-deep-equal/react'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import RewardsDialog from '~/Components/Dialogs/RewardsDialog/RewardsDialog'
import { useMount } from '~/hooks'
import { State } from '~/Reducers'
import { buyBoosters } from '~/Actions/ActionBuy'
import { Interpolate } from '@wg/wows-react-uikit'
import { gold } from '~/wowsEntities'

export interface Props {
    isVisible: boolean
    hide: () => void
}

export interface PurchaseState {
    isConfirmationOpen: boolean
    confirmationPrice: number
    confirmationRewards: Array<string>
}

const stateSelector = (state: State): PurchaseState => {
    return {
        isConfirmationOpen: state.ReducerDialogs.isPurchaseConfirmationVisible,
        confirmationPrice: state.ReducerDialogs.confirmationPrice,
        confirmationRewards: state.ReducerDialogs.confirmationRewards,
    }
}

const PurchaseConfirmation = (props: Props) => {
    const { isVisible, hide } = props

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { isConfirmationOpen, confirmationRewards, confirmationPrice } = useSelector<State, PurchaseState>(stateSelector, equal)

    const buy = () => {
        hide()
        dispatch(buyBoosters(true))
    }

    const onEnter = (e: KeyboardEvent) => {
        if (e.keyCode === 13 && isConfirmationOpen) {
            e.preventDefault()
            buy()
            hide()
        }
    }

    useMount(() => {
        document.addEventListener('keydown', onEnter, false)
        return () => {
            document.removeEventListener('keydown', onEnter, false)
        }
    })

    return (
        <RewardsDialog
            isVisible={isVisible}
            ids={confirmationRewards}
            onHide={hide}
            actionButtonText={t('Приобрести')}
            onActionButtonPress={buy}
            isBuyAction
            footerContent={
                <div>
                    <Interpolate str={t('Приобрести за %(price)s?')} price={gold(confirmationPrice)} />
                </div>
            }
        />
    )
}

export default memo(PurchaseConfirmation)
