import { DivTooltip, Interpolate, TooltipBody } from '@wg/wows-react-uikit'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
// @ts-ignore
import { compensation, gold as wowsEntityGold, shipNoBlur } from '~/wowsEntities'
import styles from './Messages.scss'
import { useSelector } from 'react-redux'
import { State } from '~/Reducers'
import { State as AppState } from '~/Reducers/ReducerApp'

export interface StartBoosterMessage_Props {
    levelCount: number
}
export const StartBoosterMessage = () => {
    const { t } = useTranslation()
    const appState = useSelector<State, AppState>((state) => state.ReducerApp)
    return (
        <div className={styles.startBoosterMessage}>
            <DivTooltip
                tooltipBody={
                    <TooltipBody>
                        <Interpolate
                            str={t(
                                'Вы не сможете приобрести Стартовые наборы после того, как завершите %(levelCount)s этапов строительства путём выполнения боевых задач Верфи.',
                                {
                                    count: appState.starterPacksMaxLevel,
                                },
                            )}
                            levelCount={appState.starterPacksMaxLevel}
                        />
                    </TooltipBody>
                }
            >
                <Interpolate str={t('Доступны только в начале строительства.')} />
            </DivTooltip>
        </div>
    )
}
StartBoosterMessage.displayName = 'StartBoosterMessage'

export const LastChanceStartBoosterMessage = () => {
    const { t } = useTranslation()
    return <div className={styles.startBoosterMessage}>{t('Последняя возможность приобрести')}</div>
}
LastChanceStartBoosterMessage.displayName = 'LastChanceStartBoosterMessage'

export const CompensationMessage = () => {
    const { t } = useTranslation()
    const appState = useSelector<State, AppState>((state) => state.ReducerApp)
    return (
        <Interpolate
            str={t(
                'Если после строительства %(ship)s вы продолжите завершать этапы, выполняя Боевые задачи Верфи, то за каждый этап вам будет начислена награда в %(return)s.',
            )}
            return={compensation(1, appState.compensations)}
            ship={shipNoBlur(appState.mainRewardShipId)}
        />
    )
}
CompensationMessage.displayName = 'CompensationMessage'

interface HeaderEndMessageProps {
    isQuestsComplete: boolean
    isVisible: boolean
    lastLevel: number
}

export const HeaderEndMessage = (props: HeaderEndMessageProps) => {
    const { t } = useTranslation()
    const { isQuestsComplete, isVisible, lastLevel } = props
    return (
        <TransitionGroup className={styles.groupWrapper}>
            {isVisible && (
                <CSSTransition key={'message-header-end'} classNames={'message'} timeout={200}>
                    <div className={styles.headerMessageWrapper}>
                        <div className={styles.mesageTitle}>
                            <Interpolate
                                str={t('Вы завершили %(levelCount)s этапов строительства корабля.', {
                                    count: lastLevel,
                                })}
                                levelCount={lastLevel}
                            />
                        </div>
                        {isQuestsComplete ? null : (
                            <div className={styles.mesageRow}>
                                <CompensationMessage />
                            </div>
                        )}
                    </div>
                </CSSTransition>
            )}
        </TransitionGroup>
    )
}
HeaderEndMessage.displayName = 'HeaderEndMessage'

interface HeaderEndEventMessageProps {
    eventEndDate: number
    isVisible: boolean
}

export const HeaderEndEventMessage = (props: HeaderEndEventMessageProps) => {
    const { t } = useTranslation()
    const appState = useSelector<State, AppState>((state) => state.ReducerApp)
    const { isVisible } = props

    return (
        <TransitionGroup className={styles.groupWrapper}>
            {isVisible && (
                <CSSTransition key={'message-header-end-event'} classNames={'message'} timeout={200}>
                    <div className={styles.headerMessageWrapper}>
                        <div className={styles.mesageTitle}>{t('Группы боевых задач для завершения этапов строительства больше недоступны.')}</div>
                        <div className={styles.mesageRow}>
                            <Interpolate
                                str={t('До конца обновления %(version)s вы можете завершить недостающие этапы за дублоны.')}
                                version={appState.eventFinishVersion}
                            />
                        </div>
                    </div>
                </CSSTransition>
            )}
        </TransitionGroup>
    )
}
HeaderEndEventMessage.displayName = 'HeaderEndEventMessage'

interface NotEnouthMoneyProps {
    needGold: number
}

export const NotEnouthMoney = (props: NotEnouthMoneyProps) => {
    const { t } = useTranslation()
    const { needGold } = props

    return (
        <TooltipBody>
            <Interpolate str={t('Не хватает: %(notEnough)s')} notEnough={wowsEntityGold(needGold)} />
        </TooltipBody>
    )
}
NotEnouthMoney.displayName = 'NotEnouthMoney'

export const PremiumLevelDescription = () => {
    const { t } = useTranslation()
    const appState = useSelector<State, AppState>((state) => state.ReducerApp)
    return (
        <span>
            <Interpolate
                str={t('Получить корабль можно, только завершив не менее %(premiumLevelsCount)s любых этапов за дублоны.', {
                    count: appState.levelsCompletedBySpendingDoubloons,
                })}
                premiumLevelsCount={appState.levelsCompletedBySpendingDoubloons}
            />
        </span>
    )
}
PremiumLevelDescription.displayName = 'PremiumLevelDescription'

export const AvailableOnBegining = () => {
    const { t } = useTranslation()
    const appState = useSelector<State, AppState>((state) => state.ReducerApp)
    return (
        <DivTooltip
            tooltipBody={
                <TooltipBody>
                    <Interpolate
                        str={t(
                            'Вы не сможете приобрести Стартовые наборы после того, как завершите %(levelCount)s этапов строительства путём выполнения боевых задач Верфи.',
                            {
                                count: appState.starterPacksMaxLevel,
                            },
                        )}
                        levelCount={appState.starterPacksMaxLevel}
                    />
                </TooltipBody>
            }
        >
            <Interpolate
                str={t('Стартовые наборы доступны до завершения первых %(levelCount)s этапов строительства.', {
                    count: appState.starterPacksMaxLevel,
                })}
                levelCount={appState.starterPacksMaxLevel}
            />
        </DivTooltip>
    )
}

export const SkipMessage = () => {
    const { t } = useTranslation()
    return <div className={styles.skipLabel}>{t('Нажмите любую клавишу, чтобы пропустить')}</div>
}
SkipMessage.displayName = 'SkipMessage'
