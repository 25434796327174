import { Dialog, localization } from '@wg/wows-react-uikit'
import equal from 'fast-deep-equal/react'
import React, { FC, memo, useEffect, useState } from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { closeBuyGoldDialog } from '~/Actions/ActionDialogs'
import toReact from 'svelte-adapter/react'
import SvelteFastGoldShowcase, { FastGoldShowcaseProps } from '@wg/wows-commerce/ui/FastGold'
import SveltePurchaseResult, { PurchaseResultProps } from '@wg/wows-commerce/ui/shared/components/PurchaseResult'
import { State } from '~/Reducers'
import styles from './BuyGoldDialog.scss'
import { ProductEntity } from '@wg/wows-commerce/core/entity/product.entity'
import { loadProducts } from '~/Actions/ActionShop'
import preloaded from '~/preloaded'
import { PURCHASE_STATUSES } from '@wg/wows-commerce'
import wowsCommerce from '~/wowsCommerce'
import { DWH_EVENTS } from '@wg/wows-commerce/constants/dwhEvents'
import { disableBlur, enableBlur } from '~/Actions/ActionApp'
import { openExternalUrl } from '~/routerHistory'
import { Button as DockyardButton } from '~/Components/Buttons/Buttons'

export interface BuyGoldDialogState {
    isBuyGoldDialogVisible: boolean
    isSteambotBackButtonVisible: boolean
    isWsmartHostProvided: boolean
    products?: Array<ProductEntity>
}

const stateSelector = (state: State): BuyGoldDialogState => {
    return {
        isBuyGoldDialogVisible: state.ReducerDialogs.isBuyGoldDialogVisible,
        isSteambotBackButtonVisible: state.ReducerApp.isSteambotBackButtonVisible,
        isWsmartHostProvided: !!preloaded.urls.wsmartUrl,
        products: state.ReducerShop.products,
    }
}

type PurchaseStatus = 'success' | 'error' | ''
const statusesWithPopup: Array<PurchaseStatus> = ['success', 'error']

interface PurchaseInfo {
    status: PurchaseStatus
    errCode: string
}

const basePurchaseInfo: PurchaseInfo = {
    status: '',
    errCode: '',
}

const FastGold = toReact(SvelteFastGoldShowcase, {}, 'div') as FC<FastGoldShowcaseProps>
const PurchaseResult = toReact(SveltePurchaseResult, {}, 'div') as FC<PurchaseResultProps>
const { t } = localization

const BuyGoldDialog = () => {
    const [selectedProduct, setSelectedProduct] = useState(null as ProductEntity | null)
    const [purchaseStatus, setPurchaseStatus] = useState(basePurchaseInfo)

    const dispatch = useDispatch()
    const state = useSelector<State, BuyGoldDialogState>(stateSelector, equal)
    const { isBuyGoldDialogVisible, products } = state
    const showArmoryUrl = preloaded.urls.armoryUrl.length > 0

    useEffect(() => {
        return () => {
            if (isBuyGoldDialogVisible) {
                dispatch(disableBlur())
            } else {
                dispatch(enableBlur())
                dispatch(loadProducts(preloaded.settings.wsmartStorefront))
            }
        }
    }, [isBuyGoldDialogVisible])

    const openMoreBundles = () => {
        openExternalUrl(preloaded.urls.armoryUrl)
    }

    const onSuccessPurchase = () => {
        setPurchaseStatus({ ...purchaseStatus, status: 'success' })
    }

    const onCancelPurchase = () => {
        setPurchaseStatus({ ...purchaseStatus, status: '' })
        setSelectedProduct(null)
    }

    const onErrorDueToPurchase = (code: string) => {
        setPurchaseStatus({ errCode: code, status: 'error' })
    }

    const removeBlur = () => {
        setPurchaseStatus({ ...purchaseStatus, status: '' })
        setSelectedProduct(null)
    }

    const closeResultPopup = () => {
        if (purchaseStatus.status === 'success') {
            hideDialog(true)
            setTimeout(removeBlur, 200)
        } else {
            removeBlur()
        }
    }

    const checkStatus = (result: any) => {
        const statusCode = result.purchaseStatus
        if (statusCode === PURCHASE_STATUSES.SUCCESS) {
            onSuccessPurchase()
        } else if (statusCode === PURCHASE_STATUSES.ERROR) {
            onErrorDueToPurchase(result.errorCode)
        } else if (statusCode === PURCHASE_STATUSES.CANCELLED) {
            onCancelPurchase()
        }
    }

    async function buyProduct(event: any) {
        const product = event.detail.product
        setSelectedProduct(product)
        if (state.isWsmartHostProvided) {
            wowsCommerce.analytics.addEvent(DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_CATEGORY, { bundle_id: product.externalProductId })

            try {
                const checkoutResult = await wowsCommerce.checkout(product.externalProductId, 1, preloaded.settings.wsmartStorefront)
                checkStatus(checkoutResult)
            } catch (err) {
                if (err.status === PURCHASE_STATUSES.ERROR) {
                    onErrorDueToPurchase(err.errorCode)
                }
            }
        } else {
            console.log('WSMART host is not provided: ignore buyProduct')
        }
    }

    const hideDialog = (force = false) => {
        if (!isPopupOpened || force) {
            dispatch(closeBuyGoldDialog())
        }
    }

    const isPopupOpened = selectedProduct !== null

    const dialogContent = () => {
        return (
            products !== null && (
                <>
                    <div className={styles.wrapper}>
                        <FastGold
                            products={products}
                            onBuy={buyProduct}
                            displayLimit={showArmoryUrl ? 3 : 4}
                            onOpenMoreBundles={showArmoryUrl ? openMoreBundles : null}
                            helpText={showArmoryUrl ? null : ' '} // It's important, sorry
                        />
                        <div className={styles.footer}>
                            <DockyardButton label={t('Закрыть')} onClick={() => hideDialog()} />
                            {showArmoryUrl && <DockyardButton label={t('Больше наборов')} onClick={openMoreBundles} />}
                        </div>
                    </div>
                    {isPopupOpened && statusesWithPopup.includes(purchaseStatus.status) && (
                        <PurchaseResult
                            purchaseResult={{
                                isSuccess: purchaseStatus.status === 'success',
                                productImageUrl: selectedProduct.productImageUrl,
                                title: selectedProduct.title,
                                errorCode: purchaseStatus.errCode,
                            }}
                            onClose={closeResultPopup}
                        />
                    )}
                </>
            )
        )
    }

    return (
        <Dialog
            className={styles.dialog}
            content={dialogContent()}
            size={'worship'}
            isOverlay={false}
            isVisible={isBuyGoldDialogVisible}
            hideDialog={hideDialog}
        />
    )
}

export default memo(BuyGoldDialog)
