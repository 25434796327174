import { EDUCATION_ACTION_ID } from '~/Actions/ActionApp'
import { State } from '~/Reducers'
import * as Api from '~/types'
import { IExtraShipLevel } from '~/Reducers/ReducerApp'

export const getNewExtraShipLevel = (extraShipLevels: Array<IExtraShipLevel>, level: number): IExtraShipLevel => {
    const levels = extraShipLevels.filter((extraShipLevel) => extraShipLevel.requiredShipLevel <= level)
    return levels[levels.length - 1]
}

export const getClientState = (account: Api.Account, state: State): ClientState => {
    const shipLevels = state.ReducerApp.shipLevels
    const secondaryShipLevels = state.ReducerApp.secondaryShipLevels ? state.ReducerApp.secondaryShipLevels : []
    const tertiaryShipLevels = state.ReducerApp.tertiaryShipLevels ? state.ReducerApp.tertiaryShipLevels : []

    const shipLevel = getCurrentShipLevel(account, shipLevels)
    const newSecondaryShipLevel = getNewExtraShipLevel(secondaryShipLevels, shipLevel)
    const newTertiaryShipLevels = getNewExtraShipLevel(tertiaryShipLevels, shipLevel)
    const secondaryShipLevel = newSecondaryShipLevel ? newSecondaryShipLevel.level : 0
    const tertiaryShipLevel = newTertiaryShipLevels ? newTertiaryShipLevels.level : 0

    return {
        shipLevel,
        secondaryShipLevel,
        tertiaryShipLevel,
    }
}

export const getCurrentShipLevel = (account: Api.Account, shipLevels: Array<Api.ShipLevel>): number => {
    return shipLevels.find((shipLevel) => shipLevel.name === account.shipLevelId).level || 0
}

export const getFinishedPendingBoosters = (nextAccount: Api.Account, prevAccount: Api.Account): Array<string> => {
    const prevBoosters = prevAccount?.boughtBoostersIds
    const nextBoosters = nextAccount?.boughtBoostersIds
    const justBoughtBoosters = nextBoosters.filter((id) => !prevBoosters.includes(id))
    return justBoughtBoosters
}

export const isGuidePassed = (completedActionsIds: Api.Account['completedActionsIds']): boolean => {
    return completedActionsIds?.includes(EDUCATION_ACTION_ID)
}
