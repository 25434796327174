import { Interpolate, Divider, Message, Tooltip } from '@wg/wows-react-uikit'
import { GRADES } from '~/constants'
import React, { memo } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { PremiumLevelDescription } from '~/Components/Messages/Messages'
import { IReward } from '~/Reducers/ReducerApp'
import { newShip } from '~/wowsEntities'
import styles from './RewardShipTooltip.scss'

interface IRewardShipTooltipProps {
    isPremium?: boolean
    reward?: IReward
    bgImage: string
    isCollected?: boolean
    id: string
}

const RewardShipTooltip = (props: IRewardShipTooltipProps) => {
    const { t } = useTranslation()
    const { isPremium, isCollected, id, reward, bgImage } = props

    const renderTitle = () => {
        switch (reward.grade) {
            case GRADES.BRONZE:
            case GRADES.SILVER: {
                return <Interpolate str={t('Особая награда за %(level)s этап')} level={reward.shipLevelNumber} />
            }
            case GRADES.GOLD: {
                return t('Финальная награда')
            }
            default: {
                return <Interpolate str={t('Награда за %(level)s этап')} level={reward.shipLevelNumber} />
            }
        }
    }

    const header = classnames(styles.header, {
        [styles.receivedReward]: isCollected,
    })

    const shipImageStyles = {
        backgroundImage: `url(${reward?.tooltipImage})`,
    }

    const calculateHeight = () => {
        /*note: calculateHeight depends on font-size*/
        const demoLabelHeight = 170
        const ttcHeight = 320
        const imgHeight = window.innerHeight > 950 ? 150 : 0
        let baseHeight = window.innerHeight > 950 ? imgHeight : 0
        if (reward.isDemo) {
            baseHeight += demoLabelHeight
        } else {
            baseHeight += ttcHeight
        }
        return baseHeight
    }

    const backgroundStyle = {
        minHeight: calculateHeight(),
        width: `100%`,
        height: `100%`,
        '--bg-image': `url(${bgImage})`,
    }

    return (
        <Tooltip>
            <div className={styles.bgWrapper} style={backgroundStyle}>
                <div>
                    <div className={styles.wrapper}>
                        <div className={header}>{renderTitle()}</div>
                    </div>
                    {reward?.tooltipImage && (
                        <>
                            <Divider className={styles.hidden} type={'major'} />
                            <div className={styles.hidden}>
                                <div className={styles.imageHolder}>
                                    <div style={shipImageStyles} className={styles.image} />
                                    <div className={styles.water} />
                                </div>
                            </div>
                        </>
                    )}
                    <div className={styles.shipTooltipContentWrapper}>{newShip(id)}</div>
                    {isCollected && (
                        <>
                            <Divider type={'major'} />
                            <div className={styles.row}>
                                <Message style={'inline'} type={'done'}>
                                    {t('Получено')}
                                </Message>
                            </div>
                        </>
                    )}
                    {isPremium && (
                        <>
                            <Divider type={'major'} />
                            <div className={styles.premiumRow}>
                                <PremiumLevelDescription />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Tooltip>
    )
}

export default memo(RewardShipTooltip, () => true)
