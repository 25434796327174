import { playShipYardOver, playButtonClickSound } from '@wg/web2clientapi/sound'
import React from 'react'
import styles from './Buttons.scss'
import classNames from 'classnames'

export interface Button_Props {
    label: string | React.ReactChild | any
    onClick?: (event?: React.MouseEvent) => void
    onFallback?: (event?: React.MouseEvent) => void
    disabled?: boolean
    className?: string | string[]
    isFull?: boolean
    isFree?: boolean
    isWarn?: boolean
    id?: string
    autoFocus?: boolean
    onMouseMove?: (event: React.MouseEvent) => void
    isFetching?: boolean
    linkTarget?: boolean
}

export const Button = function (props: Button_Props) {
    const buttonClassNames = classNames(
        styles.button,
        {
            [styles.locaked]: props.disabled || props.isFetching,
            [styles.isFull]: props.isFull,
        },
        props.className,
    )

    const onClick = (event: React.MouseEvent) => {
        event.preventDefault()
        playButtonClickSound()
        props.onClick && props.onClick(event)
    }

    const onMouseEnter = () => {
        playShipYardOver()
    }

    const onFallback = () => {
        props.onFallback?.()
    }

    return (
        <button
            className={buttonClassNames}
            onClick={props.disabled ? onFallback : onClick}
            onMouseEnter={onMouseEnter}
            autoFocus={props.autoFocus}
            id={props.id}
        >
            <span className={styles.label}>{props.label}</span>
            {props.linkTarget && <div className={styles.arrowTarget}></div>}
        </button>
    )
}
