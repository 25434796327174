import styles from '~/Components/ProgressBar/FinalReward/FinalReward.scss'
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity'
import { ItemTypes } from '@wg/wows-entities/const'
import React from 'react'
import classnames from 'classnames'
import { IReward } from '~/Reducers/ReducerApp'
import { getShipIdFromReward } from '~/utils/rewards'

interface FinalReward_Props {
    rewards: Array<IReward>
    level: number
    onClick: () => void
    isHideFinalReward: boolean
}

const FinalReward = (props: FinalReward_Props) => {
    const { onClick, isHideFinalReward, rewards } = props

    const finalRewardData: IReward = rewards.find((reward) => reward.isMainReward)

    if (!finalRewardData) return null

    const shipId = getShipIdFromReward(finalRewardData)

    const finalReward = classnames(styles.finalReward, {
        [styles.move]: isHideFinalReward,
        [styles.marl]: true,
    })
    const finalRewardStyles = {
        backgroundImage: `url(${finalRewardData.image})`,
    }

    return (
        <div className={finalReward} onClick={onClick} style={finalRewardStyles} id={'finalReward'}>
            <main className={styles.main}>
                {shipId && (
                    <WoWSEntity
                        type={ItemTypes.VEHICLES}
                        id={shipId}
                        presentation={{
                            withTooltip: false,
                            hideNationIcon: true,
                            hideTypeIcon: false,
                        }}
                    />
                )}
            </main>
        </div>
    )
}

export default FinalReward
