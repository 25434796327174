import moment from 'moment'
import {
    SHOW_LOAD_ERROR,
    HIDE_LOAD_ERROR,
    OPEN_WALLET_PORTAL,
    CLOSE_WALLET_PORTAL,
    OPEN_PROGRESS_BAR_PORTAL,
    CLOSE_PROGRESS_BAR_PORTAL,
    CLOSE_BOOSTER_DIALOG,
    CLOSE_BUYOUT_DIALOG,
    CLOSE_ERROR_DIALOG,
    CLOSE_FINAL_DIALOG,
    CLOSE_RULES_DIALOG,
    CLOSE_WELCOME_DIALOG,
    HIDE_OVERLAY,
    HIDE_VIDEO_PLAYER,
    OPEN_BOOSTER_DIALOG,
    OPEN_BUYOUT_DIALOG,
    OPEN_ERROR_DIALOG,
    OPEN_FINAL_DIALOG,
    OPEN_RULES_DIALOG,
    OPEN_WELCOME_DIALOG,
    SHOW_OVERLAY,
    SHOW_VIDEO_PLAYER,
    OPEN_MULTIPLE_BUY_DIALOG,
    CLOSE_MULTIPLE_BUY_DIALOG,
    OPEN_START_BUY_DIALOG,
    CLOSE_START_BUY_DIALOG,
    OPEN_BUY_GOLD_DIALOG,
    CLOSE_BUY_GOLD_DIALOG,
    SHOW_BLUEPRINTS_VIEWER,
    HIDE_BLUEPRINTS_VIEWER,
    SHOW_DOWNLOAD_GAME_POPUP,
    HIDE_DOWNLOAD_GAME_POPUP,
    OPEN_BUNDLE_BUY_DIALOG,
    CLOSE_BUNDLE_BUY_DIALOG,
    OPEN_PURCHASE_CONFIRMATION,
    CLOSE_PURCHASE_CONFIRMATION,
    SHOW_REST_REWARDS_DIALOG,
    HIDE_REST_REWARDS_DIALOG,
} from '~/Actions/ActionDialogs'
import { ActionDialogs } from '~/Actions/ActionDialogsType'

export interface State {
    isRulesDialogOpen: boolean
    isBoosterDialogOpen: boolean
    isWelcomeDialogOpen: boolean
    isErrorDialogOpen: boolean
    isVideoPlayerVisible: boolean
    isBuyoutDialogOpen: boolean
    isFinalDialogOpen: boolean
    error: Error | null
    isOverlayVisible: boolean
    isWelcomeMessageVisible: boolean
    isMultipleBuyDialogVisible: boolean
    isStartBuyDialogVisible: boolean
    isProgressBarPortalVisible: boolean
    isWalletPortalVisible: boolean
    isLoadErrorVisible: boolean
    isBuyGoldDialogVisible: boolean

    videoCloseTime: number
    finalDialogShipName: string

    isBlueprintsViewerVisible: boolean
    isDownloadGamePopupVisible: boolean
    isBundleBuyDialogVisible: boolean
    isPurchaseConfirmationVisible: boolean
    confirmationPrice: number
    confirmationRewards: Array<string>
    isRestRewardsDialogVisible: boolean
}

const initialState: State = {
    isRulesDialogOpen: false,
    isBoosterDialogOpen: false,
    isWelcomeDialogOpen: false,
    isErrorDialogOpen: false,
    isVideoPlayerVisible: false,
    isBuyoutDialogOpen: false,
    isFinalDialogOpen: false,
    error: null,
    isOverlayVisible: false,
    isWelcomeMessageVisible: false,
    isMultipleBuyDialogVisible: false,
    isStartBuyDialogVisible: false,
    isProgressBarPortalVisible: false,
    isWalletPortalVisible: false,
    isLoadErrorVisible: false,
    isBuyGoldDialogVisible: false,

    videoCloseTime: 0,
    finalDialogShipName: '',

    isBlueprintsViewerVisible: false,
    isDownloadGamePopupVisible: false,
    isBundleBuyDialogVisible: false,
    isPurchaseConfirmationVisible: false,
    confirmationPrice: 0,
    confirmationRewards: [],
    isRestRewardsDialogVisible: false,
}

export const ReducerDialogs = (state: State = initialState, action: ActionDialogs): State => {
    switch (action.type) {
        case OPEN_BUY_GOLD_DIALOG: {
            return {
                ...state,
                isBuyGoldDialogVisible: true,
            }
        }
        case CLOSE_BUY_GOLD_DIALOG: {
            return {
                ...state,
                isBuyGoldDialogVisible: false,
            }
        }
        case OPEN_WALLET_PORTAL: {
            return {
                ...state,
                isWalletPortalVisible: true,
            }
        }
        case CLOSE_WALLET_PORTAL: {
            return {
                ...state,
                isWalletPortalVisible: false,
            }
        }
        case OPEN_PROGRESS_BAR_PORTAL: {
            return {
                ...state,
                isProgressBarPortalVisible: true,
            }
        }
        case CLOSE_PROGRESS_BAR_PORTAL: {
            return {
                ...state,
                isProgressBarPortalVisible: false,
            }
        }
        case SHOW_OVERLAY: {
            return {
                ...state,
                isOverlayVisible: true,
            }
        }
        case HIDE_OVERLAY: {
            return {
                ...state,
                isOverlayVisible: false,
            }
        }
        case SHOW_VIDEO_PLAYER: {
            return {
                ...state,
                isVideoPlayerVisible: true,
            }
        }
        case HIDE_VIDEO_PLAYER: {
            return {
                ...state,
                isVideoPlayerVisible: false,
                videoCloseTime: moment.utc().unix() * 1000,
            }
        }
        case OPEN_FINAL_DIALOG: {
            return {
                ...state,
                isRulesDialogOpen: false,
                isBoosterDialogOpen: false,
                isWelcomeDialogOpen: false,
                isErrorDialogOpen: false,
                isBuyoutDialogOpen: false,
                isFinalDialogOpen: true,
                isMultipleBuyDialogVisible: false,
                isStartBuyDialogVisible: false,
                finalDialogShipName: action.payload.shipName,
            }
        }
        case CLOSE_FINAL_DIALOG: {
            return {
                ...state,
                isFinalDialogOpen: false,
            }
        }
        case OPEN_BUYOUT_DIALOG: {
            return {
                ...state,
                isRulesDialogOpen: false,
                isBoosterDialogOpen: false,
                isWelcomeDialogOpen: false,
                isErrorDialogOpen: false,
                isBuyoutDialogOpen: true,
                isFinalDialogOpen: false,
                isMultipleBuyDialogVisible: false,
                isStartBuyDialogVisible: false,
            }
        }
        case CLOSE_BUYOUT_DIALOG: {
            return {
                ...state,
                isBuyoutDialogOpen: false,
            }
        }
        case OPEN_ERROR_DIALOG: {
            return {
                ...state,
                isRulesDialogOpen: false,
                isBoosterDialogOpen: false,
                isWelcomeDialogOpen: false,
                isErrorDialogOpen: true,
                isBuyoutDialogOpen: false,
                isFinalDialogOpen: false,
                error: action.payload.error,
                isMultipleBuyDialogVisible: false,
                isStartBuyDialogVisible: false,
            }
        }
        case CLOSE_ERROR_DIALOG: {
            return {
                ...state,
                isErrorDialogOpen: false,
                error: null,
            }
        }
        case OPEN_WELCOME_DIALOG: {
            return {
                ...state,
                isRulesDialogOpen: false,
                isBoosterDialogOpen: false,
                isWelcomeDialogOpen: true,
                isErrorDialogOpen: false,
                isBuyoutDialogOpen: false,
                isFinalDialogOpen: false,
                isWelcomeMessageVisible: action.payload.isWelcomeMessageVisible,
                isMultipleBuyDialogVisible: false,
                isStartBuyDialogVisible: false,
            }
        }
        case CLOSE_WELCOME_DIALOG: {
            return {
                ...state,
                isWelcomeDialogOpen: false,
                isWelcomeMessageVisible: true,
            }
        }
        case OPEN_BOOSTER_DIALOG: {
            return {
                ...state,
                isBoosterDialogOpen: true,
                isRulesDialogOpen: false,
                isWelcomeDialogOpen: false,
                isErrorDialogOpen: false,
                isBuyoutDialogOpen: false,
                isFinalDialogOpen: false,
                isMultipleBuyDialogVisible: false,
                isStartBuyDialogVisible: false,
            }
        }
        case CLOSE_BOOSTER_DIALOG: {
            return {
                ...state,
                isBoosterDialogOpen: false,
            }
        }
        case OPEN_RULES_DIALOG: {
            return {
                ...state,
                isRulesDialogOpen: true,
                isBoosterDialogOpen: false,
                isWelcomeDialogOpen: false,
                isErrorDialogOpen: false,
                isBuyoutDialogOpen: false,
                isFinalDialogOpen: false,
                isMultipleBuyDialogVisible: false,
                isStartBuyDialogVisible: false,
            }
        }
        case CLOSE_RULES_DIALOG: {
            return {
                ...state,
                isRulesDialogOpen: false,
            }
        }
        case OPEN_MULTIPLE_BUY_DIALOG: {
            return {
                ...state,
                isRulesDialogOpen: false,
                isBoosterDialogOpen: false,
                isWelcomeDialogOpen: false,
                isErrorDialogOpen: false,
                isBuyoutDialogOpen: false,
                isFinalDialogOpen: false,
                isMultipleBuyDialogVisible: true,
                isStartBuyDialogVisible: false,
            }
        }
        case CLOSE_MULTIPLE_BUY_DIALOG: {
            return {
                ...state,
                isMultipleBuyDialogVisible: false,
            }
        }
        case OPEN_START_BUY_DIALOG: {
            return {
                ...state,
                isRulesDialogOpen: false,
                isBoosterDialogOpen: false,
                isWelcomeDialogOpen: false,
                isErrorDialogOpen: false,
                isBuyoutDialogOpen: false,
                isFinalDialogOpen: false,
                isMultipleBuyDialogVisible: false,
                isStartBuyDialogVisible: true,
            }
        }
        case CLOSE_START_BUY_DIALOG: {
            return {
                ...state,
                isStartBuyDialogVisible: false,
            }
        }
        case SHOW_LOAD_ERROR: {
            return {
                ...state,
                isLoadErrorVisible: true,
            }
        }
        case HIDE_LOAD_ERROR: {
            return {
                ...state,
                isLoadErrorVisible: false,
            }
        }
        case SHOW_BLUEPRINTS_VIEWER: {
            return {
                ...state,
                isBlueprintsViewerVisible: true,
            }
        }
        case HIDE_BLUEPRINTS_VIEWER: {
            return {
                ...state,
                isBlueprintsViewerVisible: false,
            }
        }
        case SHOW_DOWNLOAD_GAME_POPUP: {
            return {
                ...state,
                isDownloadGamePopupVisible: true,
            }
        }
        case HIDE_DOWNLOAD_GAME_POPUP: {
            return {
                ...state,
                isDownloadGamePopupVisible: false,
            }
        }
        case OPEN_BUNDLE_BUY_DIALOG: {
            return {
                ...state,
                isBundleBuyDialogVisible: true,
            }
        }
        case CLOSE_BUNDLE_BUY_DIALOG: {
            return {
                ...state,
                isBundleBuyDialogVisible: false,
            }
        }
        case OPEN_PURCHASE_CONFIRMATION: {
            return {
                ...state,
                isPurchaseConfirmationVisible: true,
                confirmationPrice: action.payload.price,
                confirmationRewards: action.payload.rewards,
            }
        }
        case CLOSE_PURCHASE_CONFIRMATION: {
            return {
                ...state,
                isPurchaseConfirmationVisible: false,
                confirmationPrice: 0,
                confirmationRewards: [],
            }
        }
        case SHOW_REST_REWARDS_DIALOG: {
            return { ...state, isRestRewardsDialogVisible: true }
        }
        case HIDE_REST_REWARDS_DIALOG: {
            return { ...state, isRestRewardsDialogVisible: false }
        }
        default:
            return state
    }
}
