import { IReward } from '~/Reducers/ReducerApp'

export const getShipIdFromReward = (reward: IReward): string | null => {
    let shipId: string | null = null
    const shipReward = reward.gameRewards.filter((gameReward) => gameReward.type === 'ship')[0]
    if (shipReward) {
        shipId = shipReward.id
    }
    return shipId
}

export const getWreathColor = (grade: RewardGrade): string => {
    switch (grade) {
        case 'bronze': {
            return '#c0c0c0'
        }
        case 'silver': {
            return '#c0c0c0'
        }
        case 'gold': {
            return '#ffcc66'
        }
        default: {
            return 'transparent'
        }
    }
}
