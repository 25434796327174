export const SLIDE_WIDTH = 174
export const MASK_TAIL_WIDTH = 122
export const SQUIRCLE_WIDTH = 70
export const MOUSE_LEAVE_DELAY = 3000
export const LOW_SCROLL_OFFSET = 0
export const HIGH_SCROLL_OFFSET = 300
export const SCROLL_PROGRESS = 0.96
export const PROGRESSBAR_LEAVE_TIMER_ID = 'progressbar-mouse-leave'
export const SVG_PROGRESS_ITEMS_MASK_ID = 'SVG_PROGRESS_ITEMS_MASK_ID'
export const SVG_PROGRESS_WRAPPER_ITEMS_MASK_ID = 'SVG_PROGRESS_WRAPPER_ITEMS_MASK_ID'

export const BLUEPRINT_BAR_COLOR = '#2C9AFF'
export const BAR_COLOR = '#ffcc66'
