import get from 'lodash/get'
import log from 'loglevel'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { updateAccount } from '~/Actions/ActionApp'
import { showErrorMessage } from '~/Actions/ActionClient'
import { State } from '~/Reducers'
import api from '~/api'
import preloaded from '~/preloaded'

class Sync {
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>
    getState: () => State
    interval: number
    checkPending: boolean

    constructor(interval: number, checkPending: boolean, dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: () => State) {
        this.dispatch = dispatch
        this.getState = getState
        this.interval = interval
        this.checkPending = checkPending

        this.init()
    }

    init = () => {
        this.getData()
    }

    repeat = () => {
        setTimeout(this.getData, this.interval)
    }

    getData = () => {
        const state = this.getState()
        const hasPendingTransactions = this.hasPendingTransactions(state)
        if (hasPendingTransactions === this.checkPending && state.ReducerApp.isSyncEnabled) {
            api.get('/accounts/account-info/')
                .then((resp) => {
                    this.dispatch(updateAccount(resp))
                    this.repeat()
                })
                .catch((error: Error) => {
                    this.dispatch(showErrorMessage(error))
                    log.error(error)
                    this.repeat()
                })
        } else {
            this.repeat()
        }
    }

    hasPendingTransactions = (state: State) => {
        return get(state, 'ReducerApp.account.pendingTransactions', false)
    }
}

export default Sync
