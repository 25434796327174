import equal from 'fast-deep-equal/react'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { issueRewards } from '~/Actions/ActionApp'
import RewardsDialog from '~/Components/Dialogs/RewardsDialog/RewardsDialog'
import { useMount } from '~/hooks'
import { State } from '~/Reducers'

export interface WelcomeDialog_Props {
    isVisible: boolean

    hide: () => void
}

export interface WelcomeDialogState {
    isWelcomeDialogOpen: boolean
    availableRewards: Array<string>
}

const stateSelector = (state: State): WelcomeDialogState => {
    return {
        isWelcomeDialogOpen: state.ReducerDialogs.isWelcomeDialogOpen,
        availableRewards: state.ReducerApp.availableRewards,
    }
}

const WelcomeDialog = (props: WelcomeDialog_Props) => {
    const { isVisible, hide } = props

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { isWelcomeDialogOpen, availableRewards } = useSelector<State, WelcomeDialogState>(stateSelector, equal)

    const collectRewards = () => {
        hide()
        dispatch(issueRewards(undefined, true))
    }

    const onEnter = (e: KeyboardEvent) => {
        if (e.keyCode === 13 && isWelcomeDialogOpen) {
            e.preventDefault()
            collectRewards()
            hide()
        }
    }

    useMount(() => {
        document.addEventListener('keydown', onEnter, false)
        return () => {
            document.removeEventListener('keydown', onEnter, false)
        }
    })

    return (
        <RewardsDialog
            isVisible={isVisible}
            ids={availableRewards}
            title={t('Заслуженная награда')}
            subtitle={t('Вам доступна награда за завершенные этапы строительства')}
            actionButtonText={t('Забрать')}
            onHide={hide}
            onActionButtonPress={collectRewards}
        />
    )
}

export default memo(WelcomeDialog)
