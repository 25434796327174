import React, { ReactElement } from 'react'
import { IconProps } from '~/types'

function GotItIcon({ color, className }: IconProps): ReactElement {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none" className={className}>
            <path
                opacity="0.2"
                d="M5.50674 9.64523L5.8603 9.99878L6.21385 9.64523L12.6477 3.2114L13.0012 2.85784L12.6477 2.50429L11.7898 1.64645L11.4363 1.29289L11.0827 1.64645L5.8603 6.86888L3.2114 4.21998L2.85784 3.86643L2.50429 4.21998L1.64645 5.07782L1.29289 5.43138L1.64645 5.78493L5.50674 9.64523Z"
                fill={color || '#FFCC66'}
                stroke="black"
            />
            <path
                d="M5.8603 9.29167L2 5.43138L2.85784 4.57353L5.8603 7.57599L11.4363 2L12.2941 2.85784L5.8603 9.29167Z"
                fill={color || '#FFCC66'}
                stroke={color || '#FFCC66'}
            />
        </svg>
    )
}

export default GotItIcon
