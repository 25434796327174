import { Actions } from '~/Actions'
import { TURN_GUIDE_OFF, TURN_GUIDE_ON } from '~/Actions/ActionApp'
import { HIDE_VIDEO_PLAYER, SHOW_VIDEO_PLAYER } from '~/Actions/ActionDialogs'
import ClientApi from '~/clientApi'
import { State } from '~/Reducers'
import { setWorldRendererState } from '~/Actions/ActionClient'

type Next = (action: Actions) => void

export default (store: any) => (next: Next) => (action: Actions) => {
    next(action)

    // @ts-ignore
    if (action?.payload?.skipMiddleware) return

    const state = store.getState() as State
    const dispatch = store.dispatch

    if (action.type.includes('DIALOG')) {
        if (window.tooltipProvider) {
            window.tooltipProvider.hide()
        }
        if (action.type.includes('OPEN')) {
            ClientApi.setDialogState(true)
            ClientApi.setScrollover(true)
        }
        if (action.type.includes('CLOSE')) {
            ClientApi.setDialogState(false)
            if (!state.ReducerDialogs.isBlueprintsViewerVisible) {
                ClientApi.setScrollover(false)
            }
        }
    }
    if (action.type === SHOW_VIDEO_PLAYER) {
        ClientApi.setDialogState(true)
        ClientApi.switchSoundState(false)
        ClientApi.setScrollover(true)
    }
    if (action.type === HIDE_VIDEO_PLAYER) {
        ClientApi.setDialogState(false)
        ClientApi.switchSoundState(true)
        if (!state.ReducerDialogs.isBlueprintsViewerVisible) {
            dispatch(setWorldRendererState(true))
            ClientApi.setScrollover(false)
        }
    }
    if (action.type === TURN_GUIDE_ON) {
        ClientApi.setDialogState(true)
        ClientApi.setScrollover(true)
    }
    if (action.type === TURN_GUIDE_OFF) {
        ClientApi.setDialogState(false)
        if (!state.ReducerDialogs.isBlueprintsViewerVisible) {
            ClientApi.setScrollover(false)
        }
    }
}
