import { ProgressBarController } from '~/Components/ProgressBar/ProgressBarController'
import { BlueprintViewerRef } from '~/Components/BlueprintViewer/BlueprintViewer'

export enum RefManagerKeys {
    ProgressBar = 'ProgressBar',
    SteambotFrame = 'SteambotFrame',
    BlueprintViewer = 'BlueprintViewer',
}

export type Classes = ProgressBarController | HTMLIFrameElement | BlueprintViewerRef

interface Refs {
    ProgressBar?: ProgressBarController
    SteambotFrame?: HTMLIFrameElement
    BlueprintViewer?: BlueprintViewerRef
}

class RefManager {
    private refs: Refs = {}

    setRef = (key: RefManagerKeys, ref: Classes) => {
        switch (key) {
            case RefManagerKeys.SteambotFrame: {
                this.refs[RefManagerKeys.SteambotFrame] = ref as HTMLIFrameElement
                break
            }
            case RefManagerKeys.ProgressBar: {
                this.refs[RefManagerKeys.ProgressBar] = ref as ProgressBarController
                break
            }
            case RefManagerKeys.BlueprintViewer: {
                this.refs[RefManagerKeys.BlueprintViewer] = ref as BlueprintViewerRef
                break
            }
        }
    }

    getRef = (key: RefManagerKeys) => {
        switch (key) {
            case RefManagerKeys.SteambotFrame: {
                return this.refs[RefManagerKeys.SteambotFrame] as HTMLIFrameElement
            }
            case RefManagerKeys.ProgressBar: {
                return this.refs[RefManagerKeys.ProgressBar] as ProgressBarController
            }
            case RefManagerKeys.BlueprintViewer: {
                return this.refs[RefManagerKeys.BlueprintViewer] as BlueprintViewerRef
            }
        }
    }

    deleteRef = (key: RefManagerKeys) => {
        switch (key) {
            case RefManagerKeys.SteambotFrame: {
                delete this.refs[RefManagerKeys.SteambotFrame]
                break
            }
            case RefManagerKeys.ProgressBar: {
                delete this.refs[RefManagerKeys.ProgressBar]
                break
            }
            case RefManagerKeys.BlueprintViewer: {
                delete this.refs[RefManagerKeys.BlueprintViewer]
                break
            }
        }
    }
}

export default new RefManager()
