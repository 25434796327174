import React, { Component } from 'react'
import styles from './Overlay.scss'

export interface Overlay_Props {
    isVisible: boolean
    delay?: number
    background?: string
    hide: () => void
}
export interface Overlay_State {}

export default class Overlay extends Component<Overlay_Props, Overlay_State> {
    componentDidUpdate(prevProps: Overlay_Props, prevState: Overlay_State) {
        if (!prevProps.isVisible && this.props.isVisible) {
            setTimeout(
                () => {
                    this.props.hide()
                },
                this.props.delay ? this.props.delay : 500,
            )
        }
    }

    render() {
        const overlayStyles = {
            background: '',
        }
        return <div className={`${styles.wrapper} ${this.props.isVisible ? styles.visible : ''}`} style={overlayStyles} />
    }
}
