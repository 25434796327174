import log from 'loglevel'
import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { setAnimationState, setRepeatingState, switchSync } from '~/Actions/ActionApp'
import ClientApi from '~/clientApi'
import { State } from '~/Reducers'
import Api from '~/types'
import { getClientState, getNewExtraShipLevel } from '~/utils/account'
import animationInterrupter from '~/utils/animationInterrupter'
import animationIterator from '~/utils/animationIterator'
import { dropProgressSpeed, updateProgressSpeed } from '~/utils/updateProgressSpeed'
import { SetSetShipLevelPendingState, updateAnimationLevelInterface } from './ActionClientType'
import { showBlueprintsViewer } from '~/Actions/ActionDialogs'
import { timing } from '~/utils/updateProgressSpeed'

export const BROWSER_IS_READY = 'BROWSER_IS_READY'
export const browserIsReady = (vol?: number): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const volume = vol !== undefined && vol > 0 ? vol : 0

    dispatch({
        type: BROWSER_IS_READY,
        payload: {
            volume: volume,
        },
    })
}

export const SET_CLIENT_STATE_LISTENER = 'SET_CLIENT_STATE_LISTENER'
export const setClientStateListener = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    window.onClientStateChange = (data: string) => {
        const state = getState()
        const event: ClientStateChangeEvent = JSON.parse(data)
        switch (event.actionId) {
            case 'startAnimation': {
                if (ClientApi.getWaitingList().includes(event.level)) {
                    // console.log('client started animation ', event.level, ClientApi.getWaitingList())
                    dispatch(switchSync(false))
                    timing.setStartTime(Date.now(), event.level)
                    updateProgressSpeed(event.level, state.ReducerApp.timings)
                    dispatch(setAnimationState(true))
                    dispatch(updateAnimationSubtitle(event.level, false))
                } else {
                    console.log('why client start - ', event.level, ClientApi.getWaitingList())
                }
                break
            }

            case 'finishAnimation': {
                if (ClientApi.getWaitingList().includes(event.level)) {
                    // console.log('client finished animation ', event.level, ClientApi.getWaitingList())
                    timing.setEndTime(Date.now(), event.level)
                    animationInterrupter(getState, event.level).then(() => {
                        animationIterator.animationFinished(event.level)
                        dropProgressSpeed(true)
                        if (state.ReducerApp.shipLevel === event.level) {
                            dispatch(setAnimationState(false))
                            dispatch(setRepeatingState(false))
                            dispatch(switchSync(true))
                        }
                    })
                } else {
                    console.log('why client end - ', event.level, ClientApi.getWaitingList())
                }
                break
            }
            case 'browserShown': {
                dispatch(browserIsReady(event.browserVolume))
                break
            }
            default: {
                return
            }
        }
    }
}

export const UPDATE_ANIMATION_SUBTITLE = 'UPDATE_ANIMATION_SUBTITLE'
export const updateAnimationSubtitle = (level: number, isInstant: boolean, clb?: () => void): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const { shipLevels, blueprintsCount } = getState().ReducerApp
    const { isBlueprintsViewerVisible } = getState().ReducerDialogs
    let animationStateSubtitle = ``

    shipLevels.forEach((shipLevel) => {
        if (shipLevel.level === level) {
            animationStateSubtitle = shipLevel.description
        }
    })

    dispatch({
        type: UPDATE_ANIMATION_SUBTITLE,
        payload: {
            animationStateSubtitle,
            level,
            isInstant,
        },
    })
    if (blueprintsCount && level <= blueprintsCount && !isBlueprintsViewerVisible) {
        dispatch(showBlueprintsViewer())
    }
    if (clb) {
        clb()
    }
}

export const UPDATE_CLIENT_STATE = 'UPDATE_CLIENT_STATE'
export const updateClientState = (account: Api.Account, isInstant = false): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const state = getState()
    const clientState = getClientState(account, state)
    const revision = account.revision

    dispatch(updateDockData(clientState, revision, isInstant))

    dispatch({
        type: UPDATE_CLIENT_STATE,
        payload: {
            ...clientState,
        },
    })
}

export const SET_SET_SHIP_LEVEL_PENDING_STATE = 'SET_SET_SHIP_LEVEL_PENDING_STATE'
export const setSetShipLevelPendingState = (isPending: boolean): SetSetShipLevelPendingState => {
    return {
        type: SET_SET_SHIP_LEVEL_PENDING_STATE,
        payload: {
            isPending,
        },
    }
}

export const UPDATE_ANIMATION_LEVEL = 'UPDATE_ANIMATION_LEVEL'
export const updateAnimationLevel = (level: number): updateAnimationLevelInterface => {
    return {
        type: UPDATE_ANIMATION_LEVEL,
        payload: {
            level,
        },
    }
}

export const SET_SHIP_LEVEL = 'SET_SHIP_LEVEL'
export const setShipLevelLocal = (level: number, isInstant = false, clb?: () => void): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const state = getState()
    const secondaryShipLevels = state.ReducerApp.secondaryShipLevels
    const tertiaryShipLevels = state.ReducerApp.tertiaryShipLevels
    const revision = state.ReducerApp.account.revision ?? 0

    const clientState = getClientState(state.ReducerApp.account, state)
    clientState.shipLevel = level
    const newSecondaryShipLevel = getNewExtraShipLevel(secondaryShipLevels, level)
    const newTertiaryShipLevel = getNewExtraShipLevel(tertiaryShipLevels, level)

    clientState.secondaryShipLevel = newSecondaryShipLevel.level ?? 0
    clientState.tertiaryShipLevel = newTertiaryShipLevel.level ?? 0

    dispatch(updateDockData(clientState, revision, isInstant, clb))

    dispatch({
        type: SET_SHIP_LEVEL,
        payload: {
            level,
        },
    })
}

export const SET_WORLD_RENDERER_STATE = 'SET_WORLD_RENDERER_STATE'
export const setWorldRendererState = (enabled: boolean): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    ClientApi.switchWorldRendererState(enabled)
    dispatch({
        type: SET_WORLD_RENDERER_STATE,
        payload: {
            isWorldShow: enabled,
        },
    })
}

export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE'
export const showErrorMessage = (error: Error): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    log.error(error)
}

export const UPDATE_DOCK_DATA = 'UPDATE_DOCK_DATA'
export const updateDockData = (
    clientState: ClientState,
    revision: number,
    isInstant: boolean,
    clb?: () => void,
): ThunkAction<void, unknown, unknown, AnyAction> => (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: () => State) => {
    const state = getState()
    const actionLevels = state.ReducerApp.actionLevels
    animationIterator.update(clientState, revision, isInstant, actionLevels, dispatch, getState).then(() => {
        if (clb) {
            clb()
        }
    })
}
