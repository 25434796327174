import classNames from 'classnames'
import * as React from 'react'

import styles from './Count.scss'

export interface Props {
    value: number
    min?: number
    max?: number
    onChange: (value: number) => void
    className?: string
}

export default class Count extends React.PureComponent<Props> {
    increase = () => {
        let val = this.props.value + 1
        if (this.props.max && val > this.props.max) {
            val = this.props.max
        }
        this.props.onChange(val)
    }

    decrease = () => {
        let val = this.props.value - 1
        if (this.props.min) {
            if (val < this.props.min) {
                val = this.props.min
            }
        } else {
            if (val < 0) {
                val = 0
            }
        }

        this.props.onChange(val)
    }

    render() {
        const increaseDisable = this.props.max && this.props.max === this.props.value
        const buttonPlusClassNames = classNames(styles.button, styles.plus, {
            [styles.lock]: increaseDisable,
        })
        const decreaseDisable = this.props.min && this.props.min === this.props.value
        const buttonMinusClassNames = classNames(styles.button, styles.minus, {
            [styles.lock]: decreaseDisable,
        })
        return (
            <div className={classNames(styles.wrapper, this.props.className)}>
                <div className={buttonMinusClassNames} onClick={this.decrease} />
                <div className={styles.value}>{this.props.value}</div>
                <div className={buttonPlusClassNames} onClick={this.increase} />
            </div>
        )
    }
}
