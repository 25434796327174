import React, { memo } from 'react'
import styles from './RewardHighlight.scss'
import classnames from 'classnames'

const MASK_ID = 'REWARD_HIGHLIGHT_MASK_ID'

export interface RewardHighlight_Props {
    isVisible: boolean
    color: 'yellow' | 'blue'
}

const RewardHighlight = (props: RewardHighlight_Props) => {
    const { isVisible, color } = props

    const scale = classnames(styles.scale, {
        [styles.scaleActive]: isVisible,
    })

    const highlight = classnames(styles.highlight, {
        [styles[`highlight-${color}`]]: true,
    })

    return (
        <>
            <svg height="0" width="0" viewBox={`0 0 98 98`}>
                <defs>
                    <clipPath id={MASK_ID}>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M49 3L49.9654 15.0673L50 15.2071L55.6363 17.5417C55.538 17.4893 58.1692 12.4952 59.5 10L57.294 18.2284L63.6314 20.8534C63.6544 20.6514 68.9726 12.9755 71.6288 9.16284L65.3028 21.5457C65.4606 21.5457 70.384 23.6232 72.826 24.662L75.6414 22.3586L73.5195 24.9492L73.8952 25.1048L76.7137 31.9092L88.521 26.3017L77.5288 33.8772L80.1159 40.1959L85.3928 39.2485L80.4924 41.0976L83.4288 48.1302L95 49L83.8372 49.893L80.8396 57.1298L85.3928 58.7513L80.5338 57.8682L77.845 64.4244L88.8372 72L77.0318 66.3878L73.9799 73.6908L75.6414 75.6412L73.691 73.9798L66.3771 77.0093L72 88.8372L64.41 77.8241L57.8581 80.5379L58.7514 85.3926L57.1321 80.8387L49.893 83.8372L49 95L48.0727 83.4088L40.9962 80.4776L39.2486 85.3926L40.2127 80.1531L33.8121 77.5019L26 88.8372L31.7874 76.6632L25.1048 73.8952L24.9232 73.4567L22.3587 75.6412L24.693 72.901L21.8262 65.9799L9.16284 72L20.9538 63.8739L18.3912 57.6871L12.6073 58.7513L18.0329 56.8221L15.1971 49.9757L3 49L15.6255 47.99L18.394 41.3061L12.6073 39.2485L18.7761 40.3836L21.2761 34.3482L9.16284 26L22.1723 32.1846L24.9718 25.4261L22.3587 22.3586L25.4263 24.9717L32.1788 22.1384L26 9.16284L34.3109 21.2438L40.374 18.7231L39.2486 12.6072L41.2886 18.3442L48 15.5L49 3ZM73.1298 73.1298L49.5266 82.9066C49.5011 82.9036 49.4755 82.9004 49.45 82.8969L25.8702 73.1298L25.7233 72.7751L25.7572 72.7462C25.7251 72.7081 25.6938 72.6694 25.6632 72.6301L16.0824 49.5L25.8702 25.8702L49.5 16.0824L73.1298 25.8702L82.9176 49.5L73.1298 73.1298Z"
                            fill="#FFCC66"
                        />
                    </clipPath>
                </defs>
            </svg>
            <div
                className={styles.wrapper}
                style={{
                    clipPath: `url(#${MASK_ID})`,
                    WebkitClipPath: `url(#${MASK_ID})`,
                }}
            >
                <div className={scale}>
                    <div className={highlight} />
                </div>
            </div>
        </>
    )
}

export default memo(RewardHighlight)
