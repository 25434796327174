import { Divider, Interpolate, Tooltip } from '@wg/wows-react-uikit'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import TooltipGameReward from '~/Components/TooltipGameReward/TooltipGameReward'
import { GRADES } from '~/constants'
import { IReward } from '~/Reducers/ReducerApp'
import * as Api from '~/types'
import styles from './UnitedRewardTooltip.scss'
import { settings } from '~/Components/DownloadGamePopup/settings'

interface UnitedRewardTooltipProps {
    rewards: Array<IReward>
}

export const UnitedRewardTooltip = (props: UnitedRewardTooltipProps) => {
    const { t } = useTranslation()
    const { rewards } = props

    const renderReward = (reward: IReward) =>
        reward.gameRewards.map((item: Api.GameItemReward, index) => {
            return (
                <div className={styles.rewardItem} key={index}>
                    <TooltipGameReward
                        imgSrc={reward.isMainReward && settings.isPublicTestMode ? reward.extraImage : reward.image}
                        {...item}
                        hideTooltip
                        withAmount
                    />
                </div>
            )
        })

    const renderTitle = (reward: IReward) => {
        switch (reward.grade) {
            case GRADES.BRONZE:
            case GRADES.SILVER: {
                return <Interpolate str={t('Особая награда за %(level)s этап')} level={reward.shipLevelNumber} />
            }
            case GRADES.GOLD: {
                return t('Финальная награда')
            }
            default: {
                return <Interpolate str={t('Награда за %(level)s этап')} level={reward.shipLevelNumber} />
            }
        }
    }

    const renderOne = (reward: IReward) => {
        return (
            <div>
                <div className={styles.header}>{renderTitle(reward)}</div>
                <Divider type={'major'} />
                <div className={styles.rewardRow}>
                    <div className={styles.rewardHolder}>{renderReward(reward)}</div>
                </div>
                <Divider type={'major'} />
            </div>
        )
    }

    const renderAll = () => {
        const icons: Array<React.ReactNode> = []
        rewards.map((reward: IReward, index) => {
            icons.push(
                <div className={styles.wrapper}>
                    <div key={index}>{renderOne(reward)}</div>
                </div>,
            )
        })
        return icons
    }

    return <Tooltip>{renderAll()}</Tooltip>
}
