import React, { ReactElement } from 'react'
import { IconProps } from '~/types'

function WarnIcon({ color, className }: IconProps): ReactElement {
    return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                opacity="0.2"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.49719 15.8729L1.89999 14.811L8.9556 3.52148H10.15L17.2056 14.811L16.6084 15.8729H2.49719ZM2.49719 15.1771H16.6084L9.5528 3.88767L2.49719 15.1771Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.49722 15.1772H16.6084L9.55283 3.3501L2.49722 15.1772ZM4.34352 14.1336H14.7621L9.55283 5.40141L4.34352 14.1336Z"
                fill="#FF9933"
            />
            <path d="M8.96469 7.65088H10.1406V11.9516H8.96469V7.65088Z" fill={color || '#FF9933'} />
            <path d="M8.96469 12.4893H10.1406V13.5644H8.96469V12.4893Z" fill={color || '#FF9933'} />
        </svg>
    )
}

export default WarnIcon
