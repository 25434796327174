import { getStartBoostersIds } from '~/utils/boosters'
import equal from 'fast-deep-equal/react'
import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedBooster as setSelectedBoosterAction } from '~/Actions/ActionApp'
import { buyBooster as buyBoosterAction } from '~/Actions/ActionBuy'
import { State } from '~/Reducers'
import { IBooster } from '~/Reducers/ReducerApp'
import styles from './StartBoosters.scss'
import BoosterCard from '~/Components/BoosterCard/BoosterCard'

type BoosterCardProps = {
    onClick?: (count: number, id: string, priceDiff: number) => void
    progress?: number
    levelsCount?: number
    selectedStarters: Array<string>
}

export interface StartBoostersState {
    boughtBoostersIds: Array<string>
    boosters: Array<IBooster>
    singleBoosterPrice: number
}

const stateSelector = (state: State): StartBoostersState => {
    const startBoostersIds = getStartBoostersIds(state.ReducerApp.boosters)
    const boosters = state.ReducerApp.boosters.filter((booster) => startBoostersIds.includes(booster.id)).reverse()
    const otherBoosters = state.ReducerApp.boosters.filter((booster) => !booster.unique && booster.isPremium)
    const singleBoosterPrice = otherBoosters[0] ? otherBoosters[0].price : 0
    const boughtBoostersIds = state.ReducerApp.account.boughtBoostersIds

    return {
        boughtBoostersIds: boughtBoostersIds,
        boosters: boosters,
        singleBoosterPrice,
    }
}

const StartBoosters: FC<BoosterCardProps> = ({ onClick, progress, levelsCount, selectedStarters }: BoosterCardProps) => {
    const { boughtBoostersIds, boosters, singleBoosterPrice } = useSelector<State, StartBoostersState>(stateSelector, equal)

    const dispatch = useDispatch()

    const buyBooster = () => {
        dispatch(buyBoosterAction())
    }
    const setSelectedBooster = (booster: IBooster) => {
        dispatch(setSelectedBoosterAction(booster))
    }

    const onBuy = (booster: IBooster) => () => {
        setSelectedBooster(booster)
        buyBooster()
    }

    const renderBoosters = () => {
        if (boosters.length === 0) {
            return null
        }

        const boostersArray: Array<React.ReactNode> = []
        boosters.forEach((booster: IBooster, index: number) => {
            const isBought = boughtBoostersIds.includes(booster.id)
            if (!isBought) {
                boostersArray.push(
                    <BoosterCard
                        id={booster.id}
                        title={booster.title}
                        description={booster.description}
                        key={`StartBundle-${index}`}
                        index={index}
                        count={booster.bonus.shipLevel}
                        price={booster.price}
                        onBuy={onBuy(booster)}
                        isBought={isBought}
                        allBoostersCount={boosters.length}
                        singleBoosterPrice={singleBoosterPrice}
                        onClick={onClick}
                        isBlocked={progress + booster.bonus.shipLevel > levelsCount}
                        selected={selectedStarters?.includes(booster.id)}
                    />,
                )
            }
        })
        return boostersArray
    }
    const boostersList = useMemo(() => renderBoosters(), [progress])

    return <div className={styles.bundles}>{boostersList}</div>
}

export default StartBoosters
