export default class Api {
    private baseUrl: string

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl
    }

    public async get(path: string) {
        const response = await fetch(this.getFullUrl(path))
        return this.getJson(response)
    }

    public async post(path: string, data?: Record<any, any>) {
        const body = data ? JSON.stringify(data) : ''
        const response = await fetch(this.getFullUrl(path), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: body,
        })
        return this.getJson(response)
    }

    public async delete(path: string) {
        const response = await fetch(this.getFullUrl(path), {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return this.getJson(response)
    }

    private getFullUrl(path: string) {
        return this.baseUrl + path
    }

    private getJson(response: Response) {
        if (response.headers.get('content-type') === 'application/json') {
            return response.json()
        }
        return ''
    }
}
