import React, { ReactElement } from 'react'
import { IconProps } from '~/types'

function BuyIcon({ className }: IconProps): ReactElement {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g opacity="0.2">
                <g opacity="0.5">
                    <path
                        d="M17.9 8.79961C17.875 8.57461 17.85 8.34961 17.8 8.09961C19.7 9.29961 21 11.4996 21 13.9996C21 17.8996 17.9 20.9996 14 20.9996C12.0712 20.9996 10.3209 20.2257 9.07071 18.9995H10.6752C11.6248 19.6232 12.7654 19.9996 14 19.9996C17.3 19.9996 20 17.2996 20 13.9996C20 12.1996 19.2 10.5996 18 9.49961C17.95 9.24961 17.925 9.02461 17.9 8.79961Z"
                        stroke="black"
                    />
                    <path
                        d="M12.283 18.9995C12.8209 19.193 13.3998 19.2996 14 19.2996C16.9 19.2996 19.2 16.8996 19.2 14.0996C19.2 12.6996 18.8 11.4996 18 10.5996C17.7635 13.6745 15.7864 16.3143 12.9999 17.4406V18.9995H12.283Z"
                        stroke="black"
                    />
                </g>
                <g opacity="0.75">
                    <path
                        d="M16 10C16 6.7 13.3 4 10 4C6.70003 4 4.00003 6.7 4.00003 10C4.00003 11.2194 4.36868 12.3568 5.00003 13.3064V14.9247C3.7597 13.6652 3.00003 11.9306 3.00003 10C3.00003 6.1 6.10003 3 10 3C13.9 3 17 6.1 17 10C17 12.8228 15.376 15.2265 13 16.339V15.1908C14.79 14.1494 16 12.2092 16 10Z"
                        stroke="black"
                    />
                    <path
                        d="M12.2654 15C14.1603 14.1244 15.5 12.1962 15.5 10C15.5 7 13 4.5 10 4.5C7.00003 4.5 4.50003 7 4.50003 10C4.50003 10.8038 4.67952 11.5718 5.00003 12.2654V11H9.00003V15H12.2654Z"
                        stroke="black"
                    />
                </g>
                <path d="M6 12H8V22H6V12Z" stroke="black" />
                <path d="M12 16V18L2 18L2 16L12 16Z" stroke="black" />
            </g>
            <g opacity="0.5">
                <path
                    d="M17.9 8.79961C17.875 8.57461 17.85 8.34961 17.8 8.09961C19.7 9.29961 21 11.4996 21 13.9996C21 17.8996 17.9 20.9996 14 20.9996C12.0712 20.9996 10.3209 20.2257 9.07071 18.9995H10.6752C11.6248 19.6232 12.7654 19.9996 14 19.9996C17.3 19.9996 20 17.2996 20 13.9996C20 12.1996 19.2 10.5996 18 9.49961C17.95 9.24961 17.925 9.02461 17.9 8.79961Z"
                    fill="#FFCC66"
                />
                <path
                    d="M12.283 18.9995C12.8209 19.193 13.3998 19.2996 14 19.2996C16.9 19.2996 19.2 16.8996 19.2 14.0996C19.2 12.6996 18.8 11.4996 18 10.5996C17.7635 13.6745 15.7864 16.3143 12.9999 17.4406V18.9995H12.283Z"
                    fill="#FFCC66"
                />
            </g>
            <g opacity="0.75">
                <path
                    d="M16 10C16 6.7 13.3 4 10 4C6.70003 4 4.00003 6.7 4.00003 10C4.00003 11.2194 4.36868 12.3568 5.00003 13.3064V14.9247C3.7597 13.6652 3.00003 11.9306 3.00003 10C3.00003 6.1 6.10003 3 10 3C13.9 3 17 6.1 17 10C17 12.8228 15.376 15.2265 13 16.339V15.1908C14.79 14.1494 16 12.2092 16 10Z"
                    fill="#FFCC66"
                />
                <path
                    d="M12.2654 15C14.1603 14.1244 15.5 12.1962 15.5 10C15.5 7 13 4.5 10 4.5C7.00003 4.5 4.50003 7 4.50003 10C4.50003 10.8038 4.67952 11.5718 5.00003 12.2654V11H9.00003V15H12.2654Z"
                    fill="#FFCC66"
                />
            </g>
            <path d="M6 12H8V22H6V12Z" fill="#FFCC66" />
            <path d="M12 16V18L2 18L2 16L12 16Z" fill="#FFCC66" />
        </svg>
    )
}

export default BuyIcon
