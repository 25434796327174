import web2clientapi from '@wg/web2clientapi'
import { sendAction } from '@wg/web2clientapi/core/action'
import { shipyardMoveCamera } from '@wg/web2clientapi/shipyard/shipyardMoveCamera'
import { shipyardHideScreenshot } from '@wg/web2clientapi/shipyard/shipyardHideScreenshot'
import { openSseShipyard } from '@wg/web2clientapi/shipyard/openSseShipyard'
import { isIngame } from '@wg/web2clientapi/utils/checkIngame'
import { openExternalUrl } from '@wg/web2clientapi/browser/openExternalUrl'
import { navigateTo } from '@wg/web2clientapi/browser/navigateTo'
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState'
import { updateBrowserOverScrollableState } from '@wg/web2clientapi/browser/updateBrowserOverScrollableState'
import { shipyardSoundOn, shipyardSoundOff } from '@wg/web2clientapi/sound'
import dwhExport from '~/api/dwhExport'
import { DWH_EXPORT_EVENTS } from '~/constants'

const clb = (success?: PromiseLike<undefined>): void => {
    return null
}

class ClientApi {
    private waitingList: number[] = []

    isInGame = isIngame

    openExternalUrl = openExternalUrl

    hideScreenshot = shipyardHideScreenshot

    setCameraOnShip = shipyardMoveCamera

    setScrollover = updateBrowserOverScrollableState

    setDialogState = updateBrowserControlState

    openSseShipyard = openSseShipyard

    addLevelToWaitingList = (level: number) => {
        if (!this.waitingList.includes(level)) {
            this.waitingList.push(level)
        }
    }

    removeLevelFromWaitingList = (level: number) => {
        if (this.waitingList.includes(level)) {
            this.waitingList = this.waitingList.filter((l) => l !== level)
        }
    }

    getWaitingList = () => {
        return this.waitingList
    }

    clearWaitingList = () => {
        this.waitingList = []
    }

    switchSoundState = (soundOn: boolean) => {
        if (soundOn) {
            shipyardSoundOn()
        } else {
            shipyardSoundOff()
        }
    }

    switchWorldRendererState = (enabled: boolean) => {
        sendAction({
            actionId: 'switchWorldRendererState',
            enabled,
        })
    }

    navigateToSse = (tagId: string) => {
        dwhExport.send(DWH_EXPORT_EVENTS.GO_TO_SSE, {}, true).then(() => {
            // @ts-ignore
            this.openSseShipyard(tagId)
        })
    }

    updateDockData = (from: string, state: ClientState, revision: number, isInstant = false, onSuccess = clb, onFailure = clb) => {
        this.addLevelToWaitingList(state.shipLevel)
        // console.log('send to client new ship level', state.shipLevel, revision)
        const result = sendAction({
            actionId: 'updateDockData',
            info: {
                shipLevel: state.shipLevel,
                extraShipLevel: state.secondaryShipLevel,
                thirdShipLevel: state.tertiaryShipLevel,
                isInstant: isInstant,
                revision: revision,
                force: true,
            },
        })

        if (result) {
            onSuccess()
        } else {
            onFailure()
        }
    }
}

const clientApi = new ClientApi()

// @ts-ignore
window.__web2client = web2clientapi
// @ts-ignore
window.__clientApi = clientApi

export default clientApi
