import { State } from '~/Reducers'
import blueprintsState from '~/utils/blueprintsState'

const dialogKeys = [
    'isRulesDialogOpen',
    'isBoosterDialogOpen',
    'isWelcomeDialogOpen',
    'isErrorDialogOpen',
    'isVideoPlayerVisible',
    'isBuyoutDialogOpen',
    'isFinalDialogOpen',
    'isMultipleBuyDialogVisible',
    'isStartBuyDialogVisible',
]

const hasOpenedDialogs = (getState: () => State): boolean => {
    const state = getState()
    const openedDialogs: Array<string> = []
    for (const key in state.ReducerDialogs) {
        // @ts-ignore
        if (dialogKeys.includes(key) && state.ReducerDialogs[key] === true) {
            openedDialogs.push(key)
        }
    }
    return openedDialogs.length > 0
}

const animationInterrupter = (getState: () => State, level: number): Promise<true> => {
    return new Promise((resolve) => {
        const listener = setInterval(() => {
            const state = getState()
            const blueplrintLevelAnimationComplete = blueprintsState.isLevelComplete(level)
            const levelHigherThenBlueprints = level > state.ReducerApp.blueprintsCount

            if (!hasOpenedDialogs(getState) && (blueplrintLevelAnimationComplete || levelHigherThenBlueprints)) {
                clearInterval(listener)
                resolve(true)
            }
        }, 1000 / 30)
    })
}

export default animationInterrupter
