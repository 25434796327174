import { DivTooltip, Interpolate } from '@wg/wows-react-uikit'
import React, { memo } from 'react'
import Countdown from 'react-countdown-now'
import { useTranslation } from 'react-i18next'
import styles from './CountDown.scss'
import preloaded from '~/preloaded'

interface ICountDown_Props {
    date: number
    isLarge?: boolean
    disabledTooltip?: boolean
    disableClock?: boolean
    tooltipText?: string | React.ReactElement
}

interface RedrererData {
    days: number
    hours: number
    minutes: number
    seconds: number
}

const CountDown = (props: ICountDown_Props) => {
    const { isLarge, tooltipText, disableClock, disabledTooltip } = props
    const { t } = useTranslation()
    const date = new Date(props.date)
    const local = preloaded.settings.languageCode
    const dateFinishString = new Date(date).toLocaleString(local, { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })
    const leadingZero = (num: number) => {
        return num < 10 ? '0' : ''
    }

    const renderer = ({ days, hours, minutes, seconds }: RedrererData) => {
        const dd = `${days}`
        const hh = `${leadingZero(hours)}${hours}`
        const mm = `${leadingZero(minutes)}${minutes}`
        const ss = `${leadingZero(seconds)}${seconds}`

        let counter: React.ReactElement

        if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
            return <span />
        }

        if (days >= 7) {
            counter = <Interpolate str={t('%(days)s дней', { count: days })} days={dd} />
        } else if (days < 7 && days >= 1) {
            counter = (
                <div className={styles.second}>
                    <Interpolate str={t('%(days)s дней', { count: days })} days={dd} />
                    {hours ? (
                        <div className={styles.hours}>
                            <Interpolate str={t('%(hours)s часов', { count: hours })} hours={hours} />
                        </div>
                    ) : null}
                </div>
            )
        } else if (days < 1) {
            counter = <span>{`${hh}:${mm}:${ss}`}</span>
        }

        return (
            <div className={styles.counter}>
                <div>
                    {!disableClock && <div className={styles.clock} />}
                    {isLarge && <div className={styles.description}>{t('До окончания верфи:')}</div>}
                    {counter}
                </div>
                {isLarge && (
                    <div>
                        <div className={styles.clock} />
                        {isLarge && (
                            <div className={styles.description}>
                                <Interpolate str={t('Боевые задачи верфи доступны до: %(value)s')} value={dateFinishString} />
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className={isLarge ? styles.largeWrapper : styles.wrapper}>
            <DivTooltip tooltipBody={<div className={styles.tooltip}>{tooltipText}</div>} isDisabled={isLarge || disabledTooltip}>
                <Countdown date={date} renderer={renderer} />
            </DivTooltip>
        </div>
    )
}

export default memo(CountDown)
