class BlueprintsState {
    readyBlueprints: number[] = []
    playingBlueprints: number[] = []

    setPlayingLevel = (level: number) => {
        if (!this.playingBlueprints.includes(level)) {
            this.playingBlueprints.push(level)
        }
    }

    unsetPlayingLevel = (level: number) => {
        this.playingBlueprints = this.playingBlueprints.filter((l) => l !== level)
    }

    completeAnimation = (level: number) => {
        if (!this.readyBlueprints.includes(level)) {
            this.readyBlueprints.push(level)
        }
        for (let index = 1; index < level; index++) {
            this.completeAnimation(index)
        }
    }

    clear = () => {
        this.readyBlueprints = []
    }

    isLevelComplete = (level: number): boolean => {
        return this.readyBlueprints.includes(level)
    }
}

const blueprintsState = new BlueprintsState()

export default blueprintsState
