import { DivTooltip } from '@wg/wows-react-uikit'
import classnames from 'classnames'
import equal from 'fast-deep-equal/react'
import React, { memo } from 'react'
import RewardTooltip from '~/Components/Tooltips/RewardTooltip/RewardTooltip'
import { IReward } from '~/Reducers/ReducerApp'
import styles from './Reward.scss'

interface IReward_Props {
    reward: IReward
    isBig?: boolean
    isSmall?: boolean
    shipLevelDescription?: string
    fixedTooltip?: boolean
    isPremium?: boolean
    isCollected?: boolean
    noCount?: boolean
    useIcon?: boolean
    hidePremDays?: boolean
}

const Reward = (props: IReward_Props) => {
    const { reward, isBig, isSmall, useIcon } = props

    const wrapper = classnames(styles.wrapper, {
        [styles.wrapperSmall]: !!isSmall,
        [styles.wrapperBig]: !!isBig,
    })

    let backgroundImage = reward.image
    if (useIcon) {
        backgroundImage = reward.icon
    }

    return (
        <div className={wrapper} style={{ backgroundImage: `url("${backgroundImage}")` }} />
    )
}

export default memo(Reward, equal)
