import equal from 'fast-deep-equal/react'
import { DivTooltip } from '@wg/wows-react-uikit'
import classnames from 'classnames'
import React, { memo, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { openRulesDialog as openRulesDialogAction } from '~/Actions/ActionDialogs'
import { State } from '~/Reducers'
import { appIsReady } from '~/selectors'
import styles from './TopCounter.scss'
import { RulesButtonTooltip } from '~/Components/Tooltips/Tooltips'
import { playButtonDialogClickSound } from '@wg/web2clientapi/sound'

export interface TopCounterState {
    appIsReady: boolean
}

const stateSelector = (state: State) => {
    return {
        appIsReady: appIsReady(state),
    }
}

const TopCounter = () => {
    const { t } = useTranslation()
    const state = useSelector<State, TopCounterState>(stateSelector, equal)
    const dispatch = useDispatch()
    const { appIsReady } = state

    const wrapperClassname = classnames(styles.wrapper, {
        [styles.wrapperVisible]: appIsReady,
    })

    const openRulesDialog = useCallback(() => {
        playButtonDialogClickSound()
        dispatch(openRulesDialogAction())
    }, [])

    const button = useMemo(() => {
        return (
            <DivTooltip tooltipBody={<RulesButtonTooltip />}>
                <div className={styles.rulesButton} onClick={openRulesDialog}>
                    {t('О событии')}
                </div>
            </DivTooltip>
        )
    }, [])

    return (
        <div className={wrapperClassname}>
            <div className={styles.rulesButtonWrapper}>{button}</div>
        </div>
    )
}

export default memo(TopCounter)
