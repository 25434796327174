import classnames from 'classnames'
import equal from 'fast-deep-equal/react'
import React, { memo } from 'react'
import styles from './Rays.scss'

export interface Rays_Props {
    className?: string
    color?: 'yellow' | 'blue'
    size?: 'regular' | 'big'
}

const Rays = (props: Rays_Props) => {
    const { className, color, size } = props

    const isBlue = color === 'blue'
    const isBig = size === 'big'
    const wrapper = classnames(styles.wrapper, {
        [className]: !!className,
    })
    const rays = classnames(styles.rays, {
        [styles.raysBlue]: isBlue,
        [styles.raysBig]: isBig,
        [styles.raysBigBlue]: isBig && isBlue,
    })
    const raysSecond = classnames(styles.raysSecond, {
        [styles.raysSecondBlue]: isBlue,
        [styles.raysSecondBig]: isBig,
        [styles.raysSecondBigBlue]: isBig && isBlue,
    })
    const raysThird = classnames(styles.raysThird, {
        [styles.raysThirdBlue]: isBlue,
        [styles.raysThirdBig]: isBig,
        [styles.raysThirdBigBlue]: isBig && isBlue,
    })

    return (
        <div className={wrapper}>
            <div className={styles.inner}>
                <div className={rays} />
                <div className={raysSecond} />
                <div className={raysThird} />
            </div>
        </div>
    )
}

export default memo(Rays, equal)
