import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity'
import { ItemTypes, PresentationStyles } from '@wg/wows-entities/const'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Api from '~/types'
import styles from './TooltipGameReward.scss'

interface TooltipGameReward_Props extends Api.GameItemReward {
    hideText?: boolean
    imgSrc?: string
    hideTooltip?: boolean
    withAmount?: boolean
}

const TooltipGameReward = (props: TooltipGameReward_Props) => {
    const { type, amount, customisation, id, hideText, imgSrc, hideTooltip, withAmount } = props
    return (
        <WoWSEntity
            type={type}
            id={id}
            amount={withAmount && amount}
            customisation={customisation}
            presentation={{
                withTooltip: !hideTooltip,
                withText: !hideText,
                style: PresentationStyles.WIDGET,
                imgSrc: imgSrc || null,
            }}
        />
    )
}

export default memo(TooltipGameReward, () => true)
