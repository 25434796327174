import { wowsEntities } from '@wg/wows-entities'
import { EntityTypes } from '@wg/wows-entities/const'
import { IReward } from '~/Reducers/ReducerApp'

export async function loadRewards(rewards: Array<IReward>): Promise<Array<IReward>> {
    const requests = rewards.map(async (reward) => {
        const shipGameRewards = reward.gameRewards.filter((gameReward) => gameReward.type === 'ship')

        for (const gameReward of reward.gameRewards) {
            const isShipReward = gameReward.type === 'ship'
            let query
            if (isShipReward) {
                query = wowsEntities.getStorage().loadEntity(EntityTypes.VEHICLES, shipGameRewards[0].id)
            } else if (gameReward.id) {
                query = wowsEntities.getStorage().loadEntity(gameReward.type as EntityTypes, gameReward.id)
            } else {
                query = wowsEntities.getStorage().getCurrency(gameReward.type as EntityTypes)
            }

            try {
                const response = await query
                if (isShipReward) {
                    reward.descriptionLength = response?.description.length
                    reward.tooltipImage = response?.icons?.large || response?.icons?.small || response?.icons?.default || ''
                    reward.isDemo = response?.tags?.includes('demoWithoutStatsPrem')
                }
                if (!reward.tooltipImage) {
                    reward.tooltipImage = response?.icons?.large || response?.icons?.default || ''
                }
            } catch (e) {
                console.error(e)
            }
        }

        return reward
    })

    return await Promise.all(requests)
}
