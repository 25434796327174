import { PRODUCT_LOAD_ENDS } from '~/Actions/ActionShop'
import { ActionShop } from '~/Actions/ActionShopTypes'
import { ProductEntity } from '@wg/wows-commerce/core/entity/product.entity'

export interface State {
    products?: Array<ProductEntity>
}

const initialState: State = {
    products: null,
}

export const ReducerShop = (state: State = initialState, action: ActionShop): State => {
    switch (action.type) {
        case PRODUCT_LOAD_ENDS: {
            return {
                ...state,
                products: action.payload,
            }
        }
        default:
            return state
    }
}
