import { isTooLateToBuyStartBooster } from '~/utils/boosters'
import { State } from '~/Reducers'
import get from 'lodash/get'

export const appIsReady = (state: State) => {
    return state.ReducerApp.appIsReady && state.ReducerApp.browserIsReady
}

export const isQuestsComplete = (state: State): boolean => {
    const regularBooster = state.ReducerApp.boosters.filter((booster) => !booster.isPremium && !booster.unique)[0]
    return !!(
        state.ReducerApp.account &&
        state.ReducerApp.account.boughtBoostersIds.filter((id) => id === regularBooster.id).length >= state.ReducerApp.freeLevels
    )
}

export const isBlured = (state: State) => {
    return (
        state.ReducerDialogs.isBoosterDialogOpen ||
        state.ReducerDialogs.isBuyoutDialogOpen ||
        state.ReducerDialogs.isErrorDialogOpen ||
        state.ReducerDialogs.isFinalDialogOpen ||
        state.ReducerDialogs.isRulesDialogOpen
    )
}

export const allRewardsHasGameRewardSelector = (state: State): boolean => {
    let allRewardsHasGameReward = true
    state.ReducerApp.rewards.forEach((reward) => {
        if (state.ReducerApp.availableRewards.includes(reward.id) && allRewardsHasGameReward && reward.gameRewards.length === 0) {
            allRewardsHasGameReward = false
        }
    })
    return allRewardsHasGameReward
}

export const isCollectRewardButtonVisible = (state: State): boolean => {
    const eventum = get(state.ReducerApp, 'account.wallet.eventum', 0) as number
    const allRewardsHasGameReward = allRewardsHasGameRewardSelector(state)
    const hasAvailableRewards = state.ReducerApp.availableRewards.length > 0
    const isCompensationLabelVisible = state.ReducerApp.isEndReached && eventum > 0
    let isCollectRewardButtonVisible = (hasAvailableRewards && !state.ReducerApp.isPendingIssueRewards) || isCompensationLabelVisible

    if (state.ReducerApp.availableRewards.length === 1 && !allRewardsHasGameReward) {
        isCollectRewardButtonVisible = false
    }
    return isCollectRewardButtonVisible
}

export const isStartBoosterVisible = (state: State): boolean => {
    const isStartBoosterBought = state.ReducerApp.isStartBoostersBought
    const isProgressProgressSmall = !isTooLateToBuyStartBooster(state)
    return !isStartBoosterBought && isProgressProgressSmall
}

export const isBluredSelector = (state: State) => {
    return (
        state.ReducerDialogs.isBoosterDialogOpen ||
        state.ReducerDialogs.isBuyoutDialogOpen ||
        state.ReducerDialogs.isErrorDialogOpen ||
        state.ReducerDialogs.isFinalDialogOpen ||
        state.ReducerDialogs.isRulesDialogOpen ||
        state.ReducerApp.isBlurred
    )
}

export const premiumBoosterProgress = (state: State): number => {
    let premiumBoosterProgress = 0
    const boughtBoosterIds = state.ReducerApp.account.boughtBoostersIds
    state.ReducerApp.boosters.forEach((booster) => {
        if (booster.isPremium && boughtBoosterIds.includes(booster.id)) {
            premiumBoosterProgress += booster.bonus.shipLevel
        }
    })
    return premiumBoosterProgress
}
