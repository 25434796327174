import { playShipYardSoftOver, playButtonClickSound } from '@wg/web2clientapi/sound'
import React, { useCallback } from 'react'
import styles from './Link.scss'
import classnames from 'classnames'

export interface Link_Props {
    text: string
    icon?: 'play' | 'stop' | 'menu' | 'close'
    isActive?: boolean
    isDisabled?: boolean
    onClick?: () => void
}

const Link = (props: Link_Props) => {
    const { text, icon, isActive, isDisabled, onClick } = props

    const onMouseEnter = useCallback(() => {
        playShipYardSoftOver()
    }, [])

    const wrapper = classnames(styles.wrapper, {
        [styles.active]: !!isActive,
        [styles.disabled]: !!isDisabled,
    })

    function clickHandler() {
        playButtonClickSound()
        onClick()
    }

    return (
        <div className={wrapper} onClick={clickHandler} onMouseEnter={onMouseEnter}>
            {icon ? <div className={styles[`icon-${icon}`]} /> : null}
            <span className={styles.text}>{text}</span>
        </div>
    )
}

export default Link
