import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import { openBuyGoldDialog } from '~/Actions/ActionDialogs'
import styles from './BuyGoldButton.scss'
import { useTranslation } from 'react-i18next'
import preloaded from '~/preloaded'
import { openExternalUrl } from '~/routerHistory'
import { playButtonGoldClickSound } from '@wg/web2clientapi/sound'

const BuyGoldButton = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const onClick = () => {
        playButtonGoldClickSound()
        const forceUrl = preloaded.settings.forceShopUrl
        if (forceUrl) {
            openExternalUrl(forceUrl)
        } else {
            dispatch(openBuyGoldDialog())
        }
    }

    if (!preloaded.settings.isBuyGoldButtonVisible) {
        return null
    }

    return (
        <div className={styles.wrapper} onClick={onClick}>
            {t('Купить дублоны')}
        </div>
    )
}

export default memo(BuyGoldButton)
