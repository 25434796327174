import { IReward } from '~/Reducers/ReducerApp'
import Api from '~/types'

const mapRewards = (rawRewards: Array<Api.Reward>, shipLevels: Array<Api.ShipLevel>): Array<IReward> => {
    const rewards: Array<IReward> = []
    const shipLevelsMap: { [key: string]: Api.ShipLevel } = {}
    shipLevels.map((shipLevel) => {
        shipLevelsMap[`${shipLevel.level}`] = shipLevel
    })
    rawRewards.forEach((rawReward: Api.Reward) => {
        const shipLevel = shipLevelsMap[rawReward.shipLevel]
        rewards.push({
            id: rawReward.name,
            value: shipLevel.level,
            type: 'reward',
            image: rawReward.progressbarAndPopupImgUrl,
            icon: rawReward.iconImgUrl,
            flagImage: rawReward.flagImgUrl,
            tooltipImage: rawReward.tooltipImgUrl,
            extraImage: rawReward.extraImgUrl,
            title: rawReward.title,
            description: rawReward.description,
            shipLevelId: shipLevel.name,
            shipLevelNumber: shipLevel.level,
            shipLevelDescription: shipLevel.description,
            gameRewards: rawReward.items,
            grade: rawReward.grade as RewardGrade,
            isMainReward: rawReward.isMainReward,
        })
    })

    return rewards
}

export default mapRewards
