import * as React from 'react'
import styles from './DownloadGamePopup.scss'
import classNames from 'classnames'
import { settings } from '~/Components/DownloadGamePopup/settings'
import { FadeInDiv } from '~/Components/Transitions'
import { useTranslation } from 'react-i18next'
import { Button } from '@wg/wows-react-uikit'

const DownloadGamePopup = () => {
    const ref: React.RefObject<HTMLDivElement> = React.useRef(null)
    const refDescription: React.RefObject<HTMLDivElement> = React.useRef(null)
    const click = () => {
        window.open(settings.downloadClient[settings.realm], '_blank')
    }
    const { t } = useTranslation()

    return (
        <div className={styles.wrapper}>
            <FadeInDiv className={styles.popup} ref={ref}>
                <div className={classNames(styles.popupImage, styles.activateAccount)} />
                <div className={styles.about}>
                    <div className={styles.title}>{t('Требуется активация аккаунта')}</div>
                    <div className={styles.description} ref={refDescription}>
                        {t('Для завершения этапов строительства и получения наград в Верфи необходимо хотя бы один раз зайти в игру.')}
                        <div className={styles.descriptionBlock}>
                            {t('Для этого:')}
                            <span>{t('— Скачайте и установите игру')}</span>
                            <span>{t('— Войдите в игру, используя свой аккаунт')}</span>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <Button type={'orange'} isFlat={true} isLarger={true} onClick={click}>
                            {t('Скачать игру')}
                        </Button>
                    </div>
                </div>
            </FadeInDiv>
        </div>
    )
}

export default DownloadGamePopup
