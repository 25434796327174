import { DWH_EXPORT_EVENTS } from '~/constants'
import moment from 'moment'
import classnames from 'classnames'
import { Interpolate, Popover, Button } from '@wg/wows-react-uikit'
import equal from 'fast-deep-equal/react'
import get from 'lodash/get'
import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { completeAction } from '~/Actions/ActionApp'
import { openMultipleBuyDialog } from '~/Actions/ActionDialogs'
import { ACTION_IDS } from '~/constants'
import { State } from '~/Reducers'
import * as Api from '~/types'
import { getStartBoostersIds, isTooLateToBuyStartBooster } from '~/utils/boosters'
import { ship } from '~/wowsEntities'
import styles from './StartBooster.scss'
import preloaded from '~/preloaded'
import { guideStartBanner } from '~/configs/guideStartBanner'
import { playButtonClickSound } from '@wg/web2clientapi/sound'
import dwhExport from '~/api/dwhExport'
import Text from '~/Components/Text/Text'

interface StartBoosterState {
    isLastChanceScreenVisible: boolean
    popupWasShown: boolean
    actionId: string
    appIsReady: boolean
    isPending: boolean
    isTransactionPending: boolean
    isVisible: boolean
    isAnyStartBoosterBought: boolean
    shipId: string
    startersAvailable: boolean
}

const stateSelector = (state: State): StartBoosterState => {
    const startterKitAction = state.ReducerApp.actions.filter((a) => a.name === ACTION_IDS.STARTERKIT_TOOLTIP)[0]
    const startterKitActionId = startterKitAction ? startterKitAction.name : undefined
    const completedActions: Array<Api.Action> = state.ReducerApp.account.completedActions || []
    const popupWasShown = completedActions.map((a) => a.name).includes(ACTION_IDS.STARTERKIT_TOOLTIP)
    const pendingTransactions: boolean = get(state.ReducerApp, 'account.pendingTransactions', false)
    const startBoostersIds = getStartBoostersIds(state.ReducerApp.boosters)
    const isAnyStartBoosterBought = state.ReducerApp.account.boughtBoostersIds.some((boughtBoosterId) => startBoostersIds.includes(boughtBoosterId))
    const isLastChanceScreenVisible =
        !isTooLateToBuyStartBooster(state) && state.ReducerApp.account.boughtBoostersIds.some((id) => !startBoostersIds.includes(id))
    const isTransactionPending = pendingTransactions
    const now = moment.utc().unix() * 1000
    const isEventFinished = now >= state.ReducerApp.closeTime || state.ReducerApp.account.progress === state.ReducerApp.lastLevel
    const isStartBoosterVisible = !isEventFinished
    const starters = state.ReducerApp.boosters.filter(
        (booster) => booster.bonus.shipLevel > 1 && !state.ReducerApp.account.boughtBoostersIds.includes(booster.id),
    )

    return {
        isLastChanceScreenVisible: isLastChanceScreenVisible,
        popupWasShown: popupWasShown,
        actionId: startterKitActionId,
        appIsReady: state.ReducerApp.appIsReady && state.ReducerApp.browserIsReady,
        isPending: state.ReducerApp.isPendingBuyBooster,
        isTransactionPending: isTransactionPending,
        isVisible: isStartBoosterVisible,
        isAnyStartBoosterBought: isAnyStartBoosterBought,
        shipId: state.ReducerApp.mainRewardShipId,
        startersAvailable: starters.length > 0 && state.ReducerApp.startersTimeLimit > Date.now(),
    }
}

const StartBooster = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const state = useSelector<State, StartBoosterState>(stateSelector, equal)
    const [isClosed, setIsClosed] = useState(false)
    const [isPopoverVisible, setIsPopoverVisible] = useState(false)
    const [isStartBoosterVisible, setIsStartBoosterVisible] = useState(false)

    const {
        isLastChanceScreenVisible,
        popupWasShown,
        actionId,
        appIsReady,
        isPending,
        isTransactionPending,
        isVisible,
        isAnyStartBoosterBought,
        shipId,
        startersAvailable,
    } = state

    useEffect(() => {
        if (appIsReady && isVisible) {
            setTimeout(() => {
                setIsStartBoosterVisible(true)
            }, 500)
        }
        if (isStartBoosterVisible && !isClosed && !popupWasShown) {
            setTimeout(() => {
                setIsPopoverVisible(true)
            }, 500)
        }
        if (!isVisible) {
            setIsStartBoosterVisible(false)
            setIsPopoverVisible(false)
        }
        if (isClosed) {
            setIsPopoverVisible(false)
        }
    }, [appIsReady, isVisible, isStartBoosterVisible, isClosed, popupWasShown, startersAvailable])

    const renderPopover = () => {
        if (isLastChanceScreenVisible) {
            return (
                <div className={styles.popupInnerWrapper}>
                    {t(
                        'Вы уже начали выполнять Боевые задачи Верфи и завершать этапы строительства. Скоро стартовые наборы станут недоступны для приобретения.',
                    )}
                </div>
            )
        } else {
            return (
                <div className={styles.popupInnerWrapper}>
                    <Interpolate
                        str={t(
                            'Приобретайте Стартовые наборы со скидкой, чтобы ускорить строительство %(ship)s и получить награды за этапы строительства.',
                        )}
                        ship={ship(shipId)}
                    />
                </div>
            )
        }
    }

    const closePopover = () => {
        setIsClosed(true)
        dispatch(completeAction(actionId))
    }

    const onBuy = useCallback(() => {
        if (!isPending && !isTransactionPending) {
            playButtonClickSound()
            dispatch(openMultipleBuyDialog())
            dwhExport.send(DWH_EXPORT_EVENTS.START_BOOSTER_CLICK)
        }
    }, [isPending, isTransactionPending])

    const wrapper = classnames(styles.wrapper, {
        [styles.wrapper]: true,
        [styles.wrapperBought]: isAnyStartBoosterBought,
    })

    const title = t('Этапы строительства')
    const buttonWrapper = classnames(styles.button, {
        [styles.disableFocus]: isTransactionPending || isPending,
    })

    return (
        <TransitionGroup className={styles.groupWrapper}>
            {isStartBoosterVisible ? (
                <CSSTransition key={'start-booster'} classNames={'start-booster-popup'} timeout={2500}>
                    <div>
                        <Popover
                            isVisible={isPopoverVisible}
                            onClose={closePopover}
                            position={'right'}
                            content={guideStartBanner.isShowGuideOnBanner && renderPopover()}
                        >
                            <div className={wrapper} onClick={onBuy}>
                                <div className={styles.cardInfo}>
                                    <div className={styles.title}>{title}</div>
                                    <div className={styles.inline}>
                                        <div className={buttonWrapper}>
                                            <Button
                                                isFlat={true}
                                                isLarger={true}
                                                type={'orange'}
                                                onClick={onBuy}
                                                isDisabled={isPending || isTransactionPending}
                                            >
                                                {t('Купить этапы')}
                                            </Button>
                                        </div>
                                    </div>
                                    {startersAvailable && (
                                        <div className="subTitleWithClock">
                                            <Text str={'До окончания скидки: %(startersTimeLimit)s'} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Popover>
                    </div>
                </CSSTransition>
            ) : null}
        </TransitionGroup>
    )
}

export default StartBooster
