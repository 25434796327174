import * as React from 'react'
import { thousands } from '~/utils/formatting'
import styles from './RewardCount.scss'

export interface RewardCountProps {
    amount: number
}

export default class RewardCount extends React.Component<RewardCountProps> {
    render() {
        const { amount } = this.props
        const amountFormatted = thousands(amount)

        if (amount === 1) {
            return null
        }

        return <div className={styles.gameRewardCount}>{amountFormatted}</div>
    }
}
