export interface QueryObj {
    [key: string]: string | boolean | number
    devMode?: boolean
    superTest?: boolean
    i18nDebug?: boolean
}

export const searchToObject = (): QueryObj => {
    const pairs = window.location.search.substring(1).split('&')
    const obj: QueryObj = {}

    for (const i in pairs) {
        if (pairs[i] === '') {
            continue
        }

        const pair = pairs[i].split('=')
        obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]) === 'undefined' ? true : decodeURIComponent(pair[1])
    }

    return obj
}

export const isOdd = (num: number) => {
    return num % 2
}

export const isInIframe = () => {
    return window.location !== window.parent.location
}

export const waitForValue = (link: any, value: any, timeout = 1000) => {
    return new Promise<void>((resolve, reject) => {
        let time = 0
        const listener = setInterval(() => {
            if (link === value) {
                clearInterval(listener)
                resolve()
            } else {
                if (time >= timeout) {
                    clearInterval(listener)
                    reject()
                }
            }
            time += 100
        }, 100)
    })
}
