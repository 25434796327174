import { DivTooltip, Message, TooltipBody } from '@wg/wows-react-uikit'
import { playShipYardOver } from '@wg/web2clientapi/sound/'
import classnames from 'classnames'
import equal from 'fast-deep-equal/react'
import get from 'lodash/get'
import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { State } from '~/Reducers'
import { appIsReady } from '~/selectors'
import * as Api from '~/types'
import { goldNoBlur, gold } from '~/wowsEntities'
import styles from './Wallet.scss'
import { IS_DEV_MODE } from '~/constants'

interface WalletState {
    walletData: Api.Wallet
    isInGame: boolean
    appIsReady: boolean
    isVideoPlayerVisible: boolean
    isFinalDialogOpen: boolean
}

const stateSelector = (state: State): WalletState => {
    const walletData = get(state.ReducerApp, 'account.wallet', {}) as Api.Wallet

    return {
        walletData: walletData,
        isInGame: state.ReducerApp.isInGame,
        appIsReady: appIsReady(state),
        isVideoPlayerVisible: state.ReducerDialogs.isVideoPlayerVisible,
        isFinalDialogOpen: state.ReducerDialogs.isFinalDialogOpen,
    }
}

type Props = {
    withBlur?: boolean
    className?: string
}

const Wallet: FC<Props> = ({ withBlur, className }) => {
    const { t } = useTranslation()
    const state = useSelector<State, WalletState>(stateSelector, equal)

    const { walletData, isInGame, appIsReady, isVideoPlayerVisible, isFinalDialogOpen } = state

    const onMouseEnter = () => {
        playShipYardOver()
    }

    const balance = walletData ? walletData : {}
    const hasError = walletData === null || Object.keys(balance).length === 0

    const wrapper = classnames(styles.wrapper, className, {
        [styles.wrapperInGame]: isInGame,
        [styles.wrapperVisible]: appIsReady && !isVideoPlayerVisible && !isFinalDialogOpen,
    })

    return (
        <div className={wrapper} id={'wallet'}>
            <div className={`${styles.inner} ${hasError ? styles.error : ''}`}>
                <div className={styles.inline} onMouseEnter={onMouseEnter}>
                    {IS_DEV_MODE ? <div className={styles.eventumBalance}>{`${balance.eventum ? balance.eventum : 0}`}</div> : null}
                    {withBlur ? gold(balance.gold) : goldNoBlur(balance.gold)}
                </div>
            </div>
            {hasError ? (
                <DivTooltip
                    className={styles.errorIcon}
                    tooltipBody={
                        <TooltipBody>
                            <div className={styles.messageWrapper}>
                                <Message style={'inline'} type={'error'}>
                                    {t('Ошибка')}
                                </Message>
                            </div>
                        </TooltipBody>
                    }
                />
            ) : null}
        </div>
    )
}

export default memo(Wallet)
