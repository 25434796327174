import { Button, ButtonGroup, Dialog, DialogBody, DialogContainer, DialogFooter, DialogHeader } from '@wg/wows-react-uikit'
import classnames from 'classnames'
import equal from 'fast-deep-equal/react'
import get from 'lodash/get'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import HeaderSubtitle from '~/Components/HeaderSubtitle/HeaderSubtitle'
import WidthWrapper from '~/Components/WidthWrapper/WidthWrapper'
import { State } from '~/Reducers'
import { IReward } from '~/Reducers/ReducerApp'
import { isInIframe } from '~/utils'
import styles from './RewardsDialog.scss'
import { Button as DockyardButton } from '~/Components/Buttons/Buttons'
import { renderRewards } from '~/utils/renderUnitedRewards'

export interface RewardsDialog_Props {
    isVisible: boolean
    ids: Array<string>
    title?: string | JSX.Element
    subtitle?: string
    actionButtonText: string
    isBuyAction?: boolean
    isActionDisabled?: boolean
    footerContent?: React.ReactNode

    onHide: () => void
    onActionButtonPress: () => void
}

export interface RewardsDialogState {
    rewards: Array<IReward>
    eventum: number
    compensations: Array<CompensationItem>
}

const stateSelector = (state: State): RewardsDialogState => {
    const eventum = get(state.ReducerApp, 'account.wallet.eventum', 0) as number

    return {
        rewards: state.ReducerApp.rewards,
        eventum: eventum,
        compensations: state.ReducerApp.compensations,
    }
}

const RewardsDialog = (props: RewardsDialog_Props) => {
    const { rewards, eventum, compensations } = useSelector<State, RewardsDialogState>(stateSelector, equal)
    const {
        isVisible,
        ids,
        title,
        subtitle,
        actionButtonText,
        isActionDisabled,
        isBuyAction,
        footerContent,

        onActionButtonPress,
        onHide,
    } = props

    const { t } = useTranslation()
    const isFrame = isInIframe()

    const renderContent = () => {
        return (
            <div className={styles.contentWrapper}>
                <div className={styles.iconsRow}>{renderRewards(true, rewards, eventum, compensations, ids)}</div>
                <div className={styles.iconsRow}>{renderRewards(false, rewards, eventum, compensations, ids)}</div>
            </div>
        )
    }

    const renderDialog = () => {
        return (
            <DialogContainer>
                {title && (
                    <DialogHeader>
                        {isFrame && (
                            <div className={styles.armoryTitle} onClick={onHide}>
                                {t('Стартовые наборы')}
                            </div>
                        )}
                        {title}
                    </DialogHeader>
                )}
                <DialogBody>
                    {subtitle && <HeaderSubtitle>{subtitle}</HeaderSubtitle>}
                    <WidthWrapper>{renderContent()}</WidthWrapper>
                </DialogBody>
                <DialogFooter>
                    {!!footerContent && <div className={styles.footerContent}>{footerContent}</div>}

                    {isBuyAction ? (
                        <>
                            <ButtonGroup>
                                <Button isFlat={true} type={'orange'} onClick={onActionButtonPress} isDisabled={isActionDisabled}>
                                    {t('Да')}
                                </Button>
                                <DockyardButton label={t('Нет')} onClick={onHide} />
                            </ButtonGroup>
                        </>
                    ) : (
                        <ButtonGroup>
                            <DockyardButton onClick={onActionButtonPress} disabled={isActionDisabled} label={actionButtonText} />
                        </ButtonGroup>
                    )}
                </DialogFooter>
            </DialogContainer>
        )
    }

    const dialog = classnames(styles.dialog, {
        [styles.dialogArmory]: isFrame,
    })

    return <Dialog className={dialog} content={renderDialog()} size={'worship'} isOverlay={false} isVisible={isVisible} hideDialog={onHide} />
}

export default memo(RewardsDialog)
