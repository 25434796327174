import { DivTooltip } from '@wg/wows-react-uikit'
import classnames from 'classnames'
import equal from 'fast-deep-equal/react'
import React, { memo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { openWelcomeDialog } from '~/Actions/ActionDialogs'
import Reward from '~/Components/Reward/Reward'
import RewardHighlight from '~/Components/RewardHighlight/RewardHighlight'
import RewardShip from '~/Components/RewardShip/RewardShip'
import RewardShipTooltip from '~/Components/Tooltips/RewardShipTooltip/RewardShipTooltip'
import RewardTooltip from '~/Components/Tooltips/RewardTooltip/RewardTooltip'
import { IBooster, IReward } from '~/Reducers/ReducerApp'
import * as Api from '~/types'
import { getShipIdFromReward } from '~/utils/rewards'
import styles from './ProgressBarItem.scss'

export interface ProgressBatItem_Props {
    reward: IReward
    issuedReward?: IReward
    shipLevel: Api.ShipLevel
    isPremiumLevel: boolean
    isReached: boolean
    isActive: boolean
    isLast: boolean
    index: number
    isBlueprint: boolean
    bgImage: string
    starters: Array<IBooster>
    startersAllowed: boolean
    currentProgress: number
    pendingTransactions: boolean
}

const ProgressBatItem = (props: ProgressBatItem_Props) => {
    const {
        isPremiumLevel,
        isReached,
        isActive,
        issuedReward,
        shipLevel,
        reward,
        index,
        isBlueprint,
        bgImage,
        startersAllowed,
        starters,
        currentProgress,
        pendingTransactions,
    } = props
    let hideTooltip = false

    const shipId = getShipIdFromReward(reward)
    const dispatch = useDispatch()
    const wrapper = useRef<HTMLDivElement>()
    const isCollected = !!issuedReward

    const color = isBlueprint ? 'blue' : 'yellow'

    const collectedMark = classnames(styles.collectedMark, {
        [styles[`collectedMark-yellow`]]: true,
    })

    const rewardImage = classnames(styles.rewardImage, {
        [styles.rewardImageCollected]: isCollected && !reward.isMainReward,
    })

    const levelLabel = classnames(styles.levelLabel, {
        [styles.levelLabelReached]: isReached,
    })

    const shipRewardWrapper = classnames(styles.shipRewardTopMargin)

    const getTooltip = () => {
        if (hideTooltip) return null
        return shipId ? (
            <RewardShipTooltip id={shipId} reward={reward} isCollected={isCollected} isPremium={isPremiumLevel} bgImage={bgImage} />
        ) : (
            <RewardTooltip
                shipLevelDescription={shipLevel.description}
                reward={reward}
                isCollected={isCollected}
                isPremium={isPremiumLevel}
                bgImage={bgImage}
            />
        )
    }

    const renderReward = shipId ? (
        <div className={shipRewardWrapper}>
            <RewardShip
                id={shipId}
                isCollected={isCollected}
                isPremium={isPremiumLevel}
                fixedTooltip={true}
                raysVisible={isActive || isCollected}
                reward={reward}
            />
        </div>
    ) : (
        <Reward
            reward={reward}
            shipLevelDescription={shipLevel.description}
            isPremium={isPremiumLevel}
            isCollected={isCollected}
            fixedTooltip
            isSmall
            noCount
            useIcon
        />
    )

    const onClick = () => {
        if (isReached && !isCollected) {
            dispatch(openWelcomeDialog(true))
        }
    }

    return (
        <div ref={wrapper} className={styles.wrapper} onClick={onClick}>
            <div className={styles.highlight}>
                <RewardHighlight isVisible={!shipId && isActive} color={color} />
            </div>
            <div className={styles.inner}>
                <DivTooltip tooltipBody={getTooltip()} fixed position={'top'} className={styles.reward}>
                    <div id={shipId ? `progresbar-reward-image-ship-${index}` : `progresbar-reward-image-${index}`} className={rewardImage}>
                        {renderReward}
                    </div>
                    {isCollected && <div className={collectedMark} />}
                </DivTooltip>
                <div className={levelLabel}>{reward.shipLevelNumber}</div>
            </div>
        </div>
    )
}

export default memo(ProgressBatItem, equal)
