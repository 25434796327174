import { MutableRefObject } from 'react'
import { SLIDE_WIDTH } from './constants'

export interface ProgressBarControllerParams {
    mouseIsOver: MutableRefObject<boolean>
    width: number
    checkScrollPosition: () => void
    setLocalProgress: (index: number | null) => void
    goTo: (index: number) => void
}

export class ProgressBarController {
    checkScrollPosition: () => void
    setLocalProgress: (index: number | null) => void
    goTo: (index: number) => void
    mouseIsOver: MutableRefObject<boolean>
    width: number

    constructor(params: ProgressBarControllerParams) {
        this.checkScrollPosition = params.checkScrollPosition
        this.setLocalProgress = params.setLocalProgress
        this.goTo = params.goTo
        this.mouseIsOver = params.mouseIsOver
        this.width = params.width
    }

    showReward = (index: number) => {
        this.mouseIsOver.current = true
        const indexOffset = Math.ceil(this.width / SLIDE_WIDTH / 2)
        this.setLocalProgress(index)
        this.goTo(index - indexOffset)
    }

    returnToCurrent = () => {
        this.mouseIsOver.current = false
        this.setLocalProgress(null)
        this.checkScrollPosition()
    }
}
