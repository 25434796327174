import { Button, Dialog, Label } from '@wg/wows-react-uikit'
import Count from '~/Components/Count/Count'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { addEventin, deleteAccount, switchSync, turnGuideOff, turnGuideOn } from '~/Actions/ActionApp'
import { setShipLevelLocal as setShipLevel } from '~/Actions/ActionClient'
import { setDefaultDates, setEventEndDate, setEventStartDate } from '~/Actions/ActionDev'
import {
    closeFinalDialog,
    closeMultipleBuyDialog,
    hideBlueprintsViewer,
    openFinalDialog,
    openMultipleBuyDialog,
    showBlueprintsViewer,
    showFinalVideo,
    showIntroVideo,
    showLaunchVideo,
} from '~/Actions/ActionDialogs'
import { timing } from '~/utils/updateProgressSpeed'
import preloaded from '~/preloaded'
import { State } from '~/Reducers'
import { searchToObject } from '~/utils'
import styles from './DevPanel.scss'

const queryParams = searchToObject()
const isSuperTest = queryParams.superTest === true
const isDevMode = queryParams.devMode === true

interface OwnProps {}

interface DispatchProps {
    setShipLevel: (level: number) => void
    turnGuideOn: () => void
    turnGuideOff: () => void
    deleteAccount: () => void
    switchSync: () => void
    showIntroVideo: () => void
    showLaunchVideo: () => void
    showFinalVideo: () => void
    addEventin: (value: number) => void
    setEventStartDate: (date: number) => void
    setEventEndDate: (date: number) => void
    setDefaultDates: () => void
    showBlueprintsViewer: () => void
    hideBlueprintsViewer: () => void
    closeMultipleBuyDialog: () => void
    openMultipleBuyDialog: () => void
    openFinalDialog: () => void
    closeFinalDialog: () => void
}

interface StateProps {
    shipLevel: number
    guideIsRunning: boolean
    isSyncEnabled: boolean
    isBlueprintsViewerVisible: boolean
    isMultipleBuyDialogVisible: boolean
    isFinalDialogOpen: boolean
    showDevPanel: boolean
    showResetButton: boolean
}

export type IProps = StateProps & OwnProps & DispatchProps

interface IState {
    isOpened: boolean
    isDevFrameVisible: boolean
    isPendingFrameVisible: boolean
    isTimingDialogVisible: boolean
    videoId: null | number
    addEventin: number
    startDateDays: number
    endDateDays: number
}

const getMs = (days: number): number => {
    return days * 24 * 60 * 60 * 1000
}

class DevPanel extends Component<IProps, IState> {
    state: IState = {
        isOpened: false,
        isDevFrameVisible: false,
        isPendingFrameVisible: false,
        isTimingDialogVisible: false,
        videoId: null,
        addEventin: 0,
        startDateDays: 0,
        endDateDays: 0,
    }

    toggleOpen = () => {
        this.setState({ isOpened: !this.state.isOpened })
    }

    upLevel = (levelKey: string) => () => {
        switch (levelKey) {
            case 'ship_level': {
                const level = this.props.shipLevel + 1
                this.props.setShipLevel(level)
                break
            }
            default: {
                return
            }
        }
    }

    downLevel = (levelKey: string) => () => {
        switch (levelKey) {
            case 'ship_level': {
                let level = this.props.shipLevel - 1
                if (level < 0) {
                    level = 0
                }
                this.props.setShipLevel(level)
                break
            }
            default: {
                return
            }
        }
    }

    hideDevFrame = () => {
        this.setState({ isDevFrameVisible: false })
    }

    showDevFrame = () => {
        this.setState({ isDevFrameVisible: true })
    }

    hidePendingFrame = () => {
        this.setState({ isPendingFrameVisible: false })
    }

    showPendingFrame = () => {
        this.setState({ isPendingFrameVisible: true })
    }

    hideTimingDialog = () => {
        this.setState({ isTimingDialogVisible: false })
    }

    showTimingDialog = () => {
        this.setState({ isTimingDialogVisible: true })
    }

    renderPublicTestPanel = () => {
        return (
            <div className={`${styles.wrapper} ${this.state.isOpened ? styles.wrapperOpened : ''}`}>
                <div className={styles.content}>
                    <div onClick={this.toggleOpen} className={`${styles.openButton} ${this.state.isOpened ? styles.openButtonClose : ''}`} />
                    {this.props.showResetButton && (
                        <div className={styles.param}>
                            <Label>{`Аккаунт`}</Label>
                            <div className={styles.rowWide}>
                                <Button isFlat={true} onClick={this.props.deleteAccount}>
                                    {'Удалить'}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    switchBlueprintsViewerVisible = () => {
        if (this.props.isBlueprintsViewerVisible) {
            this.props.hideBlueprintsViewer()
        } else {
            this.props.showBlueprintsViewer()
        }
    }

    switchMultipleBuyVisible = () => {
        if (this.props.isMultipleBuyDialogVisible) {
            this.props.closeMultipleBuyDialog()
        } else {
            this.props.openMultipleBuyDialog()
        }
    }

    switchFinalVisible = () => {
        if (this.props.isFinalDialogOpen) {
            this.props.closeFinalDialog()
        } else {
            this.props.openFinalDialog()
        }
    }

    renderTimingContent = () => {
        return (
            <div>
                <h3>{'Просмотри необходимые уровни, затем открой это окно'}</h3>
                <textarea className={styles.timings} defaultValue={timing.showTimings()} />
            </div>
        )
    }

    render() {
        if ((isDevMode && !this.props.showDevPanel) || (isSuperTest && !this.props.showResetButton)) {
            return null
        }

        if (isSuperTest) {
            return this.renderPublicTestPanel()
        }

        return (
            <div className={`${styles.wrapper} ${this.state.isOpened ? styles.wrapperOpened : ''}`}>
                <div className={styles.content}>
                    <div onClick={this.toggleOpen} className={`${styles.openButton} ${this.state.isOpened ? styles.openButtonClose : ''}`} />

                    <div className={styles.param}>
                        <Label>{`Этап`}</Label>
                        <div className={styles.row}>
                            <Button isFlat={true} onClick={this.downLevel('ship_level')}>
                                {'-'}
                            </Button>
                            <div className={styles.spacer}>
                                <span className={styles.value}>{this.props.shipLevel}</span>
                            </div>
                            <Button isFlat={true} onClick={this.upLevel('ship_level')}>
                                {'+'}
                            </Button>
                        </div>
                    </div>

                    {this.props.showResetButton && (
                        <div className={styles.param}>
                            <Label>{`Аккаунт`}</Label>
                            <div className={styles.rowWide}>
                                <Button isFlat={true} onClick={this.props.deleteAccount}>
                                    {'Удалить'}
                                </Button>
                            </div>
                        </div>
                    )}

                    <div className={styles.param}>
                        <Label>{`Pending`}</Label>
                        <div className={styles.rowWide}>
                            <Button isFlat={true} onClick={this.showPendingFrame}>
                                {'Открыть'}
                            </Button>
                        </div>
                    </div>

                    <div className={styles.param}>
                        <Label>{`Timing`}</Label>
                        <div className={styles.rowWide}>
                            <Button isFlat={true} onClick={this.showTimingDialog}>
                                {'Открыть'}
                            </Button>
                        </div>
                    </div>

                    <div className={styles.param}>
                        <Label>{`Обучение`}</Label>
                        <div className={styles.rowWide}>
                            {this.props.guideIsRunning ? (
                                <Button isFlat={true} onClick={this.props.turnGuideOff}>
                                    {'Выключить'}
                                </Button>
                            ) : (
                                <Button isFlat={true} onClick={this.props.turnGuideOn}>
                                    {'Включить'}
                                </Button>
                            )}
                        </div>
                    </div>

                    <div className={styles.param}>
                        <Label>{`Sync`}</Label>
                        <div className={styles.rowWide}>
                            <Button isFlat={true} onClick={this.props.switchSync}>
                                {this.props.isSyncEnabled ? 'Выключить' : 'Включить'}
                            </Button>
                        </div>
                    </div>

                    <div className={styles.param}>
                        <Label>{`Blueprints`}</Label>
                        <div className={styles.rowWide}>
                            <Button isFlat={true} onClick={this.switchBlueprintsViewerVisible}>
                                {this.props.isBlueprintsViewerVisible ? 'Скрыть' : 'Показать'}
                            </Button>
                        </div>
                    </div>

                    <div className={styles.param}>
                        <Label>{`Buy dlg`}</Label>
                        <div className={styles.rowWide}>
                            <Button isFlat={true} onClick={this.switchMultipleBuyVisible}>
                                {this.props.isMultipleBuyDialogVisible ? 'Скрыть' : 'Показать'}
                            </Button>
                        </div>
                    </div>

                    <div className={styles.param}>
                        <Label>{`Final dlg`}</Label>
                        <div className={styles.rowWide}>
                            <Button isFlat={true} onClick={this.switchFinalVisible}>
                                {this.props.isFinalDialogOpen ? 'Скрыть' : 'Показать'}
                            </Button>
                        </div>
                    </div>

                    <div className={styles.param}>
                        <Label>{`Видео`}</Label>
                        <div className={styles.row}>
                            <Button isFlat={true} onClick={this.props.showIntroVideo}>
                                {'1'}
                            </Button>
                            <Button isFlat={true} onClick={this.props.showLaunchVideo}>
                                {'2'}
                            </Button>
                            <Button isFlat={true} onClick={this.props.showFinalVideo}>
                                {'3'}
                            </Button>
                        </div>
                    </div>
                    <div className={styles.param}>
                        <Label>{`Dockyardum`}</Label>
                        <div className={styles.row}>
                            <Count
                                value={this.state.addEventin}
                                onChange={(value: number) => {
                                    this.setState({ addEventin: value })
                                }}
                            />
                            <div className={styles.fullButton}>
                                <Button
                                    isFlat={true}
                                    onClick={() => {
                                        this.props.addEventin(this.state.addEventin)
                                        this.setState({ addEventin: 0 })
                                    }}
                                >
                                    {'Начислить'}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.param}>
                        <Label>{`Старт (д)`}</Label>
                        <div className={styles.row}>
                            <Count
                                min={-30}
                                value={this.state.startDateDays}
                                onChange={(value: number) => {
                                    this.setState({ startDateDays: value })
                                }}
                            />
                            <div className={styles.fullButton}>
                                <Button
                                    isFlat={true}
                                    onClick={() => {
                                        const now = moment.utc().unix() * 1000
                                        const startDate = now + getMs(this.state.startDateDays)
                                        this.props.setEventStartDate(startDate)
                                        this.setState({ startDateDays: 0 })
                                    }}
                                >
                                    {'Задать'}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.param}>
                        <Label>{`Конец (д)`}</Label>
                        <div className={styles.row}>
                            <Count
                                min={-30}
                                value={this.state.endDateDays}
                                onChange={(value: number) => {
                                    this.setState({ endDateDays: value })
                                }}
                            />
                            <div className={styles.fullButton}>
                                <Button
                                    isFlat={true}
                                    onClick={() => {
                                        const now = moment.utc().unix() * 1000
                                        const endDate = now + getMs(this.state.endDateDays)
                                        this.props.setEventEndDate(endDate)
                                        this.setState({ endDateDays: 0 })
                                    }}
                                >
                                    {'Задать'}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.param}>
                        <Label>{`Даты`}</Label>
                        <div className={styles.rowWide}>
                            <Button isFlat={true} onClick={this.props.setDefaultDates}>
                                {'Сбросить'}
                            </Button>
                        </div>
                    </div>
                </div>
                <Dialog
                    content={this.renderTimingContent()}
                    size={'extra-large'}
                    isOverlay={false}
                    isVisible={this.state.isTimingDialogVisible}
                    hideDialog={this.hideTimingDialog}
                />
            </div>
        )
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    shipLevel: state.ReducerApp.shipLevel,
    guideIsRunning: state.ReducerApp.guideIsRunning,
    isSyncEnabled: state.ReducerApp.isSyncEnabled,
    isBlueprintsViewerVisible: state.ReducerDialogs.isBlueprintsViewerVisible,
    isMultipleBuyDialogVisible: state.ReducerDialogs.isMultipleBuyDialogVisible,
    isFinalDialogOpen: state.ReducerDialogs.isFinalDialogOpen,
    showDevPanel: state.ReducerApp.showDevPanel,
    showResetButton: state.ReducerApp.showResetButton,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<State, unknown, AnyAction>, ownProps: OwnProps): DispatchProps => {
    return {
        setShipLevel: (level: number) => {
            dispatch(setShipLevel(level))
        },
        turnGuideOn: () => {
            dispatch(turnGuideOn())
        },
        turnGuideOff: () => {
            dispatch(turnGuideOff())
        },
        deleteAccount: () => {
            dispatch(deleteAccount())
        },
        switchSync: () => {
            dispatch(switchSync())
        },
        showIntroVideo: () => {
            dispatch(showIntroVideo())
        },
        showLaunchVideo: () => {
            dispatch(showLaunchVideo())
        },
        showFinalVideo: () => {
            dispatch(showFinalVideo())
        },
        addEventin: (value: number) => {
            dispatch(addEventin(value))
        },
        setEventStartDate: (date: number) => {
            dispatch(setEventStartDate(date))
        },
        setEventEndDate: (date: number) => {
            dispatch(setEventEndDate(date))
        },
        setDefaultDates: () => {
            dispatch(setDefaultDates())
        },
        showBlueprintsViewer: () => {
            dispatch(showBlueprintsViewer())
        },
        hideBlueprintsViewer: () => {
            dispatch(hideBlueprintsViewer())
        },
        openMultipleBuyDialog: () => {
            dispatch(openMultipleBuyDialog())
        },
        closeMultipleBuyDialog: () => {
            dispatch(closeMultipleBuyDialog())
        },
        openFinalDialog: () => {
            dispatch(openFinalDialog(''))
        },
        closeFinalDialog: () => {
            dispatch(closeFinalDialog())
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DevPanel)
