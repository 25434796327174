import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { hideBlueprintsViewer, showCompleteBlueprintsVideo, showFinalVideo, showLaunchVideo } from '~/Actions/ActionDialogs'
import ClientApi from '~/clientApi'
import { ACTION_IDS } from '~/constants'
import { State } from '~/Reducers'
import { getNewExtraShipLevel } from '~/utils/account'
import { isVideoWatched } from '~/utils/actions'
import { setAnimationState, setRepeatingState } from '~/Actions/ActionApp'

class AnimationIterator {
    private defaultClientState: ClientState = {
        shipLevel: 0,
        secondaryShipLevel: 0,
        tertiaryShipLevel: 0,
    }

    private _dispatch: ThunkDispatch<unknown, unknown, AnyAction>
    private _getState: () => State
    private currentClientState: ClientState = this.defaultClientState
    private targetClientState: ClientState
    private actionLevelMap: ActionLevelMap
    private revision: number
    private isInstant: boolean
    private inProgress = false

    private callback: () => void

    public animationFinished = (level: number) => {
        this.currentClientState.shipLevel = level
        if (this.callback) {
            this.callback()
        } else {
            this.startIteration()
        }
        if (this._getState().ReducerApp.animationShipLevel === this._getState().ReducerApp.targetLevel) {
            this._dispatch(setAnimationState(false))
            this._dispatch(setRepeatingState(false))
        }
    }

    public actionFinished = () => {
        this.callback = undefined
        this.startIteration()
    }

    public continueAction = () => {
        if (this.callback) {
            this.callback()
            this.callback = undefined
        }
    }

    public forceStop = () => {
        this.inProgress = false
    }

    public update = (
        clientState: ClientState,
        revision: number,
        isInstant: boolean,
        actionLevels: ActionLevelMap,
        dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
        getState: () => State,
    ): Promise<undefined> => {
        return new Promise((resolve) => {
            this._dispatch = dispatch
            this._getState = getState

            const { targetLevel, isPauseAnimation } = this._getState().ReducerApp

            if (this.currentClientState.shipLevel === clientState.shipLevel) {
                this.inProgress = false
            }

            if (
                this.currentClientState.shipLevel !== clientState.shipLevel ||
                clientState.shipLevel === 0 ||
                this.currentClientState.shipLevel !== targetLevel
            ) {
                this.actionLevelMap = actionLevels
                this.revision = revision
                this.isInstant = isInstant

                if (this.isInstant || clientState.shipLevel < this.currentClientState.shipLevel) {
                    ClientApi.updateDockData('update', clientState, revision, true, resolve)
                    this.currentClientState = clientState
                    this.targetClientState = clientState
                    this.callback = undefined
                } else {
                    this.targetClientState = clientState
                    if (isPauseAnimation) {
                        this.targetClientState.shipLevel = targetLevel
                    }
                    if (!this.inProgress) {
                        this.startIteration()
                    }
                }
            }
        })
    }

    private startIteration = () => {
        const nextClientState = { ...this.currentClientState }

        nextClientState.shipLevel++

        const actualState = this._getState()
        const secondaryShipLevels = actualState.ReducerApp.secondaryShipLevels
        const tertiaryShipLevels = actualState.ReducerApp.tertiaryShipLevels

        const newExtraShipLevel = getNewExtraShipLevel(secondaryShipLevels, nextClientState.shipLevel)
        const newTertiaryShipLevel = getNewExtraShipLevel(tertiaryShipLevels, nextClientState.shipLevel)

        nextClientState.secondaryShipLevel = newExtraShipLevel ? newExtraShipLevel.level : nextClientState.secondaryShipLevel
        nextClientState.tertiaryShipLevel = newTertiaryShipLevel ? newTertiaryShipLevel.level : nextClientState.tertiaryShipLevel

        if (nextClientState.shipLevel <= this.targetClientState.shipLevel) {
            this.inProgress = true
            if (Object.values(this.actionLevelMap).includes(nextClientState.shipLevel)) {
                for (const key in this.actionLevelMap) {
                    // @ts-ignore
                    if (this.actionLevelMap[key] === nextClientState.shipLevel) {
                        switch (key) {
                            case 'completeBlueprints': {
                                this.callback = () => {
                                    ClientApi.updateDockData('completeBlueprints', nextClientState, this.revision, this.isInstant) //
                                }
                                this._dispatch(showCompleteBlueprintsVideo())
                                setTimeout(() => {
                                    this._dispatch(hideBlueprintsViewer())
                                }, 500)
                                break
                            }

                            case 'launchingVideo': {
                                this.callback = () => {
                                    ClientApi.updateDockData('launchingVideo', nextClientState, this.revision, this.isInstant) //
                                }
                                this._dispatch(showLaunchVideo())
                                break
                            }
                            case 'finalVideo': {
                                this.callback = () => {
                                    const data = this._getState()
                                    if (!isVideoWatched(ACTION_IDS.FINAL_VIDEO, data.ReducerApp.account.completedActionsIds)) {
                                        this._dispatch(showFinalVideo())
                                    }
                                }
                                ClientApi.updateDockData('finalVideo', nextClientState, this.revision, this.isInstant) //
                                break
                            }
                            default: {
                                break
                            }
                        }
                    }
                }
            } else {
                this.currentClientState = nextClientState
                ClientApi.updateDockData('startIteration no action', nextClientState, this.revision, this.isInstant)
            }
        }
    }
}

export default new AnimationIterator()
