import styles from './InfoMessage.module.scss'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IBooster } from '~/Reducers/ReducerApp'
import { Interpolate } from '@wg/wows-react-uikit'
import classNames from 'classnames'
import { isInIframe } from '~/utils'

type Props = {
    predictProgress: number
    starters: Array<IBooster>
    commonStartersLevels: number
    singlePrice: number
}

const InfoMessage: React.FC<Props> = ({ starters, commonStartersLevels, predictProgress, singlePrice }) => {
    const { t } = useTranslation()
    const nextProgress = predictProgress + 1
    const checher = (starter: IBooster) => predictProgress === starter.bonus.shipLevel - 1 || nextProgress === commonStartersLevels
    const suitableLevels = starters.map(checher)
    function getNextSale(): number {
        const commonPrice = nextProgress * singlePrice
        const usedStarters = starters.filter((starter) => checher(starter))
        return commonPrice - usedStarters?.reduce((sum: number, a) => sum + a.bonus.shipLevel * singlePrice - a.price, 0)
    }

    if (!suitableLevels.some(Boolean)) return null
    const sale = Math.floor(100 - (100 * getNextSale()) / (singlePrice * nextProgress))

    const style = classNames(styles.wrapper, isInIframe() && styles.small)

    return (
        <div className={style}>
            <div className={styles.title}>
                <Interpolate
                    str={t('Стоимость %(count)s этапов выгоднее, чем %(currentCount)s')}
                    count={nextProgress}
                    currentCount={predictProgress}
                />
            </div>
            <div className={styles.saleInfo}>
                <Interpolate
                    str={t('Не упустите свою выгоду. При приобретении %(count)s&nbspэтапов действует скидка %(sale)s%.')}
                    count={nextProgress}
                    sale={sale}
                />
            </div>
        </div>
    )
}

export default InfoMessage
