import { default as _CountDown } from './CountDown/CountDown'
import { default as _DevPanel } from './DevPanel/DevPanel'
import { default as _Footer } from './Footer/Footer'
import { default as _Guide } from './Guide/Guide'
import { default as _Header } from './Header/Header'
import { default as _ProgressBar } from './ProgressBar/ProgressBar'
import { default as _Reward } from './Reward/Reward'
import { default as _VideoPlayer } from './VideoPlayer/VideoPlayer'
import { default as _Wallet } from './Wallet/Wallet'

export const Footer = _Footer
export const Header = _Header
export const ProgressBar = _ProgressBar
export const Reward = _Reward
export const Wallet = _Wallet
export const CountDown = _CountDown
export const DevPanel = _DevPanel
export const Guide = _Guide
export const VideoPlayer = _VideoPlayer
