import * as React from 'react'
import styles from './QuantityText.scss'
import classNames from 'classnames'
import { localization } from '@wg/wows-react-uikit'
import { PresentationStyles } from '@wg/wows-entities/const'
import { thousands } from '~/utils/formatting'
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity'

interface IQuantityComponent {
    amount: number
    type: string
    isShort?: boolean
    label?: string
    withoutIcon?: boolean
}

const { t } = localization

const QuantityText = (props: IQuantityComponent) => {
    const getAmountText = () => {
        switch (props.type) {
            case 'credits':
            case 'steel':
            case 'free_xp':
            case 'elite_xp':
            case 'coal':
            case 'paragon_xp':
                return (
                    <WoWSEntity
                        type={props.type}
                        amount={props.amount}
                        presentation={{
                            withTooltip: false,
                            style: PresentationStyles.TTC,
                        }}
                    />
                )
            default:
                return thousands(props.amount)
        }
    }

    return (
        <React.Fragment>
            {!props.isShort && (
                <div className={styles.contentBlockSmallTitle}>
                    {props.label ?? t('Количество: ')} {getAmountText()}
                </div>
            )}
        </React.Fragment>
    )
}

export default QuantityText
