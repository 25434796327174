import * as React from 'react'
import { Interpolate } from '@wg/wows-react-uikit'
import { withTranslation, WithTranslation } from 'react-i18next'
import { ship, specialReward } from '~/wowsEntities'
import CompensationEntity from '~/Components/Entities/CompensationEntity'
import store from '~/store'
import { Provider, useSelector } from 'react-redux'
import { State } from '~/Reducers'
import { State as AppState } from '~/Reducers/ReducerApp'
import CountDown from '~/Components/CountDown/CountDown'
import { getStartBoosterProgressRestriction } from '~/utils/boosters';

const ConnectedCompensationEntity = () => (
    <Provider store={store}>
        <CompensationEntity />
    </Provider>
)

export interface TextProps extends WithTranslation {
    str: string
}

function Text(props: TextProps) {
    const { t, str } = props
    const appState = useSelector<State, AppState>((state) => state.ReducerApp)
    const {
        specialRewards,
        mainRewardShipId,
        mainRewardShipName,
        shipLevels,
        progressFinishTime,
        closeTime,
        freeLevels,
        starterPacksMaxLevel,
        boosters,
        startersTimeLimit,
    } = appState

    const lastLevel = shipLevels.length - 1

    return (
        <Interpolate
            str={t(str)}
            ship={ship(mainRewardShipId, true)}
            shipWithIcons={ship(mainRewardShipId, false)}
            totalCount={lastLevel}
            canAchieveCount={freeLevels}
            compensation={<ConnectedCompensationEntity />}
            levelCount={starterPacksMaxLevel}
            firstSpecialReward={specialReward(specialRewards?.[0])}
            secondSpecialReward={specialReward(specialRewards?.[1])}
            firstSpecialRewardStage={specialRewards?.[0]?.shipLevelNumber}
            secondSpecialRewardStage={specialRewards?.[1]?.shipLevelNumber}
            progressFinishTime={<CountDown date={progressFinishTime} disableClock disabledTooltip />}
            startersTimeLimit={<CountDown date={startersTimeLimit} disableClock disabledTooltip />}
            closeTime={<CountDown date={closeTime} disableClock disabledTooltip />}
            name={mainRewardShipName}
            progressRestriction={getStartBoosterProgressRestriction(boosters)}
        />
    )
}

export default withTranslation()(Text)
