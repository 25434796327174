import { ButtonGroup, DialogBody, DialogContainer, DialogFooter, Interpolate } from '@wg/wows-react-uikit'
import classnames from 'classnames'
import equal from 'fast-deep-equal/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { closeFinalDialog as closeFinalDialogAction } from '~/Actions/ActionDialogs'
import preloaded from '~/preloaded'
import { State } from '~/Reducers'
import { ship } from '~/wowsEntities'
import styles from './FinalDialog.scss'
import { Button } from '~/Components/Buttons/Buttons'

export interface FinalDialogState {
    shipName: string
    shipId: string
    isShipCollected: boolean
    levelCount: number
}

const stateSelector = (state: State): FinalDialogState => {
    const shipId = state.ReducerApp.mainRewardShipId
    const issuedRewardsIds = state.ReducerApp.account.issuedRewards.map((issuedReward) => issuedReward.id)
    const issuedShips: Array<string> = []
    state.ReducerApp.rewards.forEach((reward) => {
        if (issuedRewardsIds.includes(reward.id)) {
            reward.gameRewards.forEach((gameReward) => {
                if (gameReward.type === 'ship') {
                    issuedShips.push(gameReward.id)
                }
            })
        }
    })

    return {
        shipName: state.ReducerApp.mainRewardShipName,
        shipId: state.ReducerApp.mainRewardShipId,
        isShipCollected: issuedShips.includes(shipId),
        levelCount: state.ReducerApp.lastLevel,
    }
}

const FinalDialog = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const state = useSelector<State, FinalDialogState>(stateSelector, equal)
    const { isShipCollected, levelCount, shipId } = state

    const onClose = () => {
        dispatch(closeFinalDialogAction())
    }

    const wrapper = classnames(styles.wrapper, {
        [styles.wrapper]: true,
    })

    const renderText = () => {
        return (
            <React.Fragment>
                <div className={styles.align}>
                    <Interpolate
                        str={t('Вы завершили %(levelsCount)s этапа строительства корабля и можете получить %(ship)s', {
                            count: levelCount,
                        })}
                        levelsCount={levelCount}
                        ship={ship(shipId)}
                    />
                </div>
            </React.Fragment>
        )
    }

    return (
        <DialogContainer>
            <div className={wrapper}>
                <div className={styles.content}>
                    <DialogBody>
                        <div className={styles.margin} />
                        {renderText()}
                        <div className={styles.spacer} />
                    </DialogBody>
                    <DialogFooter>
                        <ButtonGroup>
                            <Button onClick={onClose} label={isShipCollected ? t('Закрыть') : t('Забрать')} />
                        </ButtonGroup>
                    </DialogFooter>
                </div>
            </div>
        </DialogContainer>
    )
}

export default FinalDialog
