import TooltipGameReward from '~/Components/TooltipGameReward/TooltipGameReward'
import { Divider, Tooltip, DivTooltip } from '@wg/wows-react-uikit'
import RewardCount from '~/Components/RewardCount/RewardCount'
import React, { memo } from 'react'
import styles from './RewardCompensation.scss'
import { useTranslation } from 'react-i18next'

export interface RewardCompensation_Props extends CompensationItem {
    multiplier: number
}

const RewardCompensation = (props: RewardCompensation_Props) => {
    const { t } = useTranslation()

    const { amount, type, multiplier } = props

    const count = multiplier * amount

    const renderIcon = () => {
        switch (type) {
            case 'coal': {
                return <div className={styles.iconCoal} />
            }
            case 'steel': {
                return <div className={styles.iconSteel} />
            }
            case 'paragon_xp': {
                return <div className={styles.iconParagonXp} />
            }
            default: {
                return null
            }
        }
    }

    const renderTooltip = () => {
        return (
            <Tooltip>
                <div className={styles.tooltipHeader}>{t('Дополнительные этапы')}</div>
                <Divider type={'major'} />
                <div className={styles.rewardRow}>
                    <div className={styles.subtitle}>{t('Награда за завершение дополнительных этапов')}</div>
                    <div className={styles.rewardHolder}>
                        <div className={styles.wrapper}>
                            {renderIcon()}
                            <div className={styles.count}>
                                <RewardCount amount={count} />
                            </div>
                        </div>
                        <div className={styles.rewardDescription}>
                            <TooltipGameReward amount={amount} type={type} />
                        </div>
                    </div>
                </div>
            </Tooltip>
        )
    }

    return (
        <DivTooltip className={styles.compensationItem} tooltipBody={renderTooltip()}>
            <div className={styles.wrapper}>
                {renderIcon()}
                <div className={styles.count}>
                    <RewardCount amount={count} />
                </div>
            </div>
        </DivTooltip>
    )
}

export default memo(RewardCompensation)
