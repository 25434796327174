import React from 'react'
import { Step } from 'react-joyride'
import { ship } from '~/wowsEntities'
import styles from './Guide.scss'
import { i18nTparams } from '~/i18n'
import classnames from 'classnames'
import Api from '~/types'
import Text from '~/Components/Text/Text'

type GetStepsType = {
    t: (message: string, settings?: i18nTparams) => string
    shipId: string
    educationSteps: Array<Api.EducationStep>
}

const getSteps = (props: GetStepsType): Array<Step> => {
    const { t, shipId, educationSteps } = props

    return educationSteps.map((step, index) => {
        if (index === 0) {
            return {
                placement: 'center',
                target: 'body',
                title: (
                    <div className={styles.wrapTitle}>
                        <h2 className={styles.titleTopMargin}>{ship(shipId)}</h2>
                    </div>
                ),
                content: (
                    <div className={styles.stepContentWrapper}>
                        <div className={classnames(styles.stepTextLine, styles.advantages)}>
                            {step.features.map((feature, fIndex) => (
                                <div key={fIndex} className={styles.advantage}>
                                    <div className={classnames(styles.icon, styles.feature_1)} style={{ backgroundImage: `url(${feature.image})` }} />
                                    <span className={styles.nameAdvantage}>{t(feature.description)}</span>
                                </div>
                            ))}
                        </div>
                        <div className={styles.stepTextLineBlock}>
                            {step.rules.map((text, textIndex) => (
                                <div key={textIndex} className={styles.stepTextLine}>
                                    <Text str={text} />
                                </div>
                            ))}
                        </div>
                    </div>
                ),
            }
        }

        return {
            placement: index === educationSteps.length - 1 ? 'right' : 'top',
            target: '#progressWrapper',
            title: (
                <div className={styles.guideTipTitle}>
                    <Text str={step.title} />
                </div>
            ),
            content: (
                <div className={styles.guideTipContentWrapper}>
                    {step.rules.map((text, textIndex) => (
                        <div key={textIndex} className={styles.guideTipLine}>
                            <Text str={text} />
                        </div>
                    ))}
                </div>
            ),
        }
    })
}

export default getSteps
