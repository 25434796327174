import RefManager, { RefManagerKeys } from '~/refManager'
import equal from 'fast-deep-equal/react'
import { Processing } from '@wg/wows-react-uikit'
import classnames from 'classnames'
import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppInit, getData as getDataAction } from '~/Actions/ActionApp'
import { Footer, Guide, Header, VideoPlayer } from '~/Components'
import DevPanel from '~/Components/DevPanel/DevPanel'
import LoadError from '~/Components/LoadError/LoadError'
import ReplaySidebar from '~/Components/ReplaySidebar/ReplaySidebar'
import StartBooster from '~/Components/StartBooster/StartBooster'
import TopCounter from '~/Components/TopCounter/TopCounter'
import Wallet from '~/Components/Wallet/Wallet'
import ContainerDialogs from '~/Containers/ContainerDialogs'
import { useMount } from '~/hooks'
import { State } from '~/Reducers'
import { isBluredSelector } from '~/selectors'
import styles from './App.scss'
import BlueprintViewer, { BlueprintViewerRef } from '~/Components/BlueprintViewer/BlueprintViewer'
import { blueprintConfig } from '~/configs/blueprintConfig'
import { IS_DEV_MODE, IS_SUPER_TEST } from '~/constants'
import DownloadGamePopup from '~/Components/DownloadGamePopup/DownloadGamePopup'

interface AppState {
    isInGame: boolean
    isBlurred: boolean
    isFetching: boolean
    isLoadErrorVisible: boolean
    isAnimationInProgress: boolean
    plugOnWeb: string
    showDevPanel: boolean
    showResetButton: boolean
    showDownloadGamePopup: boolean
}

const stateSelector = (state: State): AppState => {
    const isBlured = isBluredSelector(state)
    const isFetching = state.ReducerApp.isPendingBuyBooster || state.ReducerApp.isPendingGetData || state.ReducerApp.isLoadingStore
    return {
        isInGame: state.ReducerApp.isInGame,
        isBlurred: isBlured,
        isFetching: isFetching,
        isLoadErrorVisible: state.ReducerDialogs.isLoadErrorVisible,
        isAnimationInProgress: state.ReducerApp.isAnimationInProgress,
        plugOnWeb: state.ReducerApp.plugOnWeb,
        showDevPanel: state.ReducerApp.showDevPanel,
        showResetButton: state.ReducerApp.showResetButton,
        showDownloadGamePopup: state.ReducerDialogs.isDownloadGamePopupVisible,
    }
}

const App = () => {
    const dispatch = useDispatch()
    const state = useSelector<State, AppState>(stateSelector, equal)

    const {
        isInGame,
        isBlurred,
        isFetching,
        isLoadErrorVisible,
        isAnimationInProgress,
        plugOnWeb,
        showDevPanel,
        showResetButton,
        showDownloadGamePopup,
    } = state

    const renderDevPanel = (IS_DEV_MODE || IS_SUPER_TEST) && (showDevPanel || showResetButton)

    useMount(() => {
        document?.body?.focus() // fix for in game browser
        dispatch(AppInit())
    })

    const getData = () => {
        dispatch(getDataAction())
    }

    const renderBackground = () => {
        if (isInGame) {
            return null
        } else {
            const style = {
                backgroundImage: `url(${plugOnWeb})`,
            }
            return <div className={styles.background} style={style} />
        }
    }

    const wrapper = classnames(styles.wrapper, {
        [styles.wrapperBlured]: isBlurred,
    })

    const setBlueprintViewerRef = (r: BlueprintViewerRef) => {
        if (r) {
            RefManager.setRef(RefManagerKeys.BlueprintViewer, r)
        }
    }

    return (
        <>
            <Processing isFetching={isFetching} />
            {showDownloadGamePopup ? (
                <DownloadGamePopup />
            ) : (
                <>
                    <VideoPlayer />
                    <div className={wrapper}>
                        {renderBackground()}
                        {blueprintConfig.isShowBlueprint && <BlueprintViewer ref={setBlueprintViewerRef} />}
                        <Header />
                        <TopCounter />
                        {!isAnimationInProgress && <StartBooster />}
                        <ReplaySidebar />
                        <Footer />
                    </div>
                    <ContainerDialogs />
                    <Guide />
                    {isLoadErrorVisible && <LoadError getData={getData} />}
                    {renderDevPanel && <DevPanel />}
                    <Wallet />
                </>
            )}
        </>
    )
}

export default memo(App)
