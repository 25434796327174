export class SessionStorage {
    set(name: string, value: any) {
        sessionStorage.setItem(name, JSON.stringify(value))
    }

    get(name: string) {
        const val = sessionStorage.getItem(name)
        return _parseJsonSafe(val) || null
    }

    remove(name: string) {
        sessionStorage.removeItem(name)
    }
}

function _parseJsonSafe(data: string): any | null {
    let result = null

    try {
        result = data && JSON.parse(data)
    } catch (e) {
        console.error(e)
    }

    return result
}

export const SessionStorageHelper = new SessionStorage()
