import * as React from 'react'
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity'
import { Currencies, EntityTypes, ItemTypes, PresentationStyles } from '@wg/wows-entities/const'
import classnames from 'classnames'
import styles from '~/Components/Dialogs/RulesDialog/RulesDialog.scss'
import { LANGUAGE_LOWER_FIRST_LETTER } from '~/constants'
import { IReward } from '~/Reducers/ReducerApp'
import preloaded from '~/preloaded'

export const ship = (id: string, hideIcons = false): React.ReactNode => (
    <WoWSEntity
        type={ItemTypes.VEHICLES}
        id={id}
        presentation={{
            withTooltip: true,
            hideNationIcon: hideIcons,
            hideTypeIcon: hideIcons,
        }}
    />
)

export const newShip = (id: string, hideIcons = false): React.ReactNode => (
    <WoWSEntity
        type={ItemTypes.VEHICLES}
        id={id}
        presentation={{
            withTooltip: true,
            style: PresentationStyles.TOOLTIP_WIDGET,
            hideNationIcon: hideIcons,
            hideTypeIcon: hideIcons,
        }}
    />
)

export const shipNoBlur = (id: string, hideIcons = false): React.ReactNode => (
    <WoWSEntity
        type={ItemTypes.VEHICLES}
        id={id}
        presentation={{
            withTooltip: true,
            hideNationIcon: hideIcons,
            hideTypeIcon: hideIcons,
            tooltipStyles: 'no_filter',
        }}
    />
)

export const compensation = (multiplier = 1, compensations: Array<CompensationItem>): React.ReactNode => (
    <div className={'compensation-inline-row'}>
        {compensations.map((compensationItem, index) => {
            return (
                <WoWSEntity
                    key={`compensation_${index}`}
                    className={'we-compensation'}
                    type={compensationItem.type}
                    amount={compensationItem.amount * multiplier}
                    presentation={{
                        withTooltip: true,
                        tooltipStyles: 'no_filter',
                    }}
                />
            )
        })}
    </div>
)

export const currency = (currency: string, amount = 0): React.ReactNode => (
    <WoWSEntity
        key={`${currency}_${amount}`}
        type={currency}
        amount={amount}
        presentation={{
            withTooltip: true,
        }}
    />
)

export const goldNoBlur = (amount = 0, isShowTooltip = true): React.ReactNode => (
    <WoWSEntity
        key={`gold_${amount}`}
        id={Currencies.GOLD}
        type={EntityTypes.CURRENCY}
        amount={amount}
        presentation={{
            withTooltip: isShowTooltip,
            tooltipStyles: 'no_filter',
        }}
    />
)

export const gold = (amount = 0, isShowTooltip = true, withoutAssetIcon = false, className?: string): React.ReactNode => (
    <WoWSEntity
        key={`gold_${amount}`}
        id={Currencies.GOLD}
        type={EntityTypes.CURRENCY}
        amount={amount}
        className={className}
        presentation={{
            withTooltip: isShowTooltip,
            withoutAssetIcon: withoutAssetIcon,
        }}
    />
)

export const specialReward = (reward: IReward): React.ReactNode => {
    const local = preloaded.settings.languageCode
    const { id, type, amount } = reward.gameRewards[0]
    if (type === 'ship') return ship(id, true)

    return (
        <WoWSEntity
            className={classnames({
                [styles.reward]: LANGUAGE_LOWER_FIRST_LETTER.includes(local),
            })}
            type={type}
            id={id}
            amount={amount}
            presentation={{
                withTooltip: true,
            }}
        />
    )
}
