import { Divider, Interpolate, Message, Tooltip } from '@wg/wows-react-uikit'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { PremiumLevelDescription } from '~/Components/Messages/Messages'
import TooltipGameReward from '~/Components/TooltipGameReward/TooltipGameReward'
import { GRADES } from '~/constants'
import { IReward } from '~/Reducers/ReducerApp'
import * as Api from '~/types'
import styles from './RewardTooltip.scss'
import classnames from 'classnames'
import { settings } from '~/Components/DownloadGamePopup/settings'
import QuantityText from '~/Components/QuantityText/QuantityText'
import { EntityTypes } from '@wg/wows-entities/const'
import { ship } from '~/wowsEntities'

interface IRewardTooltipProps {
    reward: IReward
    isCollected: boolean
    shipLevelDescription?: string
    isPremium?: boolean
    bgImage: string
}

const RewardTooltip = (props: IRewardTooltipProps) => {
    const { t } = useTranslation()
    const { reward, isCollected, shipLevelDescription, isPremium, bgImage } = props
    const renderWithAmount = reward.gameRewards.length == 1
    const isPermoflage = reward.gameRewards[0].type === EntityTypes.PERMOFLAGES

    const renderReward = () =>
        reward.gameRewards.map((item: Api.GameItemReward, index) => {
            return (
                <>
                    <div className={styles.rewardItem} key={index}>
                        <TooltipGameReward
                            imgSrc={reward.isMainReward && settings.isPublicTestMode ? reward.extraImage : null}
                            {...item}
                            hideTooltip
                            withAmount={!renderWithAmount}
                        />
                    </div>
                </>
            )
        })

    const renderTitle = () => {
        switch (reward.grade) {
            case GRADES.BRONZE:
            case GRADES.SILVER: {
                return <Interpolate str={t('Особая награда за %(level)s этап')} level={reward.shipLevelNumber} />
            }
            case GRADES.GOLD: {
                return t('Финальная награда')
            }
            default: {
                return <Interpolate str={t('Награда за %(level)s этап')} level={reward.shipLevelNumber} />
            }
        }
    }

    const header = classnames(styles.header, {
        [styles.receivedReward]: isCollected,
    })

    const backgroundStyle = {
        '--bg-image': `url(${bgImage})`,
        width: `100%`,
    }

    return (
        <Tooltip>
            <div className={styles.wrapper} style={backgroundStyle}>
                <div>
                    <div className={header}>{renderTitle()}</div>
                    <Divider type={'major'} />
                    <div className={styles.rewardRow}>
                        <div className={styles.rewardHolder}>{renderReward()}</div>
                    </div>
                    {isCollected && (
                        <>
                            <Divider type={'major'} />
                            <div className={styles.row}>
                                <Message style={'inline'} type={'done'}>
                                    {t('Получено')}
                                </Message>
                            </div>
                        </>
                    )}
                    {shipLevelDescription && (
                        <>
                            <Divider type={'major'} />
                            {renderWithAmount && (
                                <>
                                    <QuantityText type={reward.gameRewards[0].type} amount={reward.gameRewards[0].amount} />
                                    <Divider type={'major'} />
                                </>
                            )}
                            {isPermoflage && (
                                <>
                                    <div className={styles.descriptionRow}>{ship(reward.gameRewards[0].ship_id)}</div>
                                    <Divider type={'major'} />
                                </>
                            )}
                            <div className={styles.descriptionRow}>{shipLevelDescription}</div>
                        </>
                    )}
                    {isPremium && (
                        <>
                            <Divider type={'major'} />
                            <div className={styles.premiumRow}>
                                <PremiumLevelDescription />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Tooltip>
    )
}

export default memo(RewardTooltip, () => true)
