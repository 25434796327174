import get from 'lodash/get'
import { IBooster } from '~/Reducers/ReducerApp'
import Api from '~/types'

const getPrice = (booster: Api.Booster): number => {
    return booster.price.amount || 0
}

const mapBoosters = (rawBoosters: Array<Api.Booster>): Array<IBooster> => {
    const boosters: Array<IBooster> = []

    if (rawBoosters && rawBoosters.length) {
        rawBoosters.forEach((rawBooster) => {
            boosters.push({
                id: rawBooster.name,
                bonus: rawBooster.bonus,
                price: getPrice(rawBooster),
                isPremium: rawBooster.kind === 'premium',
                isHidden: rawBooster.hidden,
                unique: rawBooster.unique,
                restrictedWhenUserHasBoosters: rawBooster.restrictedWhenUserHasBoosters ? rawBooster.restrictedWhenUserHasBoosters : [],
                title: rawBooster.title,
                description: rawBooster.description,
            })
        })
    }

    return boosters
}

export default mapBoosters
