import timer from '~/utils/timer'
import equal from 'fast-deep-equal/react'
import { motion } from 'framer-motion'
import React from 'react'
import styles from './BlueprintViewer.scss'

const wrapper = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
    },
}

const icon = {
    hidden: {
        pathLength: 0,
        //fill: 'rgba(255, 255, 255, 0)',
    },
    visible: {
        pathLength: 1,
        //fill: 'rgba(255, 255, 255, 1)',
    },
}

export interface Level_Props {
    lines: Array<BlueprintsSvgDataItem>
    index: number
    duration: number
    isInstant: boolean

    onAnimationStart: (level: number) => void
    onAnimationEnd: (level: number, delay: number) => void
}

const BlueprintLevel = (props: Level_Props) => {
    const { index, lines, duration, isInstant, onAnimationEnd, onAnimationStart } = props
    const levelDuration = isInstant ? 10 : duration
    const levelDurationMs = levelDuration * 1000

    const onAnimationStartClb = () => {
        if (!isInstant) {
            onAnimationStart(index)
            const timerId = `blueprint-${index}`
            timer.start(timerId, levelDurationMs, () => {
                onAnimationEnd(index, levelDurationMs)
            })
        }
    }

    return (
        <div className={styles.innerLevel}>
            <motion.svg
                width="100%"
                height="100%"
                viewBox="0 0 1200 626"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                variants={wrapper}
                initial="hidden"
                animate="visible"
                transition={{
                    default: { duration: 0.1, ease: 'linear' },
                }}
                onAnimationStart={onAnimationStartClb}
            >
                {lines.map((item, index) => {
                    return (
                        <motion.path
                            key={`path-${props.index}-${index}`}
                            d={item.path}
                            variants={icon}
                            initial="hidden"
                            animate="visible"
                            transition={{
                                default: {
                                    ease: 'linear',
                                    duration: isInstant ? 0 : item.duration,
                                    delay: isInstant ? 0 : item.delay,
                                },
                            }}
                            stroke="#fff"
                            strokeWidth={1}
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    )
                })}
            </motion.svg>
        </div>
    )
}

export default React.memo(BlueprintLevel, equal)
