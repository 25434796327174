import { createBrowserHistory } from 'history'
import clientApi from '~/clientApi'

const history = createBrowserHistory()

export function redirectTo(url: string): void {
    history.push(url)
}

export function openExternalUrl(url: string): void {
    clientApi.openExternalUrl(url) || redirectTo(url)
}

export default history
