import { MutableRefObject } from 'react'
import { SwiperInstance } from 'react-id-swiper'
import styles from './ProgressBar.scss'
import get from 'lodash/get'
import { LOW_SCROLL_OFFSET, HIGH_SCROLL_OFFSET } from './constants'

export type ScrollState = 'start' | 'end' | 'middle' | 'full'

export const getOffsetRangeValue = (value: number): number => {
    const rangeVal = (value - LOW_SCROLL_OFFSET) / (HIGH_SCROLL_OFFSET - LOW_SCROLL_OFFSET)
    let opositeVal = 1 - rangeVal
    if (opositeVal < 0) {
        opositeVal = 0
    }
    return opositeVal
}

export const updateScrollState = (): number => {
    return 0
}

export const updateButtonsAndGradient = (
    swiper: SwiperInstance,
    scrollStateRef: MutableRefObject<ScrollState>,
    swiperWrapper: MutableRefObject<HTMLDivElement>,
    swiperNextButton: MutableRefObject<HTMLDivElement>,
    swiperPrevButton: MutableRefObject<HTMLDivElement>,
) => {
    const virtualSize = get(swiper, 'virtualSize', 0) as number
    const translate = get(swiper, 'translate', 0) as number
    const width = get(swiper, 'width', 0) as number

    let scrollState: ScrollState = 'start'
    const msl = `color-stop(5%, rgba(0,0,0,1))`
    const msr = `color-stop(85%, rgba(0,0,0,1))`

    const leftOffset = -translate
    const rightOffset = virtualSize - (width + leftOffset)
    const leftOpacity = getOffsetRangeValue(leftOffset)
    const rightOpacity = getOffsetRangeValue(rightOffset)

    if (swiper.isBeginning) {
        scrollState = 'start'
    }
    if (swiper.isEnd) {
        scrollState = 'end'
    }
    if (!swiper.isEnd && !swiper.isBeginning) {
        scrollState = 'middle'
    }
    if (swiper.isEnd && swiper.isBeginning) {
        scrollState = 'full'
    }

    if (swiperWrapper.current && (scrollState !== scrollStateRef.current || leftOffset <= HIGH_SCROLL_OFFSET || rightOffset <= HIGH_SCROLL_OFFSET)) {
        switch (scrollState) {
            case 'start': {
                swiperWrapper.current.style.webkitMaskImage = `-webkit-gradient(linear, left top, right top, color-stop(0%, rgba(0,0,0,1)), ${msl}, ${msr},  color-stop(88%, rgba(0, 0, 0, 0)))`
                swiperPrevButton.current.classList.add(styles.disabledArrowButton)
                swiperNextButton.current.classList.remove(styles.disabledArrowButton)
                break
            }
            case 'end': {
                swiperWrapper.current.style.webkitMaskImage = `-webkit-gradient(linear, left top, right top, color-stop(0%, rgba(0,0,0,0)), ${msl}, ${msr},  color-stop(88%, rgba(0, 0, 0, 1)))`
                swiperPrevButton.current.classList.remove(styles.disabledArrowButton)
                swiperNextButton.current.classList.add(styles.disabledArrowButton)
                break
            }
            case 'middle': {
                swiperWrapper.current.style.webkitMaskImage = `-webkit-gradient(linear, left top, right top, color-stop(0%, rgba(0,0,0,${leftOpacity})), ${msl}, ${msr},  color-stop(95%, rgba(0, 0, 0, 0)))`
                swiperPrevButton.current.classList.remove(styles.disabledArrowButton)
                swiperNextButton.current.classList.remove(styles.disabledArrowButton)
                break
            }
            default: {
                swiperWrapper.current.style.webkitMaskImage = `-webkit-gradient(linear, left top, 85% top, color-stop(0%, rgba(0,0,0,1)), ${msl}, ${msr},  color-stop(88%, rgba(0, 0, 0, 0)))`
                swiperPrevButton.current.classList.add(styles.disabledArrowButton)
                swiperNextButton.current.classList.add(styles.disabledArrowButton)
                break
            }
        }

        scrollStateRef.current = scrollState
    }
}
