import { ButtonGroup, DialogBody, DialogContainer, DialogFooter, DialogHeader, Link } from '@wg/wows-react-uikit'
import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { closeRulesDialog } from '~/Actions/ActionDialogs'
import { i18n_Props } from '~/i18n'
import { State } from '~/Reducers'
import * as Api from '~/types'
import styles from './RulesDialog.scss'
import Text from '~/Components/Text/Text'
import ClientApi from '~/clientApi'
import { appIsReady, isQuestsComplete } from '~/selectors'
import moment from 'moment'
import WidthWrapper from '~/Components/WidthWrapper/WidthWrapper'
import { Button } from '~/Components/Buttons/Buttons'
type OwnProps = i18n_Props

interface DispatchProps {
    closeRulesDialog: () => void
}

interface StateProps {
    completedActions: Array<string>
    appIsReady: boolean
    rules: Array<Api.Rule>
    isInGame: boolean
    readMoreLink?: string
    sseSectionName: string
    isSSEFinished: boolean
    progressFinishTime: number
}

export type Props = StateProps & OwnProps & DispatchProps

class RulesDialog extends PureComponent<Props> {
    render() {
        const { t, rules, isInGame, appIsReady, readMoreLink, sseSectionName, progressFinishTime, isSSEFinished } = this.props
        const now = moment.utc().unix() * 1000
        const isEventFinished = now >= progressFinishTime && appIsReady
        const isSSEVisible = !(isSSEFinished || isEventFinished)

        const readMoreText = t('Читать подробнее на портале игры')
        const goToSseText = t('Перейти к боевым задачам Верфи')

        return (
            <DialogContainer>
                <DialogHeader>{t('Правила')}</DialogHeader>
                <DialogBody>
                    <WidthWrapper className={styles.widthWrapper}>
                        <div className={styles.wrapper}>
                            {rules.map((rule, index) => (
                                <div className={styles.paragraph} key={index}>
                                    <div className={styles.title}>{t(rule.title)}</div>
                                    <div className={styles.text}>
                                        {rule.rules.map((ruleText, textIndex) => (
                                            <Text key={`${index}-${textIndex}`} str={ruleText} />
                                        ))}
                                    </div>
                                </div>
                            ))}

                            {readMoreLink && (
                                <div className={styles.portalLink}>
                                    {isInGame ? (
                                        <>
                                            <Link
                                                arrow=""
                                                isActionLink={false}
                                                onClick={() => {
                                                    ClientApi.openExternalUrl(this.props.readMoreLink)
                                                }}
                                            >
                                                {readMoreText}
                                            </Link>
                                            {isSSEVisible && (
                                                <Link
                                                    arrow=""
                                                    isActionLink={false}
                                                    onClick={() => {
                                                        ClientApi.navigateToSse(sseSectionName)
                                                    }}
                                                >
                                                    {goToSseText}
                                                </Link>
                                            )}
                                        </>
                                    ) : (
                                        <a href={this.props.readMoreLink}>{readMoreText}</a>
                                    )}
                                </div>
                            )}
                        </div>
                    </WidthWrapper>
                </DialogBody>
                <DialogFooter>
                    <ButtonGroup>
                        <Button onClick={this.props.closeRulesDialog} label={t('Закрыть')} />
                    </ButtonGroup>
                </DialogFooter>
            </DialogContainer>
        )
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    completedActions: state.ReducerApp.account.completedActionsIds,
    isInGame: state.ReducerApp.isInGame,
    rules: state.ReducerApp.rules,
    readMoreLink: state.ReducerApp.readMoreLink,
    sseSectionName: state.ReducerApp.sseSectionName,
    progressFinishTime: state.ReducerApp.progressFinishTime,
    isSSEFinished: appIsReady(state) && isQuestsComplete(state),
    appIsReady: appIsReady(state),
})

const mapDispatchToProps = (dispatch: ThunkDispatch<State, unknown, AnyAction>, ownProps: OwnProps): DispatchProps => {
    return {
        closeRulesDialog: () => {
            dispatch(closeRulesDialog())
        },
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RulesDialog))
