import '@wg/wows-css-uikit/index.css'
import { wowsEntities } from '@wg/wows-entities'
import '@wg/wows-entities/index.css'
import { TooltipProvider } from '@wg/wows-react-uikit'
import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { Router, Route, Switch } from 'react-router-dom'
import App from '~/Components/App/App'
import preloaded from '~/preloaded'
import store from '~/store'
import BundlePurchase from './Components/BundlePurchase/BundlePurchase'
import i18n from './i18n'
import history from '~/routerHistory'
import './styles/global.scss'
import { saveAnalyticsData } from '~/utils/analytics'
import dwhExport from '~/api/dwhExport'
import { DWH_EXPORT_EVENTS } from '~/constants'

export const startTime = Date.now()

wowsEntities.init({
    vortexBaseUrl: `https://${preloaded.urls.vortex}`,
    languageCode: preloaded.settings.languageCode,
})
saveAnalyticsData()
dwhExport.send(DWH_EXPORT_EVENTS.APP_INIT, {}, true, true)

export default function Root() {
    return (
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <TooltipProvider>
                    <Router history={history}>
                        <Switch>
                            <Route path="/:lang/bundlePurchase">
                                <BundlePurchase />
                            </Route>
                            <Route path="/">
                                <App />
                            </Route>
                        </Switch>
                    </Router>
                </TooltipProvider>
            </I18nextProvider>
        </Provider>
    )
}
