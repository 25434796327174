import { SessionStorageHelper } from '~/utils/storage'
import { getPlatform } from '~/utils/settings'
import { isInGame } from '~/Reducers/ReducerApp'

const ANALYTICS_KEY = 'accountAnalyticsData'
interface AccountAnalyticsData {
    platform?: string
    source?: string
}
const dataAllowedKeys = ['platform', 'source']

let _analyticsDataCache: AccountAnalyticsData | null = null
let _frontendSessionId: string | null = null
const randomHash = (length = 20) => {
    return [...Array(length)].map(() => (~~(Math.random() * 36)).toString(36)).join('')
}

export function saveAnalyticsData() {
    const params = new URLSearchParams(document.location.search.substring(1))
    const data = _getSavedAnalyticsData() || {}

    data.platform = data.platform || _determinePlatform()
    data.source = data.source || _getCleanedParam(params, 'utm_source')
    SessionStorageHelper.set(ANALYTICS_KEY, data)

    _analyticsDataCache = data
}

export function getAnalyticsData(): AccountAnalyticsData | null {
    if (_analyticsDataCache !== null) {
        return _analyticsDataCache
    }

    const data = _getSavedAnalyticsData()
    if (data !== null) {
        _analyticsDataCache = data
    }

    return data
}

export function getFrontendSessionId() {
    if (!_frontendSessionId) {
        _frontendSessionId = randomHash(20)
    }

    return _frontendSessionId
}

function _determinePlatform() {
    const platformWeb = 'web'

    let platform

    if (isInGame) {
        platform = getPlatform()
    } else {
        platform = platformWeb
    }

    return platform
}

function _getCleanedParam(params: URLSearchParams, name: string, maxLen = 20): string | null {
    let value = params.get(name)

    if (value) {
        value = ('' + value).replace(/[^a-z0-9-_]/gi, '')
    }

    if (value && maxLen) {
        value = value.substring(0, maxLen)
    }

    return value || null
}

function _getSavedAnalyticsData(): AccountAnalyticsData | null {
    let data = SessionStorageHelper.get(ANALYTICS_KEY)

    if (!_isAnalyticsDataValid(data)) {
        SessionStorageHelper.remove(ANALYTICS_KEY)
        data = null
    }

    return data
}

function _isAnalyticsDataValid(data: any) {
    if (typeof data !== 'object' || data === null) {
        return false
    }

    for (const [key, val] of Object.entries(data)) {
        if (!dataAllowedKeys.includes(key) || !(typeof val === 'string' || val === null)) {
            return false
        }
    }

    return true
}
