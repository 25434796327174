import React, { memo } from 'react'
import styles from './WidthWrapper.scss'
import classNames from 'classnames'

export interface WidthWrapper_Props {
    children: React.ReactNode | Array<React.ReactNode>
    className?: string
}

const WidthWrapper = (props: WidthWrapper_Props) => {
    return <div className={classNames(styles.wrapper, props.className)}>{props.children}</div>
}

export default memo(WidthWrapper)
