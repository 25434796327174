import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import wowsCommerce from '~/wowsCommerce'
import { setShopLoadingState } from './ActionApp'

export const PRODUCT_LOAD_STARTS = 'PRODUCT_LOAD_STARTS'
export const PRODUCT_LOAD_ENDS = 'PRODUCT_LOAD_ENDS'
export const loadProducts = (storefrontName: string) => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    if (window.dockyard.urls.wsmartUrl) {
        dispatch(setShopLoadingState(true))
        dispatch({ type: PRODUCT_LOAD_STARTS })
        const products = await wowsCommerce.products(storefrontName)
        dispatch({ type: PRODUCT_LOAD_ENDS, payload: products })
        dispatch(setShopLoadingState(false))
    }
}
