import { thousands } from '~/utils/formatting'
import React, { memo } from 'react'
import styles from './Currency.scss'

export interface Currency_Props {
    amount: number | string
}

const Currency = (props: Currency_Props) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.amount}>{thousands(parseInt(`${props.amount}`))}</div>
            <div className={styles.icon} />
        </div>
    )
}

export default memo(Currency)
