import React from 'react'
import styles from './HeaderSubtitle.scss'

export interface HeaderSubtitle_Props {
    children?: React.ReactNode
}

const HeaderSubtitle = (props: HeaderSubtitle_Props) => {
    const { children } = props

    return <div className={styles.wrapper}>{children}</div>
}

export default HeaderSubtitle
