import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { State } from '~/Reducers'
import { searchToObject } from '~/utils'

const queryParams = searchToObject()

const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export const SET_EVENT_START_DATE = 'SET_EVENT_START_DATE'
export const setEventStartDate = (date: number): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: SET_EVENT_START_DATE,
        payload: {
            date: date,
        },
    })
}

export const SET_EVENT_END_DATE = 'SET_EVENT_END_DATE'
export const setEventEndDate = (date: number): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: SET_EVENT_END_DATE,
        payload: {
            date: date,
        },
    })
}

export const SET_DEFAULT_DATES = 'SET_DEFAULT_DATES'
export const setDefaultDates = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    dispatch({
        type: SET_DEFAULT_DATES,
    })
}

export const addMockJsHost = (): ThunkAction<void, unknown, unknown, AnyAction> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
) => {
    const state = getState()
    let lastShipLevel: null | number = null

    const animations: Array<AnimatedLevel> = []

    const iterateAnimations = () => {
        const animation = animations.shift()
        if (animation) {
            if (window.onClientStateChange) {
                window.onClientStateChange(
                    JSON.stringify({
                        actionId: 'startAnimation',
                        level: animation.level,
                    }),
                )
                setTimeout(() => {
                    window.onClientStateChange(
                        JSON.stringify({
                            actionId: 'finishAnimation',
                            level: animation.level,
                        }),
                    )
                    iterateAnimations()
                }, animation.delay)
            }
        } else {
            setTimeout(() => {
                iterateAnimations()
            }, 500)
        }
    }

    iterateAnimations()

    const fillStack = (delay: number, fromLevel: number, toLevel: number) => {
        if (toLevel > fromLevel) {
            if (delay === 0) {
                animations.push({
                    delay,
                    level: toLevel,
                })
            } else {
                for (let level = fromLevel + 1; level <= toLevel; level++) {
                    animations.push({
                        delay,
                        level,
                    })
                }
            }
        } else {
            animations.push({
                delay,
                level: toLevel,
            })
        }
    }

    if (!state.ReducerApp.isInGame) {
        setTimeout(() => {
            if (window.onClientStateChange) {
                window.onClientStateChange(
                    JSON.stringify({
                        actionId: 'browserShown',
                    }),
                )
            }
        }, 100)

        window.jsHostQuery = (data: JsHostQueryRequest) => {
            const timings = getState().ReducerApp.timings
            const req: JsHostQueryReq = JSON.parse(data.request)
            if (req && req.params && req.params.actionId === 'updateDockData' && req.params.info && req.params.info.shipLevel !== undefined) {
                const shipLevel = req.params.info.shipLevel
                if (lastShipLevel !== null && lastShipLevel !== shipLevel) {
                    const fromLevel = lastShipLevel || 0
                    const timing = timings[fromLevel] ? timings[fromLevel] : 2
                    const delay = req.params.info.isInstant ? 0 : timing
                    fillStack(delay, fromLevel, shipLevel)
                }
                lastShipLevel = shipLevel
            }
        }
    }
}
