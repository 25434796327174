import moment from 'moment'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { setTargetLevel } from '~/Actions/ActionApp'
import { showLaunchVideo } from '~/Actions/ActionDialogs'
import { State } from '~/Reducers'
import * as Api from '~/types'
import { ACTION_IDS } from '~/constants'
import preloaded from '~/preloaded'

const MIN_LAUNCHING_REPLAY_DELAY = 5 // sec

interface ActionMap {
    [key: string]: Api.Action
}

export const videoIdsMapping = {
    intro: 'action_video_1',
    launch: 'action_video_2',
    final: 'action_video_3',
    completeBlueprints: 'action_video_complete_blueprints',
}

export const getVideoAction = (actions: Array<Api.Action>, videoType: VideoType): Api.Action => {
    const actionId = videoIdsMapping[videoType]
    return actions.find((action) => action.name === actionId)
}

export const isRealmCn360 = () => {
    if (preloaded.settings.realm === 'cn360') {
        return true
    } else {
        return false
    }
}

export const disabledCertainVideo = (videoType: VideoType): boolean => {
    switch (videoType) {
        case 'intro': {
            return preloaded.settings.disableIntroVideo
        }
        case 'completeBlueprints': {
            return preloaded.settings.disableCompleteBlueprintsVideo
        }
        case 'launch': {
            return preloaded.settings.disableLaunchVideo
        }
        case 'final': {
            return preloaded.settings.disableFinalVideo
        }
    }
}

export const isVideoWatched = (type: ACTION_IDS, completedActionsIds: Api.Account['completedActionsIds']): boolean => {
    return completedActionsIds?.includes(type)
}

export const getLaunchingLevel = (data: Api.ApiQuery): number => {
    const launchingAction = data.config.actions.find((action) => action.name === data.config.launchingLevelId)
    if (launchingAction) {
        return launchingAction.shipLevel
    } else {
        return 99
    }
}

const notInstantReplay = (state: State): boolean => {
    return (moment.utc().unix() * 1000 - state.ReducerDialogs.videoCloseTime) / 1000 > MIN_LAUNCHING_REPLAY_DELAY
}

export const showLaunching = (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => State,
    clientState: ClientState,
    isInstant = false,
) => {
    return new Promise((resolve: (level?: number) => void) => {
        const state = getState()
        const lastLevelBeforeLaunch = state.ReducerApp.launchingLevelId - 1

        if (isInstant && state.ReducerApp.isRepeating) {
            resolve()
            return
        }
        if ((isInstant || state.ReducerApp.animationShipLevel >= state.ReducerApp.launchingLevelId) && !state.ReducerApp.isRepeating) {
            resolve()
        } else {
            if (clientState.shipLevel >= state.ReducerApp.launchingLevelId && !state.ReducerApp.isLaunchingPassed) {
                if (state.ReducerApp.animationShipLevel === lastLevelBeforeLaunch) {
                    if (notInstantReplay(state)) {
                        dispatch(setTargetLevel(clientState.shipLevel))
                        dispatch(showLaunchVideo())
                    } else {
                        resolve(clientState.shipLevel)
                    }
                } else {
                    dispatch(setTargetLevel(clientState.shipLevel))
                    resolve(lastLevelBeforeLaunch)
                }
            } else {
                resolve()
            }
        }
    })
}

export const mapActions = (actions: Array<Api.Action>): ActionLevelMap => {
    const actionLevelMap: ActionLevelMap = {
        education: 0,
        introVideo: 0,
        launchingVideo: 0,
        finalVideo: 0,
        completeBlueprints: 0,
    }
    actions.forEach((action) => {
        const level = action.shipLevel
        switch (action.name) {
            case ACTION_IDS.EDUCATION: {
                actionLevelMap.education = level
                break
            }
            case ACTION_IDS.INTRO_VIDEO: {
                actionLevelMap.introVideo = level
                break
            }
            case ACTION_IDS.LAUNCH_VIDEO: {
                actionLevelMap.launchingVideo = level
                break
            }
            case ACTION_IDS.LAUNCHING: {
                break
            }
            case ACTION_IDS.FINAL_VIDEO: {
                actionLevelMap.finalVideo = level
                break
            }
            case ACTION_IDS.COMPLETE_BLUEPRINTS_VIDEO: {
                actionLevelMap.completeBlueprints = level
                break
            }
            default: {
                break
            }
        }
    })
    return actionLevelMap
}

export const getBlueprintsCount = (shipLevels: Array<Api.ShipLevel>) => {
    return shipLevels.filter((shipLevel) => shipLevel.kind === 'blueprint').length
}
