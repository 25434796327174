import log from 'loglevel'

interface Intervals {
    [key: string]: {
        timeout: NodeJS.Timeout
        elapsed: number
        isRunning: boolean
    }
}

class Timer {
    private step = 50
    private intervals: Intervals = {}

    public clear = (name: string) => {
        if (this.intervals[name]) {
            this.intervals[name].elapsed = 0
            this.intervals[name].isRunning = false
            clearInterval(this.intervals[name].timeout)
        }
    }

    public start = (name: string, delay: number, clb?: () => void) => {
        this.intervals[name] = {
            timeout: setInterval(() => {
                this.intervals[name].elapsed += this.step
                if (this.intervals[name].elapsed >= delay) {
                    this.intervals[name].elapsed = 0
                    this.intervals[name].isRunning = false
                    clearInterval(this.intervals[name].timeout)
                    if (clb) {
                        clb()
                    }
                }
            }, this.step),
            elapsed: 0,
            isRunning: true,
        }
    }

    public isRunning = (name: string) => {
        if (this.intervals[name]) {
            return this.intervals[name].isRunning
        } else {
            return false
        }
    }
}

export default new Timer()
