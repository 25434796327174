import equal from 'fast-deep-equal/react'
import RewardsDialog from '~/Components/Dialogs/RewardsDialog/RewardsDialog'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import RewardShip from '~/Components/RewardShip/RewardShip'
import { State } from '~/Reducers'
import { IReward } from '~/Reducers/ReducerApp'
import { getShipIdFromReward } from '~/utils/rewards'
import styles from './RangeRewards.scss'
import TooltipGameReward from '~/Components/TooltipGameReward/TooltipGameReward'
import { hideRestRewards, showRestRewards } from '~/Actions/ActionDialogs'

export interface RangeRewards_Props {
    from: number
    to: number
    filteredShips: Array<string>
    isLastIncluded: boolean
}

export interface RangeRewardsState {
    rewards: Array<IReward>
    isRestRewardsDialogVisible: boolean
}

const MAX_VISIBLE_COUNT = 7

const stateSelector = (state: State): RangeRewardsState => {
    return {
        rewards: state.ReducerApp.rewards,
        isRestRewardsDialogVisible: state.ReducerDialogs.isRestRewardsDialogVisible,
    }
}

const RangeRewards = (props: RangeRewards_Props) => {
    const { from, to, filteredShips, isLastIncluded } = props
    const dispatch = useDispatch()

    const { t } = useTranslation()
    const { rewards, isRestRewardsDialogVisible } = useSelector<State, RangeRewardsState>(stateSelector, equal)

    const showRewardsDialog = () => {
        dispatch(showRestRewards())
    }

    const hideRewardsDialog = () => {
        dispatch(hideRestRewards())
    }

    const renderReward = (isShip: boolean, reward: any, shipId?: string) => {
        if (isShip) {
            return <RewardShip id={shipId} fixedTooltip={false} reward={reward} />
        } else {
            return <TooltipGameReward hideText={true} {...reward} withAmount />
        }
    }

    let visibleItemsCount = 0

    const pushToIcons = (icons: Array<React.ReactNode>, reward: any, index: number, isVisible: boolean, isShip: boolean) => {
        let shipId
        if (isShip) {
            shipId = getShipIdFromReward(reward)
        }
        icons.push(
            <CSSTransition in={isVisible} unmountOnExit classNames="zoom-range-reward" timeout={200} key={`RangeReward-${index}`}>
                <div className={styles.rewardWrapper} key={`reward-${reward.id}`}>
                    {renderReward(isShip, reward, shipId)}
                </div>
            </CSSTransition>,
        )
    }

    const checkVisible = (level: number, length: number) => {
        return level <= to && length < MAX_VISIBLE_COUNT
    }

    const renderRewards = (rewardsArray: Array<IReward>, isShip: boolean) => {
        const icons: Array<React.ReactNode> = []

        const filteredRewards = rewardsArray.filter((reward) => {
            const shipId = getShipIdFromReward(reward)
            const isShipReward = !!shipId
            return isShip === isShipReward && reward.gameRewards && reward.gameRewards.length > 0 && !filteredShips.includes(shipId)
        })

        filteredRewards.forEach((reward: IReward, index: number) => {
            if (reward.shipLevelNumber <= from || reward.shipLevelNumber > to) return
            let isVisible
            if (isShip) {
                pushToIcons(icons, reward, index, isVisible, isShip)
                isVisible = checkVisible(reward.shipLevelNumber, visibleItemsCount)
                if (isVisible) visibleItemsCount++
            } else {
                reward.gameRewards.map((gameReward) => {
                    isVisible = checkVisible(reward.shipLevelNumber, visibleItemsCount)
                    pushToIcons(icons, gameReward, index, isVisible, isShip)
                    if (isVisible) visibleItemsCount++
                })
            }
        })

        return icons
    }

    const ships = renderRewards(rewards, true)
    const restRewards = renderRewards(rewards, false)
    const hiddenRewardsCount = restRewards.length + ships.length - visibleItemsCount
    const isRestRewardsButtonVisible = hiddenRewardsCount > 0
    const restRewardsIds = rewards.filter((r) => r.shipLevelNumber > from && r.shipLevelNumber <= to).map((r) => r.id)

    const renderRestRewardsCountButton = () => {
        return (
            <CSSTransition key={`CountButton`} in={isRestRewardsButtonVisible} unmountOnExit classNames="zoom-range-reward" timeout={200}>
                <div className={styles.showRewardsDialogButton} onClick={showRewardsDialog}>
                    {`+${hiddenRewardsCount}`}
                </div>
            </CSSTransition>
        )
    }

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.availableRewardsIcons}>
                    {ships}
                    {restRewards}
                    {renderRestRewardsCountButton()}
                </div>
            </div>
            <RewardsDialog
                isVisible={isRestRewardsDialogVisible}
                ids={restRewardsIds}
                title={t('Награды')}
                actionButtonText={t('Закрыть')}
                onHide={hideRewardsDialog}
                onActionButtonPress={hideRewardsDialog}
            />
        </>
    )
}

export default RangeRewards
