import { playButtonClickSound } from '@wg/web2clientapi/sound'
import equal from 'fast-deep-equal/react'
import isFunction from 'lodash/isFunction'
import React, { MutableRefObject, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import Swiper, { SwiperInstance } from 'react-id-swiper'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import ClientApi from '~/clientApi'
import ProgressBarItem from '~/Components/ProgressBatItem/ProgressBarItem'
import { State } from '~/Reducers'
import { IBooster, IReward } from '~/Reducers/ReducerApp'
import { premiumBoosterProgress } from '~/selectors'
import * as Api from '~/types'
import { getShipIdFromReward } from '~/utils/rewards'
import timer from '~/utils/timer'
import styles from './ProgressBar.scss'
import { ProgressBarController } from './ProgressBarController'
import ProgressBarMask from './ProgressBarMask'
import { ScrollState, updateButtonsAndGradient } from './utils'
import FinalReward from '~/Components/ProgressBar/FinalReward/FinalReward'
import {
    MASK_TAIL_WIDTH,
    MOUSE_LEAVE_DELAY,
    PROGRESSBAR_LEAVE_TIMER_ID,
    SCROLL_PROGRESS,
    SLIDE_WIDTH,
    SQUIRCLE_WIDTH,
    SVG_PROGRESS_ITEMS_MASK_ID,
} from './constants'

export interface ProgressBar_Props {}

export type ProgressBarRefFunc = (ref: ProgressBarController) => void

export type ProgressBarRef = ProgressBarRefFunc | MutableRefObject<ProgressBarController>

export interface ProgressBarState {
    rewards: Array<IReward>
    animationProgress: number
    premiumBoosterProgress: number
    shipLevels: Array<Api.ShipLevel>
    issuedRewards: Array<IReward>
    isAnimationInProgress: boolean
    blueprintsCount: number
    isBlueprintsViewerVisible: boolean
    accountProgress: number
    freeLevels: number
    bgImage: string
    starters: Array<IBooster>
    startersAllowed: boolean
    pendingTransactions: boolean
}

export const stateSelector = (state: State): ProgressBarState => {
    const starters = state.ReducerApp.boosters.filter((item) => item.unique && !state.ReducerApp.account.boughtBoostersIds.includes(item.id))
    return {
        rewards: state.ReducerApp.rewards,
        animationProgress: state.ReducerApp.animationShipLevel,
        premiumBoosterProgress: premiumBoosterProgress(state),
        shipLevels: state.ReducerApp.shipLevels,
        issuedRewards: state.ReducerApp.account.issuedRewards,
        isAnimationInProgress: state.ReducerApp.isAnimationInProgress,
        blueprintsCount: state.ReducerApp.blueprintsCount,
        isBlueprintsViewerVisible: state.ReducerDialogs.isBlueprintsViewerVisible,
        accountProgress: state.ReducerApp.account.progress,
        freeLevels: state.ReducerApp.freeLevels,
        bgImage: state.ReducerApp.plugOnWeb,
        starters: starters,
        startersAllowed: state.ReducerApp.startersTimeLimit > Date.now() && starters.length > 0,
        pendingTransactions: state.ReducerApp.account.pendingTransactions,
    }
}

const ProgressBar = React.forwardRef((props: ProgressBar_Props, ref: ProgressBarRef) => {
    const {
        rewards,
        animationProgress,
        premiumBoosterProgress,
        shipLevels,
        issuedRewards,
        isAnimationInProgress,
        blueprintsCount,
        isBlueprintsViewerVisible,
        accountProgress,
        freeLevels,
        bgImage,
        starters,
        startersAllowed,
        pendingTransactions,
    } = useSelector<State, ProgressBarState>(stateSelector, equal)

    const swiperRef = useRef<SwiperInstance | null>(null)
    const swiperWrapper = useRef<HTMLDivElement>()
    const swiperNextButton = useRef<HTMLDivElement>()
    const swiperPrevButton = useRef<HTMLDivElement>()
    const scrollState = useRef<ScrollState>('start')
    const mouseIsOver = useRef<boolean>(false)
    const [localProgress, setLocalProgress] = useState<number | null>(null)
    const [isHideFinalReward, setIsHideFinalReward] = useState<boolean>(true)
    const reachableCount = rewards.length > 0 ? freeLevels + premiumBoosterProgress : 0
    const fadedIndexes = rewards.filter((reward) => !!getShipIdFromReward(reward)).map((r) => r.shipLevelNumber)
    const getIndex = (indexParam: number): number => {
        const width = swiperWrapper.current.clientWidth
        const indexOffset = Math.ceil(width / SLIDE_WIDTH / 2)
        const index = indexParam - indexOffset
        return index
    }
    useEffect(() => {
        goTo(getIndex(accountProgress))
    }, [accountProgress])
    useEffect(() => {
        onSliderProgress()
        checkScrollPosition()
    }, [animationProgress])

    useLayoutEffect(() => {
        const width = swiperWrapper.current ? swiperWrapper.current.clientWidth : 0
        const controller = new ProgressBarController({
            mouseIsOver,
            checkScrollPosition,
            setLocalProgress,
            goTo,
            width,
        })

        if (ref) {
            if (isFunction(ref)) {
                ref(controller)
            } else {
                ref.current = controller
            }
        }
    })

    const setSwiperRef = useCallback((ref?: SwiperInstance) => {
        if (ref) {
            swiperRef.current = ref
        }
    }, [])

    const setSwiperWrapper = useCallback((ref?: HTMLDivElement | null) => {
        if (ref) {
            swiperWrapper.current = ref
        }
    }, [])

    const setSwiperNextButton = useCallback((ref?: HTMLDivElement | null) => {
        if (ref) {
            swiperNextButton.current = ref
        }
    }, [])

    const setSwiperPrevButton = useCallback((ref?: HTMLDivElement | null) => {
        if (ref) {
            swiperPrevButton.current = ref
        }
    }, [])

    const checkScrollPosition = (force = false) => {
        if (!timer.isRunning(PROGRESSBAR_LEAVE_TIMER_ID) || force) {
            setTimeout(() => {
                if (swiperRef.current && swiperWrapper.current && !mouseIsOver.current) {
                    const index = getIndex(animationProgress)
                    goTo(index)
                }
            }, 10)
        }
    }

    const onMouseOver = () => {
        if (mouseIsOver.current) return
        mouseIsOver.current = true
        ClientApi.setScrollover(true)
        timer.clear(PROGRESSBAR_LEAVE_TIMER_ID)
    }

    const onMouseLeave = () => {
        mouseIsOver.current = false
        if (!isBlueprintsViewerVisible) {
            ClientApi.setScrollover(false)
        }
        timer.start(PROGRESSBAR_LEAVE_TIMER_ID, MOUSE_LEAVE_DELAY, () => {
            checkScrollPosition()
        })
    }

    const goNext = () => {
        playButtonClickSound()
        if (swiperRef.current !== null) {
            swiperRef.current.slideTo(swiperRef.current.activeIndex + 3)
        }
    }

    const goToEnd = () => {
        playButtonClickSound()
        if (swiperRef.current !== null && swiperRef.current.slideTo) {
            console.warn('end')
            swiperRef.current.slideTo(rewards.length)
        }
    }

    const goPrev = () => {
        playButtonClickSound()
        if (swiperRef.current !== null) {
            swiperRef.current.slideTo(swiperRef.current.activeIndex - 3)
        }
    }

    const goTo = (index: number) => {
        if (swiperRef.current !== null && swiperRef.current.slideTo) {
            swiperRef.current.slideTo(index)
        }
    }

    const onSliderProgress = () => {
        if (swiperRef.current) {
            updateButtonsAndGradient(swiperRef.current, scrollState, swiperWrapper, swiperNextButton, swiperPrevButton)
        }
        if (swiperRef && swiperRef.current && swiperRef.current.progress > SCROLL_PROGRESS) {
            setIsHideFinalReward(true)
        } else {
            setIsHideFinalReward(false)
        }
    }

    const getBarPercentOffset = (progress: number, maxProgress?: number) => {
        let p = progress
        if (maxProgress !== undefined && p > maxProgress) {
            p = maxProgress
        }
        const offset = (rewards.length - p) * SLIDE_WIDTH + MASK_TAIL_WIDTH - SQUIRCLE_WIDTH
        return -offset
    }

    const getGradient = (): string => {
        const fullWidth = SLIDE_WIDTH * rewards.length
        let breakpoints = ''
        const half = SLIDE_WIDTH / 2
        const offset = 130
        fadedIndexes.forEach((index) => {
            const center = SLIDE_WIDTH * index - half
            breakpoints += `black ${center - offset}px, `
            breakpoints += `transparent ${center - offset + 30}px, `
            breakpoints += `transparent ${center + offset - 30}px, `
            breakpoints += `black ${center + offset}px, `
        })
        const gradient = `linear-gradient(to right, black 0px, ${breakpoints} black ${fullWidth}px)`
        return gradient
    }

    const renderBar = () => {
        const fullWidth = SLIDE_WIDTH * rewards.length
        const barHolderWidth = fullWidth
        const barProgress = localProgress !== null ? localProgress : animationProgress
        const barTranslate = getBarPercentOffset(barProgress)
        const barBlueprintTranslate = getBarPercentOffset(barProgress, blueprintsCount)

        return (
            <div
                className={styles.barHolderWrapper}
                style={{
                    width: barHolderWidth,
                    WebkitMaskImage: getGradient(),
                    maskImage: getGradient(),
                }}
            >
                <div
                    className={styles.barHolder}
                    style={{
                        clipPath: `url(#${SVG_PROGRESS_ITEMS_MASK_ID})`,
                        WebkitClipPath: `url(#${SVG_PROGRESS_ITEMS_MASK_ID})`,
                    }}
                >
                    <div
                        id={`progressbar-bar`}
                        className={styles.progress}
                        style={{
                            transform: `translate(${barTranslate}px, 0px)`,
                        }}
                    />
                    <div
                        id={`progressbar-bar-blueprint`}
                        className={styles.progressBlueprint}
                        style={{
                            transform: `translate(${barBlueprintTranslate}px, -70px)`,
                        }}
                    />
                </div>
            </div>
        )
    }

    return (
        <>
            <ProgressBarMask rewards={rewards} />
            <div
                onMouseOver={onMouseOver}
                onMouseDown={onMouseOver}
                onMouseLeave={onMouseLeave}
                onWheel={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
                className={classnames(styles.wrapper, { [styles.fullWidth]: isHideFinalReward })}
                id={'progressBar'}
            >
                <div ref={setSwiperWrapper}>
                    <Swiper
                        slidesPerView={'auto'}
                        freeMode={true}
                        mousewheel={true}
                        preventInteractionOnTransition={true}
                        key={`Rewards${rewards.length}`}
                        getSwiper={setSwiperRef}
                        touchStartPreventDefault={true}
                        on={{
                            progress: onSliderProgress,
                        }}
                    >
                        {rewards.map((reward: IReward, i: number) => {
                            const isFirst = i === 0
                            const isLast = i === rewards.length - 1
                            const isActive = animationProgress === i + 1 && !isAnimationInProgress
                            const isPremiumLevel = reward.shipLevelNumber > reachableCount
                            const shipLevel = shipLevels[i + 1]
                            const isReached = shipLevel.level <= animationProgress
                            const shipId = getShipIdFromReward(reward)
                            const isBlueprint = i < blueprintsCount

                            const issuedReward = issuedRewards.filter((issuedReward) => issuedReward.id === reward.id)[0]
                            return (
                                <div
                                    id={`ProgressBarItem-${i}`}
                                    key={`r${i}`}
                                    style={{ width: isLast && !!shipId ? SLIDE_WIDTH + 60 : SLIDE_WIDTH }}
                                    className={styles.progressBatItemWrapper}
                                >
                                    {isFirst && renderBar()}
                                    <ProgressBarItem
                                        issuedReward={issuedReward}
                                        shipLevel={shipLevel}
                                        reward={reward}
                                        isPremiumLevel={isPremiumLevel}
                                        isReached={isReached}
                                        index={i}
                                        isActive={isActive}
                                        isLast={isLast}
                                        isBlueprint={isBlueprint}
                                        bgImage={bgImage}
                                        starters={starters}
                                        startersAllowed={startersAllowed}
                                        currentProgress={accountProgress}
                                        pendingTransactions={pendingTransactions}
                                    />
                                </div>
                            )
                        })}
                    </Swiper>
                </div>
                <div className={styles.nextButton} onClick={goNext} ref={setSwiperNextButton} />
                <div className={styles.prevButton} onClick={goPrev} ref={setSwiperPrevButton} />
            </div>
            <FinalReward level={rewards.length} onClick={goToEnd} isHideFinalReward={isHideFinalReward} rewards={rewards} />
        </>
    )
})

export default ProgressBar
