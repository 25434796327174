import React, { ReactElement } from 'react'
import { IconProps } from '~/types'

function UnselectIcon({ color, className, onClick }: IconProps): ReactElement {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" className={className} onClick={onClick}>
            <path
                opacity="0.2"
                d="M7.25375 8.49999L2.6842 3.93044L3.93044 2.6842L8.49999 7.25375L13.0695 2.6842L14.3158 3.93044L9.74623 8.49999L14.3158 13.0695L13.0695 14.3158L8.49999 9.74623L3.93044 14.3158L2.6842 13.0695L7.25375 8.49999Z"
                stroke="black"
            />
            <path
                opacity="0.75"
                d="M7.25375 8.49999L2.6842 3.93044L3.93044 2.6842L8.49999 7.25375L13.0695 2.6842L14.3158 3.93044L9.74623 8.49999L14.3158 13.0695L13.0695 14.3158L8.49999 9.74623L3.93044 14.3158L2.6842 13.0695L7.25375 8.49999Z"
                fill={color || 'white'}
            />
        </svg>
    )
}

export default UnselectIcon
