import React, { useEffect, useRef, useState, createContext } from 'react'
import styles from './AnimationsHolder.scss'

export interface AnimationsHolderProps {
    children?: React.ReactNode
    aspectRatio: number
}

export const AnimationsHolder = (props: AnimationsHolderProps) => {
    const { children, aspectRatio } = props

    const wrapperRef = useRef<HTMLDivElement>()
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    const onResize = () => {
        if (wrapperRef.current) {
            const { clientWidth, clientHeight } = wrapperRef.current
            const currentAspectRatio = clientHeight / clientWidth

            const fullwidth = currentAspectRatio < aspectRatio
            if (fullwidth) {
                const newWidth = clientWidth
                const newHeight = Math.floor(newWidth * aspectRatio)
                setWidth(newWidth)
                setHeight(newHeight)
            } else {
                const newHeight = clientHeight
                const newWidth = Math.floor(newHeight / aspectRatio)
                setWidth(newWidth)
                setHeight(newHeight)
            }
        }
    }

    useEffect(() => {
        window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    const setWrapperRef = (r: HTMLDivElement | null) => {
        if (r) {
            wrapperRef.current = r
            onResize()
        }
    }

    return (
        <div className={styles.wrapper} ref={setWrapperRef}>
            <div
                className={styles.inner}
                style={{
                    minWidth: `${width}px`,
                    minHeight: `${height}px`,
                    maxWidth: `${width}px`,
                    maxHeight: `${height}px`,
                }}
            >
                {children}
            </div>
        </div>
    )
}

export default AnimationsHolder
