import { AnyAction } from 'redux'
import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import {
    closeBoosterDialog,
    closeBuyoutDialog,
    closeErrorDialog,
    closeFinalDialog,
    closeRulesDialog,
    closeWelcomeDialog,
    hideOverlay,
    openBoosterDialog,
    openBuyoutDialog,
    openErrorDialog,
    openFinalDialog,
    openRulesDialog,
    openWelcomeDialog,
    openMultipleBuyDialog,
    closeMultipleBuyDialog,
    openStartBuyDialog,
    closeStartBuyDialog,
    openPurchaseConfirmation,
    closePurchaseConfirmation,
} from '~/Actions/ActionDialogs'
import ViewDialogs from '~/Components/ViewDialogs/ViewDialogs'
import { i18n_Props } from '~/i18n'
import { State } from '~/Reducers'
import { IBooster } from '~/Reducers/ReducerApp'

type OwnProps = i18n_Props

interface DispatchProps {
    openWelcomeDialog: () => void
    closeWelcomeDialog: () => void
    openRulesDialog: () => void
    closeRulesDialog: () => void
    openBoosterDialog: (booster: IBooster) => void
    closeBoosterDialog: () => void
    openErrorDialog: (error: Error) => void
    closeErrorDialog: () => void
    openBuyoutDialog: () => void
    closeBuyoutDialog: () => void
    openFinalDialog: (shipName: string) => void
    closeFinalDialog: () => void
    hideOverlay: () => void
    openMultipleBuyDialog: () => void
    closeMultipleBuyDialog: () => void
    openStartBuyDialog: () => void
    closeStartBuyDialog: () => void
    openPurchaseConfirmation: (price: number, rewards: Array<string>) => void
    closePurchaseConfirmation: () => void
}

interface StateProps {
    isRulesDialogOpen: boolean
    isBoosterDialogOpen: boolean
    isWelcomeDialogOpen: boolean
    isErrorDialogOpen: boolean
    isBuyoutDialogOpen: boolean
    isFinalDialogOpen: boolean
    noAnimation: boolean
    isOverlayVisible: boolean
    isMultipleBuyDialogVisible: boolean
    isStartBuyDialogVisible: boolean
    isBundleBuyDialogVisible: boolean
    isPurchaseConfirmationVisible: boolean
}

export type Props = StateProps & OwnProps & DispatchProps

class ContainerDialogs extends PureComponent<Props> {
    render() {
        return <ViewDialogs {...this.props} />
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    isRulesDialogOpen: state.ReducerDialogs.isRulesDialogOpen,
    isBoosterDialogOpen: state.ReducerDialogs.isBoosterDialogOpen,
    isWelcomeDialogOpen: state.ReducerDialogs.isWelcomeDialogOpen,
    isErrorDialogOpen: state.ReducerDialogs.isErrorDialogOpen,
    isBuyoutDialogOpen: state.ReducerDialogs.isBuyoutDialogOpen,
    isFinalDialogOpen: state.ReducerDialogs.isFinalDialogOpen,
    noAnimation: state.ReducerApp.noAnimation,
    isOverlayVisible: state.ReducerDialogs.isOverlayVisible,
    isMultipleBuyDialogVisible: state.ReducerDialogs.isMultipleBuyDialogVisible,
    isStartBuyDialogVisible: state.ReducerDialogs.isStartBuyDialogVisible,
    isBundleBuyDialogVisible: state.ReducerDialogs.isBundleBuyDialogVisible,
    isPurchaseConfirmationVisible: state.ReducerDialogs.isPurchaseConfirmationVisible,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<State, unknown, AnyAction>, ownProps: OwnProps): DispatchProps => {
    return {
        openWelcomeDialog: () => {
            dispatch(openWelcomeDialog())
        },
        closeWelcomeDialog: () => {
            dispatch(closeWelcomeDialog())
        },
        openPurchaseConfirmation: (price: number, rewards: Array<string>) => {
            dispatch(openPurchaseConfirmation(price, rewards))
        },
        closePurchaseConfirmation: () => {
            dispatch(closePurchaseConfirmation())
        },
        openBoosterDialog: (booster: IBooster) => {
            dispatch(openBoosterDialog(booster))
        },
        closeBoosterDialog: () => {
            dispatch(closeBoosterDialog())
        },
        openRulesDialog: () => {
            dispatch(openRulesDialog())
        },
        closeRulesDialog: () => {
            dispatch(closeRulesDialog())
        },
        openErrorDialog: (error: Error) => {
            dispatch(openErrorDialog(error))
        },
        closeErrorDialog: () => {
            dispatch(closeErrorDialog())
        },
        openBuyoutDialog: () => {
            dispatch(openBuyoutDialog())
        },
        closeBuyoutDialog: () => {
            dispatch(closeBuyoutDialog())
        },
        openFinalDialog: (shipName: string) => {
            dispatch(openFinalDialog(shipName))
        },
        closeFinalDialog: () => {
            dispatch(closeFinalDialog())
        },
        openMultipleBuyDialog: () => {
            dispatch(openMultipleBuyDialog())
        },
        closeMultipleBuyDialog: () => {
            dispatch(closeMultipleBuyDialog())
        },
        hideOverlay: () => {
            dispatch(hideOverlay())
        },
        openStartBuyDialog: () => {
            dispatch(openStartBuyDialog())
        },
        closeStartBuyDialog: () => {
            dispatch(closeStartBuyDialog())
        },
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContainerDialogs))
