const defaultSettings: Preloaded = {
    settings: {
        isBuyGoldButtonVisible: false,
        disableIntroVideo: false,
        disableCompleteBlueprintsVideo: false,
        disableLaunchVideo: false,
        disableFinalVideo: false,
        skipTutorial: false,
        realm: 'ru',
        languageCode: 'ru',
        formatting: {},
        isPublicTestMode: false,
        wsmartStorefront: 'fastgold',
        wsmartStorefrontDebug: false,
        forceShopUrl: '',
        sentry: {
            isEnabled: true,
            dsn: '',
            environment: '',
            release: '',
        },
        isCISRegion: false,
        downloadClient: {},
    },
    urls: {
        vortex: '',
        static: '/',
        portal: '',
        dwhExport: '',
        wsmartUrl: '',
        armoryUrl: '',
        supportUrl: '',
    },
    state: {},
}
const preloaded: Preloaded = window.dockyard ? window.dockyard : defaultSettings

export default preloaded
