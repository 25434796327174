import { Interpolate } from '@wg/wows-react-uikit'
import equal from 'fast-deep-equal/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppInit } from '~/Actions/ActionApp'
import { useMount } from '~/hooks'
import { State } from '~/Reducers'
import { ship } from '~/wowsEntities'
import styles from './BundlePurchase.scss'
import { isInIframe } from '~/utils'
import classNames from 'classnames'
import MultipleBuyDialog from '~/Components/Dialogs/MultipleBuyDialog/MultipleBuyDialog'

export interface BundlePurchaseState {
    appIsReady: boolean
    shipId: string
}

const stateSelector = (state: State): BundlePurchaseState => {
    return {
        appIsReady: state.ReducerApp.appIsReady,
        shipId: state.ReducerApp.mainRewardShipId,
    }
}

export const BundlePurchase = () => {
    const { appIsReady, shipId } = useSelector<State, BundlePurchaseState>(stateSelector, equal)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    useMount(() => {
        dispatch(AppInit(true))
    })

    const classesDockyardDescription = classNames(styles.description, {
        [styles.inIframe]: isInIframe(),
    })

    return (
        <div id="bundle-purchase-wrapper" className={styles.wrapper}>
            {appIsReady ? (
                <>
                    <div className={styles.title}>{t('Купить этапы Верфи')}</div>
                    <div className={classesDockyardDescription}>
                        <Interpolate
                            str={t(
                                'Верфь — это специальный раздел в Порту, где происходит строительство кораблей. На Верфи вы сможете увидеть все этапы кораблестроения и, завершая их, получить корабль %(ship)s',
                            )}
                            ship={ship(shipId, true)}
                        />
                    </div>
                    <MultipleBuyDialog />
                </>
            ) : null}
        </div>
    )
}

export default BundlePurchase
