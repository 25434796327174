import { State } from '~/Reducers'
import { IBooster } from '~/Reducers/ReducerApp'

export const getStartBoostersIds = (boosters: Array<IBooster>) => {
    return boosters.filter((booster) => booster.unique).map((booster) => booster.id)
}

export const isTooLateToBuyStartBooster = (state: State): boolean => {
    let result = false

    const startBoostersIds = getStartBoostersIds(state.ReducerApp.boosters)
    const startBoosters = state.ReducerApp.boosters.filter((booster) => startBoostersIds.includes(booster.id))
    startBoosters.forEach((booster) => {
        if (!result) {
            booster.restrictedWhenUserHasBoosters.forEach((restriction) => {
                const boughtRestrictedBoosters = state.ReducerApp.account.boughtBoostersIds.filter((id) => id === restriction.boosterId)
                const isMoreThanCount = boughtRestrictedBoosters.length >= restriction.count
                if (isMoreThanCount) {
                    result = true
                }
            })
        }
    })

    return result
}

export const getStartBoosterProgressRestriction = (boosters: Array<IBooster>): number => {
    let progress = 0

    const startBoostersIds = getStartBoostersIds(boosters)
    const startBoosters = boosters.filter((booster) => startBoostersIds.includes(booster.id))
    startBoosters.forEach((booster) => {
        booster.restrictedWhenUserHasBoosters.forEach((restriction) => {
            if (restriction.count > progress) {
                progress = restriction.count
            }
        })
    })

    return progress
}
