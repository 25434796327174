import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Tooltips.scss'

export const RulesButtonTooltip = memo(
    () => {
        const { t } = useTranslation()
        return <div className={styles.padding}>{t('Подробные правила события')}</div>
    },
    () => true,
)
